import { POST } from 'api';
import React, { useEffect, useState } from 'react';
import { MenuBox, Navigation } from 'components/common/navigation';
import { Panel } from 'components/common/styled-components-common';
import { HomeService } from 'modules/page/home';
import Breadcrumbs from 'components/common/breadcrumbs';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { useTranslation } from 'react-i18next';
import WorkplazeThemeHideElement from 'components/elements/workplaze-theme-hide-element';
import EnabledDeveloperTools from 'components/common/deverloper-tools/enabled-developer-tools';
import Button from 'components/common/button';
import { numberjs } from 'helpers';
import { useNavigate } from 'react-router-dom';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import useAuth from 'hooks/useAuth';
import { HomePluginWrapper } from './Home.styled';

interface DefaultCoverage {
    defaultCoverageFlag?: number;
    amount?: number;
    imageUrl?: string;
}

function HomePlugin() {
    const navigate = useNavigate();
    const { name: theme } = useThemeWithPartner();
    const { t } = useTranslation(['home', 'navigation']);
    const { profile } = useAuth();
    const [defaultCoverage, setDefaultCoverage] = useState<DefaultCoverage>({});

    const getCoverage = async () => {
        let companyCode: string | null = null;
        const data = {
            phoneNumber: profile.phoneNumber,
            userPidType: profile.userPidType,
            userPid: profile.userPid ?? null,
            dateOfBirth: profile.dateOfBirth,
            validatePid: 0,
        };

        if (profile?.userXPartner?.partnerCompanyCode) {
            companyCode = profile.userXPartner.partnerCompanyCode;
        }

        try {
            const res = await POST('/claim/checkCoverage', { data, companyCode });
            setDefaultCoverage(res.defaultCoverage || {});
        } catch (error: any) {
            if (error.statusCode === 404) {
                console.warn('claim/checkCoverage --> error', error);
            } else {
                console.error('claim/checkCoverage --> error', error);
            }
        }
    };

    useEffect(() => {
        getCoverage();
    }, []); // Run once on mount

    return (
        <React.Fragment>
            <Breadcrumbs
                onBack={() => navigate(-1)}
                title={theme.includes('default') ? t('home:pharmcare_service') : t('home:service')}
                icon={<IconConfigWithTheme pageName='user_plugin' iconName='breadcrumbs' />}
            />
            <HomePluginWrapper>
                <EnabledDeveloperTools />
                <Panel className='profile-panel'>
                    <div className='profile-photo'>
                        {profile?.userImgUrl ? (
                            <img src={profile.userImgUrl} alt='profile photo' />
                        ) : (
                            <div className='first-letter'>{profile?.displayName?.substring(0, 1)?.toUpperCase()}</div>
                        )}
                    </div>
                    <div className='profile-detail'>
                        <div className='text-gray-1'>{profile.displayName}</div>
                        {defaultCoverage?.defaultCoverageFlag === 1 && (
                            <div>วงเงิน: {numberjs(defaultCoverage?.amount ?? 0).format('double', 0, 2)} บาท</div>
                        )}
                    </div>
                    {defaultCoverage?.defaultCoverageFlag === 1 && (
                        <div className='coverage-logo'>
                            <img src={defaultCoverage?.imageUrl} alt='coverage logo' />
                        </div>
                    )}
                </Panel>
                {theme === 'mtl' ? (
                    <div className='text-center mt-2 mb-3 text-black-1 fw-500'>
                        <div>{t('home:carecover_clinic')}</div>
                        <div>{t('home:license_number')} 10110005667</div>
                    </div>
                ) : theme === 'morningmind' ? (
                    <div className='text-center mt-2 mb-3 text-black-1 fw-500'>
                        <div>มอร์นิ่งมายด์คลินิกเฉพาะทางเวชกรรมจิตเวช</div>
                        <div>{t('home:license_number')} 10102006165</div>
                    </div>
                ) : (
                    <div className='service-title'>{t('home:our_services_choose')}</div>
                )}
                <HomeService showTitle={true} />
                {theme === 'central' && (
                    <div className='action-next'>
                        <Button
                            variant='primary'
                            className='miw-275'
                            onClick={() =>
                                window.open(
                                    theme === 'central'
                                        ? 'https://form.fillout.com/t/fZwTxgAgR2us?openExternalBrowser=1'
                                        : 'https://form.fillout.com/t/jBV6mfdMAYus?openExternalBrowser=1'
                                )
                            }
                        >
                            ทำแบบประเมินความเครียด
                        </Button>
                    </div>
                )}
            </HomePluginWrapper>
            <WorkplazeThemeHideElement>
                <Navigation>
                    {!['bilaxten'].some((f) => f === theme) && (
                        <MenuBox
                            to='/home'
                            tag='/home'
                            title={t('navigation:home')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='home' />}
                        />
                    )}

                    {!['mtl', 'default_oceanopd'].some((f) => f === theme) && (
                        <MenuBox
                            to='/phr/existing'
                            tag='/phr'
                            title={t('navigation:health_record')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='phr' />}
                        />
                    )}
                    <MenuBox
                        to='/service'
                        tag='/service'
                        title={t('navigation:acitivites')}
                        icon={<IconConfigWithTheme pageName='navigation' iconName='service' />}
                    />
                </Navigation>
            </WorkplazeThemeHideElement>
        </React.Fragment>
    );
}

export default HomePlugin;
