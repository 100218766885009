import { POST } from 'api';
import EXPPopupJs from 'components/common/exp-popupjs';
import { API_CALL_TYPES, OPEN_SERVICE_DATE_TIME_WITH_THEME } from 'constants/default-config';
import { DEFAULT_PHARMCARE_PROGRAM } from 'constants/service-config';
import i18next from 'i18next';
import moment from 'moment';
import { paramsjs } from 'helpers';

const getRedirecctUrl = (service = {}, params = '', phrExistFlag = 0, skipPhr = 0, createdAt = moment()) => {
    if (service.name === 'telemed') return `${service.servicePath}/consult/select-department${params}`;
    if (service.name === 'tlm' || service.name === 'tlmNcd') return `${service.servicePath}/select-hsp${params}`;
    if (service.name === 'mth') return `${service.servicePath}/select-mth${params}`;
    if (
        (service.name === 'telepharmacy' || service.name === 'telepharmacyLTC') &&
        skipPhr === 1 &&
        phrExistFlag === 0 &&
        moment(createdAt).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ) {
        return `${service.servicePath}/screening/final${params}`;
    }
    if (['telepharmacy', 'telepharmacyLTC', 'nutritionist', 'physiotherapy', 'fulfillment'].some((s) => service.name === s)) {
        if (phrExistFlag === 1) {
            return `${service.servicePath}/phr/existing${params}`;
        }

        return `${service.servicePath}/phr/new/disease${params}`;
    }

    return '';
};

const getProcessCode = (taskStatusCode) => {
    if (taskStatusCode === 0) return 4;

    if (taskStatusCode === 10) return 14;

    if (taskStatusCode === 20) return 24;

    return 0;
};

/**
 *
 * @param {{
 * userPid: String | null,
 * pharmacyCode: String,
 * hspCode: String,
 * createdAt?: String,
 * skipPhr?: Number,
 * phrExistFlag: Number,
 * serviceConfig:{
 *      name: String,
 *      communicationType: String,
 *      servicePath: String,
 * },
 * coverageData: {
 *      companyCode: String,
 *      policyNumber: String,
 *      pharmcareProgram: String,
 *      coverageType: String,
 *      coverageCode: String,
 *      voucherType: String,
 *      voucherCode: String,
 *      amount: String,
 *      isAvailable: Boolean,
 * },
 * translation: any
 * }} props
 * @returns { Promise<{
 * statusCode: Number,
 * redirectUrl: String,
 * rejectReason: String,
 * taskAlready: {
 *      taskId:string
 *      processCode:number
 *      appointmentNo?:string
 *      pharmacyServiceId?:string
 *      taskStatusCode?:number
 *      ctaRedirectUrl?:string
 *      rejectReason?:string
 *      phrExistFlag?:  number
 * }
 * }>
 * }
 */
const getSelectCoverage = ({
    userPid = '',
    pharmacyCode = '',
    hspCode = '',
    createdAt = '',
    skipPhr = 0,
    phrExistFlag = 0,
    serviceConfig = {},
    coverageData = {},
    translation,
}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await POST('/claim/selectCoverage', {
                pid: userPid,
                latitude: 0,
                longitude: 0,
                taskCommunicationType: serviceConfig.communicationType,
                ltcFlag: serviceConfig.name === 'telepharmacyLTC' ? 1 : 0,
                pharmcareProgram: DEFAULT_PHARMCARE_PROGRAM[serviceConfig.name],
                data: coverageData,
                // data: {
                //     companyCode: coverageData.companyCode,
                //     policyNumber: coverageData.policyNumber,
                //     pharmcareProgram: coverageData.pharmcareProgram,
                //     coverageType: coverageData.coverageType,
                //     coverageCode: coverageData.coverageCode,
                //     voucherType: coverageData.voucherType,
                //     voucherCode: coverageData.voucherCode,
                //     amount: coverageData.amount,
                //     isAvailable: coverageData.isAvailable
                // }
            });

            // if taskAlready
            if (res.statusCode === 300) {
                resolve({
                    statusCode: res.statusCode,
                    redirectUrl: '',
                    rejectReason: res.rejectReason,
                    taskAlready: {
                        taskId: res.task.taskId,
                        processCode: getProcessCode(res.task.taskStatusCode),
                        appointmentNo: res.task.appointmentNo,
                        pharmacyServiceId: res.task.pharmacyServiceId,
                        taskStatusCode: res.task.taskStatusCode,
                        ctaRedirectUrl: res.task.ctaRedirectUrl,
                        rejectReason: res.rejectReason,
                        phrExistFlag: res.task.phrExistFlag,
                    },
                });
            } else {
                const pharmacyCodeParams = pharmacyCode ? `&pharmacyCode=${pharmacyCode}` : '';
                const hspParams = hspCode ? `&hspCode=${hspCode}` : '';
                const params = `?task=${res.task.taskId}${pharmacyCodeParams}${hspParams}&flag=${res.task.phrExistFlag}`;
                const redirectUrl = getRedirecctUrl(serviceConfig, params, res.task.phrExistFlag, skipPhr, createdAt);

                if (res.redirectUrl !== null)
                    resolve({
                        statusCode: res.statusCode,
                        redirectUrl: `${res.redirectUrl}${params}`,
                        rejectReason: res.rejectReason,
                        taskAlready: {
                            taskId: '',
                            processCode: 0,
                            taskStatusCode: 0,
                            ctaRedirectUrl: '',
                            phrExistFlag: 0,
                        },
                    });

                if (!redirectUrl) reject('out of condition');

                resolve({
                    statusCode: res.statusCode,
                    redirectUrl: redirectUrl,
                    rejectReason: '',
                    taskAlready: {
                        taskId: '',
                        processCode: 0,
                        taskStatusCode: 0,
                        ctaRedirectUrl: '',
                        phrExistFlag: 0,
                    },
                });
            }
        } catch (error) {
            console.error('/claim/selectCoverage error', error);
            if (error.isNoPopup) {
                EXPPopupJs.simple('warning', translation('common:error'), error.rejectReason);
            }
            // reject(error)
        }
    });
};

/**
 *
 * @param {string} taskId
 * @param {string} currentUrl
 * @returns {Promise<{
 * task: object,
 * taskPharmacy: object,
 * taskScreening: array,
 * taskAttachment: array,
 * taskMeetingRoom: object,
 * taskSymptom: array,
 * taskPurchaseItemGroup: object,
 * taskPayment: array,
 * preCheckDeliveryResult: null,
 * taskDoctor: object,
 * taskTracking: array,
 * taskCoverageDetail: array,
 * taskSystemFee:object,
 * taskUserXPartnerOrders: array,
 * taskRecommendItems: array,
 * courierDetailChanges: array,
 * taskMedicine: array,
 * taskProgress: object
 * }>}
 */
const getTaskDetail = (taskId = null, currentUrl = null, includeAttachmentFlag = false) => {
    return new Promise(async (resolve, reject) => {
        await POST('/service/getTaskDetail', { taskId, currentUrl, includeAttachmentFlag })
            .then((res) =>
                resolve({
                    ...res.data,
                    taskProgress: res.taskProgress,
                })
            )
            .catch((error) => {
                console.error('/service/getTaskDetail error', error);
                reject(error);
            });
    });
};

const getPHR = (pid, taskId = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await POST('/phr/getPhr', { pid, taskId });

            resolve({
                ...res.data,
                taskScreenings: res.taskScreenings,
            });
        } catch (error) {
            console.error('/phr/getPhr error', error);
            reject(error);
        }
    });
};

/**
 *
 * @param { {questionType:string, nextQuestion:object[], nextAction:string} } result
 * @returns url
 */
function nextQuestion(result) {
    let qusetList = '';

    const arrayLikeJoin = (values = [], separator = '') => {
        if (values.length <= 0) return '';

        const removeEmptyValue = [];
        values.forEach((value) => {
            if (!value) return;

            return removeEmptyValue.push(value);
        });

        return removeEmptyValue.join(separator);
    };

    if (result.nextQuestion) {
        qusetList = arrayLikeJoin(
            result.nextQuestion.map((items) => items.questionId),
            '-'
        );
    }

    const query = paramsjs.query(window.location.search);
    const params = paramsjs.queryToParams({ ...query, questionId: qusetList });
    if (result.nextAction === 'PROCEED_PHARMCARE' || result.questionType === 'P06' || result.questionType === 'P02') {
        return '/screening/final' + params;
    } else {
        return '/screening/' + result.questionType?.toLowerCase() + params;
    }
}

/**
 * @see sessionState encode from object: {
 * path: string,
 * params: string,
 * data: { authorization: string, autoLogin: number, partner: string, userId: string, companyCode: string, language: string, ...etc}}
 * @param {string} sessionState
 * @returns
 */
function lineAuthen(sessionState = null) {
    const params = paramsjs.query(window.location.search);

    return new Promise(async (resolve, reject) => {
        try {
            const res = await POST('/user/lineAuthen', {
                sessionId: params?.['liff.state'] || '/home',
                sessionState,
            });

            if (res.lineAuthenStateUrl) {
                resolve(res.lineAuthenStateUrl);
            } else {
                resolve(res.lineAuthenUrl);
            }
        } catch (error) {
            console.error('lineAuthen ~ error', error);
            reject(error);
        }
    });
}

function updateUserLanguage(language = 'th', token = '') {
    const _language = language?.toLowerCase();

    i18next.changeLanguage(_language);

    if (window) document.documentElement.setAttribute('lang', _language);
    // set timezone
    moment.locale(_language);

    if (token) return POST('/user/setLanguage', { languageEn: _language === 'th' ? 0 : 1, token });

    return Promise.resolve('seccess');
}

function getApiCallTypeWithTheme(theme = '', companyCode = '') {
    if (['01', '19', '33'].some((s) => s === companyCode)) return API_CALL_TYPES[companyCode];
    return API_CALL_TYPES[theme] || API_CALL_TYPES.default;
}

const OUT_OF_SERVICE_TIME_WITH_SERVICE = {
    default: { open: '00:00', close: '23:59' },
    tlm: { open: '09:00', close: '20:00' },
    tlm_default_oceanopd: { open: '08:00', close: '22:00' },
    telepharmacy: { open: '08:00', close: '22:00' },
};

function checkServiceHours(serviceName = '', theme = '') {
    const today = moment().format('YYYY-MM-DD');
    const _outOfService = OUT_OF_SERVICE_TIME_WITH_SERVICE;
    let result = { isOutOfServiceTime: false, open: _outOfService.default.open, close: _outOfService.default.close };

    if (
        (serviceName === 'tlm' || serviceName === 'tlmNcd') &&
        process.env.REACT_APP_SHOW_POPUP_OUT_OF_SERVICE_TIME === 'true' &&
        (theme !== 'default_oceanopd' || process.env.REACT_APP_FILE_NAME !== '.env.uat') &&
        !moment().isBetween(moment(today + ' ' + _outOfService.tlm.open), moment(today + ' ' + _outOfService.tlm.close))
    ) {
        return {
            ...result,
            isOutOfServiceTime: true,
            open: _outOfService.tlm.open,
            close: _outOfService.tlm.close,
        };
    }

    if (
        (serviceName === 'tlm' || serviceName === 'tlmNcd') &&
        process.env.REACT_APP_SHOW_POPUP_OUT_OF_SERVICE_TIME === 'true' &&
        theme === 'default_oceanopd' &&
        process.env.REACT_APP_FILE_NAME === '.env.uat' &&
        !moment().isBetween(
            moment(today + ' ' + _outOfService.tlm_default_oceanopd.open),
            moment(today + ' ' + _outOfService.tlm_default_oceanopd.close)
        )
    ) {
        return {
            ...result,
            isOutOfServiceTime: true,
            open: _outOfService.tlm_default_oceanopd.open,
            close: _outOfService.tlm_default_oceanopd.close,
        };
    }

    if (
        serviceName === 'telepharmacy' &&
        process.env.REACT_APP_SHOW_POPUP_OUT_OF_SERVICE_TIME === 'true' &&
        !moment().isBetween(moment(today + ' ' + _outOfService.telepharmacy.open), moment(today + ' ' + _outOfService.telepharmacy.close))
    ) {
        return {
            ...result,
            isOutOfServiceTime: true,
            open: _outOfService.telepharmacy.open,
            close: _outOfService.telepharmacy.close,
        };
    }

    return result;
}
function checkServiceHoursNew(serviceName = '', theme = '') {
    const todayByDate = moment().format('YYYY-MM-DD');
    const todayByDay = moment().locale('en').format('dddd');

    let _outOfService = OPEN_SERVICE_DATE_TIME_WITH_THEME[serviceName] || OPEN_SERVICE_DATE_TIME_WITH_THEME['defaultService'];

    //tlm default_oceanopd uat
    // if (serviceName === 'tlm' && theme === 'default_oceanopd' && process.env.REACT_APP_FILE_NAME === '.env.uat') {
    //     _outOfService = OPEN_SERVICE_DATE_TIME_WITH_THEME['tlm'];
    // }
    // tlm mtl
    if (serviceName === 'tlm' && theme === 'mtl') {
        _outOfService = OPEN_SERVICE_DATE_TIME_WITH_THEME['tlmMtl'];
    }
    const _outOfServiceWithToday = _outOfService.find((f) => f.day === todayByDay);

    let result = { isOutOfServiceTime: false, open: _outOfServiceWithToday.open, close: _outOfServiceWithToday.close };

    if (
        process.env.REACT_APP_SHOW_POPUP_OUT_OF_SERVICE_TIME === 'true' &&
        !moment().isBetween(
            moment(todayByDate + ' ' + _outOfServiceWithToday.open),
            moment(todayByDate + ' ' + _outOfServiceWithToday.close)
        )
    ) {
        return {
            ...result,
            isOutOfServiceTime: true,
        };
    }

    return result;
}
export {
    getSelectCoverage,
    getTaskDetail,
    getPHR,
    nextQuestion,
    lineAuthen,
    updateUserLanguage,
    getApiCallTypeWithTheme,
    checkServiceHours,
    checkServiceHoursNew,
};
