import React from 'react';
import { TermsAndConditionsYaphrom } from '../terms-and-conditions-with-theme';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import PharmcareConsent from './pharmcare-consent';
import styled from '@emotion/styled';
import YaphromConsent from './yaphrom-consent';
import RabbitConsent from './rabbit-consent';
import { Else, If, Then } from 'components/common/if-condition';
import EXPLiftUp from 'components/common/exp-lift-up';
import WorkplazeThemeHideElement from 'components/elements/workplaze-theme-hide-element';
import { withTranslation } from 'react-i18next';
import Central20Consent from './central20-consent';
import TermsAndConditionsCentral20 from '../terms-and-conditions-with-theme/terms-and-conditions-central20';
import { LiftUpV2 } from 'components/common/LiftUpV2';
import MorningmindConsent from './morningmind-consent';
import MtlConsent from './mtl-consent';

const Style = styled.div`
    label: popup-consent-with-theme;

    .terms-and-conditions-title {
        text-align: center;
        margin: auto;
    }

    .consent-close-popup-icon {
        position: sticky;
        top: 0;
        width: 100%;
        padding-top: 12px;
        padding-right: 24px;
        background-color: #fff;
        z-index: 2;

        .close-icon {
            position: relative;
            display: block;
            width: 4px;
            height: 24px;
            font-size: 0;
            margin-left: auto;
            cursor: pointer;

            &::before,
            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                background-color: var(--gray-2);
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
    }

    .consent-panel-sticky {
        position: sticky;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--white);
    }

    .consent-panel {
        padding: 1.5rem;
        border-radius: 8px;
        background-color: var(--terms-conditons-popup-panel-background-color);
    }

    .consent-section {
        &:last-of-type .consent-input {
            padding-bottom: 0;
        }

        &.__border-bottom {
            border-bottom: 2px solid var(--white);
            margin-bottom: 1rem;

            &:last-of-type {
                border-bottom-color: transparent;
                margin-bottom: 0;
            }
        }
    }
    .consent-title {
        font-size: 1.234rem;

        .fa-chevron-up {
            font-size: 80%;
        }
    }
    .consent-remark {
        color: var(--red-1);
    }
    .consent-important {
        font-weight: 600;
    }
    .consent-underline {
        text-decoration: underline;
    }
    .consent-input {
        max-width: 450px;
        margin: 0 auto;
        padding: 1.5rem;
    }
    .consent-table {
        table {
            width: 100%;
            margin: 1.5rem 0;
            color: var(--text-normal-1);
        }

        td,
        th {
            border: 1px solid var(--text-normal-1);
            padding: 8px;
        }
        ._accept {
            width: 10%;
            text-align: center;
        }
        .bg_gray {
            background-color: var(--text-normal-6);
        }
        .bg_white {
            background-color: var(--white);
        }
        .bg_transparent {
            text-align: center;
            background-color: transparent;
        }
    }
    .consent-footer {
        text-align: center;
        padding: 1.5rem;

        button {
            width: 100%;
            max-width: 400px;
        }

        &.__double-button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                width: 48%;
                max-width: 350px;
            }
        }
    }
`;

/**
 *
 * @param {{
 * show: Boolean,
 * onClose: ()=> void,
 * onSubmitConsent:(never:{c1: 'Y'|'N'|'', c2: 'Y'|'N'|'', c3: 'Y'|'N'|'', c4: 'Y'|'N'|'' })=> void
 * }} props
 * @returns
 */
function PopupConsentWithTheme({ show = false, onClose = () => null, onSubmitConsent = () => null }) {
    const theme = useThemeWithPartner();
    //
    const showPopupAcceptTermsConditions = React.useMemo(() => {
        return show;
    }, [show]);

    return (
        <>
            <EXPLiftUp.slideUp
                showClose={false}
                show={showPopupAcceptTermsConditions}
                zIndex={'var(--liftup-consent-zindex)'}
                data-test-id='popupConsent'
                onClose={onClose}
            >
                <Style>
                    <WorkplazeThemeHideElement>
                        <div className='consent-close-popup-icon'>
                            <span className='close-icon' onClick={onClose}>
                                x
                            </span>
                        </div>
                    </WorkplazeThemeHideElement>
                    <div className='consent-popup-body'>
                        <If condition={theme.name === 'rabbit'}>
                            <Then>
                                <RabbitConsent onSubmit={onSubmitConsent} />
                            </Then>
                            <Else>
                                <If condition={theme.name === 'yaphrom'}>
                                    <Then>
                                        <TermsAndConditionsYaphrom />
                                        <YaphromConsent onSubmit={onSubmitConsent} />
                                    </Then>
                                    <Else>
                                        <If condition={theme.name === 'central20'}>
                                            <Then>
                                                <TermsAndConditionsCentral20 />
                                                <Central20Consent onSubmit={onSubmitConsent} />
                                            </Then>
                                            <Else>
                                                <If condition={theme.name === 'morningmind'}>
                                                    <Then>
                                                        <MorningmindConsent onSubmit={onSubmitConsent} />
                                                    </Then>
                                                    <Else>
                                                        <If condition={theme.name === 'mtl'}>
                                                            <Then>
                                                                <MtlConsent onSubmit={onSubmitConsent} />
                                                            </Then>
                                                            <Else>
                                                                <PharmcareConsent onSubmit={onSubmitConsent} />
                                                            </Else>
                                                        </If>
                                                    </Else>
                                                </If>
                                            </Else>
                                        </If>
                                    </Else>
                                </If>
                            </Else>
                        </If>
                    </div>
                </Style>
            </EXPLiftUp.slideUp>
        </>
    );
}

export default withTranslation([
    'consent-default',
    'consent-central20',
    'consent-pharmcare',
    'consent-morningmind',
    'consent-yaphrom',
    'consent-rabbit',
    'consent-mtl',
    'privacy-policy-central20',
    'privacy-policy-pharmcare',
    'privacy-policy-morningmind',
    'privacy-policy-rabbit',
    'privacy-policy-yaphrom',
    'privacy-policy-mtl',
    'terms-and-conditions-central20',
    'terms-and-conditions-pharmcare',
    'terms-and-conditions-morningmind',
    'terms-and-conditions-rabbit',
    'terms-and-conditions-yaphrom',
    'terms-and-conditions-mtl',
])(PopupConsentWithTheme);
