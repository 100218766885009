import Button from 'components/common/button';
import { Field, FormField } from 'components/common/exp-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrivacyPolicyRabbit } from '../privacy-policy-with-theme';
import EXPLiftUp from 'components/common/exp-lift-up';
import { TermsAndConditionsRabbit } from '../terms-and-conditions-with-theme';
import styled from '@emotion/styled';

const LiffupFooter = styled.div`
    text-align: center;
    padding: 1.5rem;

    button {
        width: 100%;
        max-width: 400px;
    }
`;

/**
 *
 * @param {{
 * onSubmit:(never:{c1: 'Y'|'N'|'', c2: 'Y'|'N'|'', c3: 'Y'|'N'|'', c4: 'Y'|'N'|'' })=> void
 * }} props
 * @returns
 */
function RabbitConsent({ onSubmit = () => null }) {
    const { t } = useTranslation();
    //
    const [consentInput, setConsentInput] = React.useState({ c1: '', c2: '', c3: '', c4: '' });
    const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = React.useState(false);
    const [showTermsConditionsPopup, setShowTermsConditionsPopup] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    //
    const onChangeConsent = (event) => {
        setConsentInput({ ...consentInput, [event.target.name]: event.target.value });
    };

    const onSubmitConsent = () => {
        setLoading(true);
        onSubmit(consentInput);
        setTimeout(() => {
            setLoading(false);
        }, 120);
    };

    const onShowPrivacyPolicyPopup = (event) => {
        event.preventDefault();

        setShowPrivacyPolicyPopup(true);
    };

    const onShowTermsConditionsPopup = (event) => {
        event.preventDefault();

        setShowTermsConditionsPopup(true);
    };

    return (
        <>
            <FormField onSubmit={onSubmitConsent}>
                <div className='consent-panel'>
                    <h4 className='text-primary text-center'>{t('consent-rabbit:title')}</h4>
                    <div id='consent-section1' className='consent-section'>
                        <div>
                            <p>
                                {t('consent-rabbit:section1.detail1')}
                                <a href='/privacy-policy' onClick={onShowPrivacyPolicyPopup}>
                                    {t('consent-rabbit:privacy_policy')}
                                </a>
                            </p>
                            <p className='consent-remark' dangerouslySetInnerHTML={{ __html: t('consent-rabbit:section1.note') }} />
                        </div>
                        <div className='consent-input'>
                            <Field.RadioGroup
                                type='radio-group'
                                name='c1'
                                value={consentInput.c1}
                                radioListGroup={[
                                    { label: t('common:accept'), value: 'Y', xs: 6 },
                                    { label: t('common:not_accept'), value: 'N', xs: 6 },
                                ]}
                                classNameField='input-radio-type4 input-radio-button text-center'
                                onChange={onChangeConsent}
                            />
                        </div>
                    </div>
                    <div id='consent-section2' className='consent-section'>
                        <div>
                            <p>
                                {t('consent-rabbit:section2.detail1')}
                                <a href='/privacy-policy' onClick={onShowPrivacyPolicyPopup}>
                                    {t('consent-rabbit:privacy_policy')}
                                </a>
                            </p>
                            <p className='consent-remark' dangerouslySetInnerHTML={{ __html: t('consent-rabbit:section2.note') }} />
                        </div>
                        <div className='consent-input'>
                            <Field.RadioGroup
                                type='radio-group'
                                name='c2'
                                value={consentInput.c2}
                                radioListGroup={[
                                    { label: t('common:accept'), value: 'Y', xs: 6 },
                                    { label: t('common:not_accept'), value: 'N', xs: 6 },
                                ]}
                                classNameField='input-radio-type4 input-radio-button text-center'
                                onChange={onChangeConsent}
                            />
                        </div>
                    </div>
                    <div id='consent-section3' className='consent-section'>
                        <div>
                            <p className='consent-important' dangerouslySetInnerHTML={{ __html: t('consent-rabbit:section3.detail1') }} />
                            <p dangerouslySetInnerHTML={{ __html: t('consent-rabbit:section3.detail2') }} />
                            <p className='consent-remark' dangerouslySetInnerHTML={{ __html: t('consent-rabbit:section3.note') }} />
                        </div>
                        <div className='consent-input'>
                            <Field.RadioGroup
                                type='radio-group'
                                name='c3'
                                value={consentInput.c3}
                                radioListGroup={[
                                    { label: t('common:accept'), value: 'Y', xs: 6 },
                                    { label: t('common:not_accept'), value: 'N', xs: 6 },
                                ]}
                                classNameField='input-radio-type4 input-radio-button text-center'
                                onChange={onChangeConsent}
                            />
                        </div>
                    </div>
                    <div id='consent-section4' className='consent-section'>
                        <div>
                            <p>
                                {t('consent-rabbit:section4.detail1')}
                                <a href='/privacy-policy' onClick={onShowPrivacyPolicyPopup}>
                                    {t('consent-rabbit:privacy_policy')}
                                </a>
                            </p>
                            <div className='consent-important' dangerouslySetInnerHTML={{ __html: t('consent-rabbit:section4.detail2') }} />
                            <p className='consent-remark' dangerouslySetInnerHTML={{ __html: t('consent-rabbit:section4.note') }} />
                        </div>
                        <div className='consent-input'>
                            <Field.RadioGroup
                                type='radio-group'
                                name='c4'
                                value={consentInput.c4}
                                radioListGroup={[
                                    { label: t('common:accept'), value: 'Y', xs: 6 },
                                    { label: t('common:not_accept'), value: 'N', xs: 6 },
                                ]}
                                classNameField='input-radio-type4 input-radio-button text-center'
                                onChange={onChangeConsent}
                            />
                        </div>
                    </div>
                    <div id='consent-section5' className='consent-section'>
                        <div>
                            <p>
                                {t('consent-rabbit:section5.detail1_1')}{' '}
                                <a href='/terms-conditions' onClick={onShowTermsConditionsPopup}>
                                    {t('consent-rabbit:terms_conditions')}
                                </a>{' '}
                                {t('consent-rabbit:section5.detail1_2')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='consent-footer'>
                    <Button variant='primary' loading={loading} disabled={consentInput.c1 !== 'Y' || consentInput.c2 !== 'Y'}>
                        {t('common:confirm')}
                    </Button>
                </div>
            </FormField>
            <EXPLiftUp.slideUp
                show={showPrivacyPolicyPopup}
                zIndex={'var(--liftup-consent-zindex)'}
                onClose={() => setShowPrivacyPolicyPopup(false)}
            >
                <div className='pt-3'>
                    <PrivacyPolicyRabbit />
                    <LiffupFooter>
                        <Button variant='primary' onClick={() => setShowPrivacyPolicyPopup(false)}>
                            {t('common:back')}
                        </Button>
                    </LiffupFooter>
                </div>
            </EXPLiftUp.slideUp>
            <EXPLiftUp.slideUp
                show={showTermsConditionsPopup}
                zIndex={'var(--liftup-consent-zindex)'}
                onClose={() => setShowTermsConditionsPopup(false)}
            >
                <div className='pt-3'>
                    <TermsAndConditionsRabbit />
                    <LiffupFooter>
                        <Button variant='primary' onClick={() => setShowTermsConditionsPopup(false)}>
                            {t('common:back')}
                        </Button>
                    </LiffupFooter>
                </div>
            </EXPLiftUp.slideUp>
        </>
    );
}

export default RabbitConsent;
