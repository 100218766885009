import useThemeWithPartner from 'hooks/useThemeWithPartner';
import React from 'react';
import { useTranslation } from 'react-i18next';

function SidebarAddress() {
    const { t } = useTranslation();
    const theme = useThemeWithPartner();

    if (theme.name === 'yaphrom')
        return (
            <p className='mt-5 mb-0 fw-200'>
                {t('common:contact.contact_us')}
                <br />
                บริษัท ยาพร้อม จำกัด
                <br />
                1976 ถนนเพชรบุรีตัดใหม่ <br />
                แขวงบางกะปิ เขตห้วยขวาง
                <br />
                กรุงเทพมหานคร 10310
                <br />
                <br />
                LINE ID: @yaphrom
                <br />
            </p>
        );
    if (theme.name === 'mtl')
        return (
            <p className='mt-5 mb-0 fw-500'>
                {t('common:contact.contact_us')}
                <br />
                {t('common:contact.careCover_clinic')}
                <br />
                {t('common:contact.contact_mtl1')} <br />
                {t('common:contact.contact_mtl2')}
                <br />
                {t('common:contact.contact_mtl3')}
                <br />
                <br />
            </p>
        );
    return (
        <p className='mt-5 mb-0 fw-200'>
            {t('common:contact.contact_us')}
            <br />
            {t('common:contact.company')}
            <br />
            {t('common:contact.road')}
            <br />
            {t('common:contact.floor')}
            <br />
            {t('common:contact.district')}
            <br />
            {t('common:contact.province')}
            <br />
            <br />
            {t('common:contact.line_id')}
            <br />
        </p>
    );
}

export default SidebarAddress;
