import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { breakpoint } from 'helpers';

const Style = styled.div`
    label: terms-conditions-with-theme;

    .terms-and-conditions-title {
        color: var(--text-title-color);
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .terms-and-conditions-subtitle {
        font-weight: 600;
        text-decoration: underline;
    }

    .terms-and-conditions-panel {
        position: relative;
        display: block;
        width: 94%;
        max-width: 900px;
        padding-bottom: 40px;
        margin: 0 auto;

        ${breakpoint('XS')} {
            padding-bottom: 24px;
        }
    }
`;

function TermsAndConditionsMorningmind() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Style className='terms-and-conditions'>
                <div className='terms-and-conditions-panel'>
                    <h4
                        id='terms-conditions-morningmind'
                        className='terms-and-conditions-title'
                        dangerouslySetInnerHTML={{
                            __html: t('terms-and-conditions-morningmind:title'),
                        }}
                    />
                    <h6
                        className='terms-and-conditions-subtitle'
                        dangerouslySetInnerHTML={{
                            __html: t('terms-and-conditions-morningmind:subtitle'),
                        }}
                    />
                    <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:detail1') }} />
                    <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:detail2') }} />
                    <div className='terms-and-conditions-section list-style-nested-number'>
                        <ul className='list-style-number-right-dot __bold __no-padding-inline-start'>
                            <li id='terms-conditions-morningmind-section1'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section1.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section1.detail1') }} />
                                <ul lang={i18n.language} className='list-style-character-right-bracket'>
                                    <li>
                                        <div>{t('terms-and-conditions-morningmind:section1.detail1_1.title')}</div>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section1.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div>{t('terms-and-conditions-morningmind:section1.detail1_2.title')}</div>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section1.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section1.detail2') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section1.detail3') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section1.detail4') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section1.detail5') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section2'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section2.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section2.detail1') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section2.detail2') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section2.detail3') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section2.detail4') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section2.detail5') }} />
                                <ul lang={i18n.language} className='list-style-character-bracket'>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section2.detail5_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section2.detail5_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section2.detail5_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section2.detail5_4.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-morningmind-section3'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section3.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section3.detail1') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section4'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section4.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section4.detail1') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section4.detail2') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section5'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section5.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section5.detail1') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section5.detail2') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section6'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section6.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section6.detail1') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section6.detail2') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section7'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section7.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section7.detail1') }} />
                                <ul lang={i18n.language} className='list-style-character-bracket'>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_4.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_5.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_6.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_7.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_8.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_9.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_10.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_11.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_12.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_13.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail1_14.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section7.detail2') }} />
                                <ul
                                    lang={i18n.language}
                                    className={i18n.language === 'th' ? 'list-style-number-bracket' : 'list-style-roman-bracket'}
                                >
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail2_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail2_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail2_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section7.detail2_4.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-morningmind-section8'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section8.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section8.detail1') }} />
                                <ul lang={i18n.language} className='list-style-character-bracket'>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section8.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-morningmind:section8.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section8.detail2') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section8.detail3') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section9'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section9.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section9.detail1') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section10'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section10.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section10.detail1') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section11'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section11.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section11.detail1') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section11.detail2') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section12'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section12.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section12.detail1') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section13'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section13.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section13.detail1') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section13.detail2') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section14'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section14.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section14.detail1') }} />
                            </li>
                            <li id='terms-conditions-morningmind-section15'>
                                <strong className='title'>{t('terms-and-conditions-morningmind:section15.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section15.detail1') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section15.detail2') }} />
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-morningmind:section15.detail3') }} />
                            </li>
                        </ul>
                    </div>
                </div>
            </Style>
        </>
    );
}

export default TermsAndConditionsMorningmind;
