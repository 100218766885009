import React from 'react';
import styled from '@emotion/styled';
import { paramsjs } from 'helpers';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { POST } from 'api';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { Else, If, Then } from 'components/common/if-condition';
import moment from 'moment';
import { Col, Row } from 'components/common/grid-layout';
import { useNavigate } from 'react-router-dom';

const BoxHistory = styled.div`
    label: box-history;

    position: relative;
    font-size: 0.9rem;
    font-family: var(--detail2-font-en);
    padding: 10px 20px;
    margin: 1rem auto;
    border: solid 3px transparent;
    border-color: transparent;
    border-radius: 10px;
    background-color: rgba(193, 193, 193, 0.2);
    cursor: pointer;

    &.selected {
        border-color: var(--second-color);
    }

    .date {
        color: var(--text-normal-2);
        margin-bottom: 0.5rem;
    }
    .sub-title {
        font-size: 80%;
        font-weight: 500;
        color: var(--text-normal-2);
    }
    .sub-detail {
        color: var(--info-color);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

/**
 *
 * @param {{
 * serviceName: String,
 * onSelected: (pharmacyCode:String)=> void
 * }} props
 * @returns
 */
function ClaimHistory({ serviceName = '', onSelected = () => null }) {
    const { pharmacyCode } = paramsjs.query();
    const { t } = useTranslation();
    const { profile } = useAuth();
    const navigate = useNavigate();

    //
    const [serviceHistory, setServiceHistory] = React.useState([]);
    const [selected, setSelected] = React.useState(-1);
    //
    const handleSelect = (valuePharmacyCode, index = 0) => {
        if (selected !== index) {
            setSelected(index);
            // callback
            onSelected(valuePharmacyCode);
        } else {
            setSelected(-1);
            // callback
            onSelected('');
        }
    };

    React.useEffect(() => {
        const getClaimServiceHistory = async () => {
            try {
                const res = await POST('/user/getCompletedServiceHistory', {
                    userId: profile.userId,
                });

                setServiceHistory([...res.userGetCompletedServiceHistoryDatas]);
            } catch (error) {
                console.error('getCompletedServiceHistory ~ error', error);
            }
        };

        getClaimServiceHistory();
    }, []);

    // return
    if (serviceName !== 'telepharmacy' || pharmacyCode) return null;
    if (serviceHistory.length <= 0) return null;

    return (
        <div>
            <div className='text-primary my-3 fw-500'>
                <IconConfigWithTheme pageName='claim' iconName='history' /> &nbsp;
                {t('claim:create.receive_service_same_pharmacies')}
            </div>
            {serviceHistory.map((value, index) => (
                <BoxHistory
                    key={index}
                    className={selected === index ? 'claim-history-box selected' : 'claim-history-box'}
                    onClick={() => handleSelect(value.pharmacyCode, index)}
                >
                    <div className='date'>
                        <If
                            condition={
                                moment(value.taskBeginDate).format('DD MMM YYYY') === moment(value.taskEndDate).format('DD MMM YYYY')
                            }
                        >
                            <Then>
                                {moment(value.taskBeginDate).format('DD MMM YY , HH.mm')}
                                {' - '}
                                {moment(value.taskEndDate).format('HH.mm')}
                            </Then>
                            <Else>
                                {moment(value.taskBeginDate).format('DD MMM YY HH.mm')}
                                {' - '}
                                {moment(value.taskEndDate).format('DD MMM YY HH.mm')}
                            </Else>
                        </If>
                    </div>
                    <Row>
                        <Col xs={6} sm={6}>
                            <div className='sub-title'>{t('common:pharmacy')}</div>
                            <div className='sub-detail'>{value.pharmacyName}</div>
                        </Col>
                        <Col xs={6} sm={6}>
                            <div className='sub-title'>{t('common:pharmacist')}</div>
                            <div className='sub-detail'>{value.pharmacistName}</div>
                        </Col>
                    </Row>
                </BoxHistory>
            ))}
            <div className='text-right font-detail2'>
                <u className='cursor-pointer' onClick={() => navigate('/service?tabs=history')}>
                    {t('claim:create.see_more')}
                </u>
            </div>
        </div>
    );
}

export default ClaimHistory;
