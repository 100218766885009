import Breadcrumbs from 'components/common/breadcrumbs';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { FormPanel, Section } from 'components/layouts/page-layout';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectDateTime from './Component/SelectDateTime';
import SelectAddressList from './Component/SelectAddressList';
import ModalAddEditAddress from './Component/ModalAddEditAddress';
import { useEffect, useState } from 'react';
import { ButtonNewAddress, CustomPanel } from './SelectLabAddress.styled';
import { POST } from 'api';
import { getFirstNameLastName, paramsjs } from 'helpers';
import Button from 'components/common/button';
import EXPPopupJs from 'components/common/exp-popupjs';
import { DEFAULT_LATLNG } from 'constants/default-config';

export interface AddressData {
    id?: string;
    nomineeName?: string;
    nomineePhoneNumber?: string;
    latitude?: number;
    longitude?: number;
    address?: string;
    province?: string;
    district?: string;
    subdistrict?: string;
    zipcode?: string;
    countryCode?: string;
    dateOfBirth?: string;
}

export interface SelectSlotData {
    date: string;
    time: string | null;
    addressId?: string;
}

function SelectLabAddress() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { task } = paramsjs.query(location.search);
    //
    const [addressList, setAddressList] = useState<AddressData[]>([]);
    const [addressForm, setAddressForm] = useState<AddressData>();
    const [slotsData, setSlotsData] = useState<{ date: string; timeList: string[] }[]>();
    const [selectSlotData, setSelectSlotData] = useState<SelectSlotData>({
        date: '2024-10-12',
        time: '23:30',
        addressId: '001',
    });
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const onSelectSlot = (data: SelectSlotData) => {
        setSelectSlotData((prev) => ({ ...prev, ...data }));
    };
    const onEditAddress = (data: AddressData = {}) => {
        const _data = {
            ...data,
            latitude: Number(data.latitude || DEFAULT_LATLNG.latitude),
            longitude: Number(data.longitude || DEFAULT_LATLNG.longitude),
        };

        setAddressForm({ ...addressList[0], ..._data });
        setOpenModal(true);
    };
    const onUpdateAddress = async (data: AddressData) => {
        setLoading(true);
        // call api get address
        // console.log('addressList: ', addressList);

        // const indexOfaddress = addressList.findIndex((f) => f.id === data.id);
        // console.log('indexOfaddress: ', indexOfaddress);
        // let addressListTemp = [...addressList];
        // console.log('addressListTemp: ', addressListTemp);

        // if (indexOfaddress >= 0) {
        //     addressListTemp[indexOfaddress] = data;

        //     setAddressList([...addressListTemp]);
        //     setOpenModal(false);
        // }
        const { firstName, lastName } = getFirstNameLastName(data.nomineeName);
        const _addressList = {
            taskId: task,
            noun: 'คุณ',
            firstName: firstName,
            lastName: lastName,
            phoneNumber: data.nomineePhoneNumber,
            bookingTimestamp: '',
            fullAddress: mapAddress(data),
            address: data.address,
            subDistrict: data.subdistrict,
            district: data.district,
            province: data.province,
            zipCode: data.zipcode,
            latitude: Number(data.latitude),
            longitude: Number(data.longitude),
            dateOfBirth: data.dateOfBirth,
        };
        try {
            await POST('/service/lab-save', _addressList);
            setLoading(false);
            setOpenModal(false);
            setAddressList([data]);
        } catch (error) {
            setLoading(false);
            const _error = error as any;
            console.error('/service/lab-save --> error: ', error);

            if (_error.statusCode === 400) {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: `อยู่นอกระยะให้บริการ`,
                    message: 'คนไข้ต้องอยู่ใน กทม. เท่านั้น',
                    isClickOutSideClose: false,
                    showClose: false,
                    buttons: [
                        {
                            label: 'ตกลง',
                            variant: 'primary',
                        },
                    ],
                });
            }
        }
    };

    const getLabTimeslots = async ({ companyCode = '' }) => {
        try {
            const res = await POST('/service/telemed/lab-timeslots', { companyCode, taskId: task });
            if (res.data.dataList.length > 0) {
                setSlotsData(res.data.dataList);
                setSelectSlotData({
                    date: res.data.dataList[0].date,
                    time: res.data.dataList[0].timeList[0],
                    addressId: '001',
                });
            } else {
                EXPPopupJs.alert({
                    type: 'warning',
                    icon: 'warning',
                    title: t('common:warning'),
                    message: 'ขณะนี้ยังไม่มี ตารางเวลาว่าง',
                    showClose: false,
                    isClickOutSideClose: false,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: () => navigate(-1),
                        },
                    ],
                });
            }
        } catch (error) {
            console.error('/service/telemed/lab-timeslots --> error: ', error);
            const _error = error as any;
            if (_error.statusCode === 404) {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: `Warning`,
                    message: 'ระบบไม่พบเวลานัดหมายสำหรับบริการเก็บตัวอย่างแลป กรุณาติดต่อ PharmCare',
                    isClickOutSideClose: false,
                    showClose: false,
                    buttons: [
                        {
                            label: 'ตกลง',
                            variant: 'primary',
                            action: ({ close }) => {
                                close();
                                navigate('/tlm-ncd/fulfillment/service/confirm-lab?task=' + task);
                            },
                        },
                    ],
                });
            } else if (_error.isNoPopup) {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: t('common:error'),
                    message: _error.rejectReason,
                    isClickOutSideClose: false,
                    showClose: false,
                    buttons: [
                        {
                            label: 'ตกลง',
                            variant: 'primary',
                            action: ({ close }) => {
                                close();
                                navigate('/tlm-ncd/fulfillment/service/confirm-lab?task=' + task);
                            },
                        },
                    ],
                });
            }
        }
    };
    const getTaskDetail = async () => {
        try {
            const res = await POST('/service/getTaskDetail', { taskId: task, currentUrl: location.pathname, includeAttachmentFlag: false });
            if (res.data.taskLabBookingData)
                setAddressList([
                    {
                        id: '001',
                        nomineeName: res.data.taskLabBookingData?.firstName
                            ? res.data.taskLabBookingData?.firstName + ' ' + res.data.taskLabBookingData?.lastName
                            : '',
                        latitude: res.data.taskLabBookingData?.latitude || DEFAULT_LATLNG.latitude,
                        longitude: res.data.taskLabBookingData?.longitude || DEFAULT_LATLNG.longitude,
                        nomineePhoneNumber: res.data.taskLabBookingData?.phoneNumber,
                        address: res.data.taskLabBookingData?.address || '',
                        province: res.data.taskLabBookingData?.province || '',
                        district: res.data.taskLabBookingData?.district || '',
                        subdistrict: res.data.taskLabBookingData?.subDistrict || '',
                        zipcode: res.data.taskLabBookingData?.zipCode || '',
                        countryCode: res.data.taskLabBookingData?.countryCode || '',
                        dateOfBirth: res.data.taskLabBookingData?.dateOfBirth || '',
                    },
                ]);
            getLabTimeslots({ companyCode: res.data.task.companyCode });
        } catch (error) {
            console.error('/service/getTaskDetail --> error: ', error);
        }
    };
    const mapAddress = (value) => {
        let _address = '';

        if (value.address) {
            _address = _address + ' ' + value.address;
        }
        if (value.subdistrict) {
            _address = _address + ' ' + value.subdistrict;
        }
        if (value.district) {
            _address = _address + ' ' + value.district;
        }
        if (value.province) {
            _address = _address + ' ' + value.province;
        }
        if (value.zipcode) {
            _address = _address + ' ' + value.zipcode;
        }
        return _address;
    };
    const onSubmit = async () => {
        setLoading(true);
        const { firstName, lastName } = getFirstNameLastName(addressList[0].nomineeName);
        const _addressList = {
            taskId: task,
            noun: 'คุณ',
            firstName: firstName,
            lastName: lastName,
            phoneNumber: addressList[0].nomineePhoneNumber,
            fullAddress: mapAddress(addressList[0]),
            address: addressList[0].address,
            subDistrict: addressList[0].subdistrict,
            district: addressList[0].district,
            province: addressList[0].province,
            zipCode: addressList[0].zipcode,
            bookingTimestamp: selectSlotData.date + 'T' + selectSlotData.time + ':00.000Z',
            latitude: Number(addressList[0].latitude),
            longitude: Number(addressList[0].longitude),
            dateOfBirth: addressList[0].dateOfBirth,
        };
        try {
            await POST('/service/lab-save', _addressList);
            setLoading(false);
            navigate('/tlm-ncd/fulfillment/service/confirm-lab?task=' + task);
        } catch (error) {
            setLoading(false);
            console.error('/service/lab-save --> error: ', error);
            const _error = error as any;
            console.error('/service/lab-save --> error: ', error);

            if (_error.statusCode === 400) {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: `อยู่นอกระยะให้บริการ`,
                    message: 'คนไข้ต้องอยู่ใน กทม. เท่านั้น',
                    isClickOutSideClose: false,
                    showClose: false,
                    buttons: [
                        {
                            label: 'ตกลง',
                            variant: 'primary',
                        },
                    ],
                });
            }
        }
    };
    useEffect(() => {
        getTaskDetail();
    }, []);

    return (
        <>
            <Breadcrumbs
                title={'Booking'}
                icon={<IconConfigWithTheme pageName='comfirm_med' iconName='breadcrumbs' />}
                onBack={() => navigate(-1)}
            />
            <Section>
                <FormPanel>
                    <CustomPanel>
                        {slotsData && <SelectDateTime data={selectSlotData} slotsData={slotsData} onSelect={onSelectSlot} />}
                        {addressList[0]?.address && (
                            <SelectAddressList
                                data={selectSlotData}
                                dataSource={addressList}
                                onSelect={onSelectSlot}
                                onEdit={onEditAddress}
                            />
                        )}
                        {(addressList.length < 1 || !addressList[0]?.address) && (
                            <div className='py-3'>
                                <ButtonNewAddress className='add-address' onClick={() => onEditAddress()}>
                                    <IconConfigWithTheme
                                        pageName='detail_address_type01'
                                        iconName='add_new_address'
                                        className='cursor-pointer text-wram-green mr-2'
                                    />
                                    เพิ่ม/ค้นหาที่อยู่
                                </ButtonNewAddress>
                            </div>
                        )}
                        <div className='d-flex justify-content-center pt-4'>
                            <Button
                                type='submit'
                                variant='primary'
                                disabled={!addressList[0]?.address}
                                onClick={onSubmit}
                                loading={loading}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </CustomPanel>
                </FormPanel>
            </Section>
            <ModalAddEditAddress
                loading={loading}
                open={openModal}
                data={addressForm}
                onClose={() => setOpenModal(false)}
                onUpdateAddress={onUpdateAddress}
            />
        </>
    );
}

export default SelectLabAddress;
