import styled from '@emotion/styled';
import { breakpoint, extendesClassname } from 'helpers';
import React, { useEffect, useState } from 'react';

const StyleFormFooter = styled.div`
    label: form-footer;

    --form-footer-panel-double-button-height: calc((var(--form-footer-panel-height) * 2) * 0.9); // aspect-ratio 9:10

    position: static;
    width: 96%;
    max-width: 900px;
    height: var(--form-footer-panel-height);
    margin: auto auto 0 auto;

    /* border: 1px solid #0f0; // ! test */

    .form-footer-panel-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 100%;
        height: var(--form-footer-panel-height);
        padding: var(--form-footer-panel-padding-y) var(--form-footer-panel-padding-x);
        z-index: var(--form-footer-panel-sticky-zindex);

        /* border: 1px solid #0ff; // ! test */

        button {
            width: 100%;
            max-width: var(--form-footer-panel-button-max-width);
        }
    }

    /* for two button */
    &.form-footer-panel-double-button {
        .form-footer-panel-button {
            justify-content: space-between;

            button {
                width: 48%;
            }
        }
        ${breakpoint('XS')} {
            height: var(--form-footer-panel-double-button-height);

            .form-footer-panel-button {
                flex-wrap: wrap;
                max-width: unset;
                height: var(--form-footer-panel-double-button-height);

                button {
                    width: 100%;
                    max-width: 100%;
                }

                button ~ button {
                    margin-top: 1rem;
                }
            }
        }
    }

    /* keyboard open */
    .form-footer-panel-show-button {
        position: fixed;
        right: 12px;
        bottom: calc(var(--form-footer-panel-height) / 2 * -1);
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(var(--form-footer-panel-height) / 2);
        height: calc(var(--form-footer-panel-height) / 2);
        color: var(--white);
        border-radius: 100%;
        background-color: var(--primary-color);
        z-index: 2;
        transition: all 0.5s;
    }

    ${breakpoint('XS')} {
        width: 100%;

        .form-footer-panel-button {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        /* sticky */
        &.form-footer-panel-sticky .form-footer-panel-button {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            border-top-left-radius: var(--form-footer-panel-sticky-border-top-radius);
            border-top-right-radius: var(--form-footer-panel-sticky-border-top-radius);
            background-color: var(--form-footer-panel-sticky-background-color);
            box-shadow: 0 -5px 10px #00000050;
            transition: all 0.5s;
        }

        /* keyboard open */
        &.form-footer-panel-sticky.form-footer-panel-keyboard-opened {
            .form-footer-panel-button {
                bottom: calc(var(--form-footer-panel-height) * -1);

                /* border: 4px solid #0ff; //! test */
            }
            .form-footer-panel-show-button {
                bottom: 12px;
            }
        }

        &.form-footer-panel-sticky.form-footer-panel-keyboard-opened.form-footer-panel-double-button .form-footer-panel-button {
            bottom: calc(var(--form-footer-panel-double-button-height) * -2);
        }
    }
`;

const StyleLeftSide = styled.div`
    label: left-side;

    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20%;
    min-width: 280px;
    height: auto;
    margin-left: auto;
    padding: 24px;

    button {
        width: 100%;
        max-width: var(--form-footer-panel-button-max-width);
    }

    ${breakpoint('XL')} {
        justify-content: flex-start;
        width: 48%;
        max-width: 450px;
        padding-left: 0;
    }

    ${breakpoint('XS')} {
        display: none;
    }
`;

const StyleRightSide = styled.div`
    label: right-side;

    --form-footer-panel-double-button-height: calc((var(--form-footer-panel-height) * 2) * 0.9); // aspect-ratio 9:10

    position: static;
    display: flex;
    align-items: center;
    width: 20%;
    min-width: 280px;
    height: auto;
    margin-right: auto;

    /* border: 1px solid #0f0; // ! test */

    .form-footer-panel-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 100%;
        padding: var(--form-footer-panel-padding-y) var(--form-footer-panel-padding-x);
        z-index: var(--form-footer-panel-sticky-zindex);
        /* border: 1px solid #0ff; // ! test */

        button {
            width: 100%;
            max-width: var(--form-footer-panel-button-max-width);
        }
    }

    /* for two button */
    &.form-footer-panel-double-button {
        .form-footer-panel-button {
            flex-wrap: wrap;

            button ~ button {
                margin-top: 1rem;
            }
        }

        ${breakpoint('XS')} {
            height: var(--form-footer-panel-double-button-height);

            .form-footer-panel-button {
                height: var(--form-footer-panel-double-button-height);
            }
        }
    }

    /* keyboard open */
    .form-footer-panel-show-button {
        position: fixed;
        right: 12px;
        bottom: calc(var(--form-footer-panel-height) / 2 * -1);
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(var(--form-footer-panel-height) / 2);
        height: calc(var(--form-footer-panel-height) / 2);
        color: var(--white);
        border-radius: 100%;
        background-color: var(--primary-color);
        z-index: 2;
        transition: all 0.5s;
    }

    ${breakpoint('XL')} {
        width: 48%;
        max-width: 450px;

        .form-footer-panel-button {
            justify-content: flex-end;
            padding-right: 0;
        }
    }

    ${breakpoint('XS')} {
        width: 100%;
        height: var(--form-footer-panel-height);

        .form-footer-panel-button {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            justify-content: center;
            height: var(--form-footer-panel-height);
            padding-right: var(--form-footer-panel-padding-x);
        }

        /* sticky */
        &.form-footer-panel-sticky .form-footer-panel-button {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            border-top-left-radius: var(--form-footer-panel-sticky-border-top-radius);
            border-top-right-radius: var(--form-footer-panel-sticky-border-top-radius);
            background-color: var(--form-footer-panel-sticky-background-color);
            box-shadow: 0 -5px 10px #00000050;
            transition: all 0.5s;
        }

        /* keyboard open */
        &.form-footer-panel-sticky.form-footer-panel-keyboard-opened {
            .form-footer-panel-button {
                bottom: calc(var(--form-footer-panel-height) * -1);

                /* border: 4px solid #0ff; //! test */
            }
            .form-footer-panel-show-button {
                bottom: 12px;
            }
        }

        &.form-footer-panel-sticky.form-footer-panel-keyboard-opened.form-footer-panel-double-button .form-footer-panel-button {
            bottom: calc(var(--form-footer-panel-double-button-height) * -2);
        }
    }
`;

/**
 * @version 1.0.4 change ref.current.style to style inline
 * @version 1.0.3 modify css for "numberOfButtons" = double
 * @version 1.0.2 add parameter "numberOfButtons"
 * @version 1.0.1 create
 *
 * @param {{
 * requireSticky?: boolean,
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */
function FormFooter({ requireSticky, children, ...props }) {
    const elementRef = React.useRef();
    //
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    //

    // const isDoubleButton = React.useMemo(() => {
    //     let _numberOfButtons = []

    //     if (elementRef.current) {
    //         _numberOfButtons = elementRef.current.querySelectorAll('button')
    //     }

    //     return _numberOfButtons.length > 1
    // }, [_numberOfButtons])

    let _numberOfButtons = [];
    if (elementRef.current) {
        _numberOfButtons = elementRef.current.querySelectorAll('button');
    }
    const isDoubleButton = React.useMemo(() => {
        return _numberOfButtons.length > 1;
    }, [_numberOfButtons]);

    useEffect(() => {
        const onKeyboardPop = () => {
            const newState = window.screen.height - 300 > window.visualViewport.height;
            if (isKeyboardOpen !== newState) {
                setIsKeyboardOpen(true);
                // setIsKeyboardOpen(false) //! test
            } else {
                setIsKeyboardOpen(false);
                // setIsKeyboardOpen(true) //! test
            }
        };

        // onKeyboardPop() //! test

        if (requireSticky) {
            window.visualViewport.addEventListener('resize', onKeyboardPop);
        }
        return () => {
            window.visualViewport.removeEventListener('resize', onKeyboardPop);
        };
    }, []);

    return (
        <StyleFormFooter
            {...props}
            {...extendesClassname([
                'form-footer-panel',
                isKeyboardOpen ? 'form-footer-panel-keyboard-opened' : '',
                requireSticky ? 'form-footer-panel-sticky' : '',
                isDoubleButton ? 'form-footer-panel-double-button' : '',
                props.className,
            ])}
            ref={elementRef}
        >
            <div className={`form-footer-panel-button`}>{children}</div>
            <div className='form-footer-panel-show-button' onClick={() => setIsKeyboardOpen(false)}>
                <i className='fas fa-chevron-double-right'></i>
            </div>
        </StyleFormFooter>
    );
}

/**
 * @version 1.0.2 change ref.current.style to style inline
 * @version 1.0.1 create
 *
 * @param {{
 * requireSticky: boolean,
 * children: Element
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */
function FormTwinTailRightSide({ requireSticky = false, children, ...props }) {
    const elementRef = React.useRef();
    //

    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    //
    let _numberOfButtons = [];
    if (elementRef.current) {
        _numberOfButtons = elementRef.current.querySelectorAll('button');
    }
    const isDoubleButton = React.useMemo(() => {
        // let _numberOfButtons = []

        // if (elementRef.current) {
        //     _numberOfButtons = elementRef.current.querySelectorAll('button')
        //     console.log('_numberOfButtons: ', _numberOfButtons)
        // }

        return _numberOfButtons.length > 1;
    }, [_numberOfButtons]);
    // }, [elementRef.current])

    const onKeyboardPop = () => {
        const newState = window.screen.height - 300 > window.visualViewport.height;
        if (isKeyboardOpen !== newState) {
            setIsKeyboardOpen(true);
            // setIsKeyboardOpen(false) //! test
        } else {
            setIsKeyboardOpen(false);
            // setIsKeyboardOpen(true) //! test
        }
    };

    useEffect(() => {
        if (requireSticky) {
            window.visualViewport.addEventListener('resize', onKeyboardPop);
        }
        return () => {
            window.visualViewport.removeEventListener('resize', onKeyboardPop);
        };
    }, []);

    return (
        <StyleRightSide
            {...props}
            {...extendesClassname([
                'form-twin-tail-right',
                isKeyboardOpen ? 'form-footer-panel-keyboard-opened' : '',
                requireSticky ? 'form-footer-panel-sticky' : '',
                isDoubleButton ? 'form-footer-panel-double-button' : '',
                props.className,
            ])}
            ref={elementRef}
        >
            <div className={`form-footer-panel-button`}>{children}</div>
            <div className='form-footer-panel-show-button' onClick={() => setIsKeyboardOpen(false)}>
                <i className='fas fa-chevron-double-right'></i>
            </div>
        </StyleRightSide>
    );
}

function FormTwinTailLeftSide({ children, ...props }) {
    return (
        <StyleLeftSide {...props} {...extendesClassname(['form-twin-tail-left', props.className])}>
            {children}
        </StyleLeftSide>
    );
}

export { FormTwinTailLeftSide, FormTwinTailRightSide, FormFooter };
