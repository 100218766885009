import { ICON_CONFIG } from 'constants/icon-config';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import React from 'react';
import { extendesClassname } from 'helpers';

/**
 * @param {{
 * pageName: string,
 * iconName: string,
 * className?: string,
 * onClick?: ()=> void,
 * style?: object
 * }} props
 * @returns
 */

export default function IconConfigWithTheme({ pageName = '', iconName = '', className = '', onClick = null, style = {}, iconRef }) {
    const theme = useThemeWithPartner();

    return (
        <i
            {...extendesClassname([ICON_CONFIG[pageName][iconName][theme.name] || ICON_CONFIG[pageName][iconName]['default'], className])}
            style={style}
            onClick={onClick}
            ref={iconRef}
        />
    );
}
