import React from 'react';
import { Carousel, CarouselItem } from 'components/common/Carousel';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { breakpoint } from 'helpers';
import { HomeCertificationWrapper } from './Home.styled';

function HomeCertification() {
    const { t } = useTranslation();
    return (
        <HomeCertificationWrapper controls={false}>
            <CarouselItem>
                <div className='home-certification-box'>
                    <div>{t('home:customer')}</div>
                    <div className='home-certification-box-title' data-test-id='title'>
                        {t('home:reviews')}
                    </div>
                    <div className='home-certification-avatar'>
                        <div className='avatar-user'>
                            <img
                                src='/images/home/home-certification-user.png'
                                alt='home-certification-user'
                                title='คำรับรอง เสียงจากผู้ใช้'
                            />
                        </div>
                        <div className='certification-star'>
                            <img src='/images/home/home-certification-star.png' alt='home-certification-star' title='service rating' />
                        </div>
                    </div>
                    <div className='d-flex align-items-start justify-content-center'>
                        <div className='home-certification-box-letter'>"</div>
                        <p>{t('home:an_all_encompassing_health_solution_webapp')}</p>
                    </div>
                    <div>— {t('home:jirapat_duangchawii')}</div>
                </div>
            </CarouselItem>
        </HomeCertificationWrapper>
    );
}

export default HomeCertification;
