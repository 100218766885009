import { deleteCookie } from 'cookies-next';

export { default as breakpoint } from './breakpoint';
export { default as fluidSizing } from './fluidSizing';
export { default as extendesClassname } from './extendesClassname';
export { default as checkDevice } from './checkDevice';
export { default as numberjs } from './numberJs';
export { default as openPeriod } from './open-period';
export { default as locationjs } from './locationJs';
export { default as paramsjs } from './paramsJs';
export { default as cryptojs } from './cryptoJs';

export const clearUserLocalStorage = () => {
    window.localStorage.removeItem('_token_');

    deleteCookie('userToken', { domain: process.env.REACT_APP_DOMAIN_YAPHROM });
    deleteCookie('userToken', { domain: process.env.REACT_APP_DOMAIN });
    deleteCookie('userToken', { domain: 'pharmcare.co' });
};

export const getFullName = (firstName = '', lastName = '') => {
    let _firstName = firstName?.replace(/\s\s+/g, '');
    let _lastName = lastName?.replace(/\s\s+/g, '');

    if (_firstName && _lastName) {
        return _firstName + ' ' + lastName;
    }

    if (_firstName) {
        return _firstName;
    }

    return '';
};

export const getFirstNameLastName = (fullName = '') => {
    let firstNameNlastName = fullName.trim();
    firstNameNlastName = firstNameNlastName.replace(/\s\s+/g, ' ').split(' ');
    let firstName = '';
    let lastName = '';

    if (firstNameNlastName[0]) {
        firstName = firstNameNlastName[0];
    }
    if (firstNameNlastName[1]) {
        lastName = firstNameNlastName[1];
    }

    return { firstName, lastName };
};
