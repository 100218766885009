import React from 'react';
import FormGroup from '../form-group';
import InputMask from 'react-input-mask';
import { extendesClassname } from '../utils';
import FormInvalidFeedback from '../form-invalid-feedback';
import FormRecommend from '../form-recommend';
import FormLabel from '../form-label';

/**
 *
 * @param {{
 * title?: React.ReactNode,
 * label?: React.ReactNode,
 * mask?: String | null,
 * maskChar?: String | null,
 * className?: React.ReactNode,
 * classNameLabel?: String,
 * classNameField?: String,
 * errorMsg?: React.ReactNode,
 * recommendMsg?: React.ReactNode,
 * isInvalid?: Boolean,
 * labelTextSmall?: Boolean,
 * titleTextSmall?: Boolean,
 * refInput?: React.Ref,
 * xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs,
 * } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> } peops
 * @returns
 */
function FieldMask({
    title = '',
    label = '',
    mask = null,
    maskChar = null,
    className = '',
    classNameLabel = '',
    classNameField = '',
    errorMsg = '',
    recommendMsg = '',
    isInvalid = false,
    labelTextSmall = true,
    titleTextSmall = true,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
    refInput,
    children,
    ...props
}) {
    const inputRef = React.useRef(null);

    React.useEffect(() => {
        const setInvalid = () => {
            const _isInvalidClassname = inputRef.current?.classList?.contains('is-invalid');

            if (isInvalid) {
                inputRef.current.setCustomValidity('Invalid field.');

                if (!_isInvalidClassname) inputRef.current.classList.add('is-invalid');
            } else if (_isInvalidClassname) {
                inputRef.current.classList.remove('is-invalid');
            }
        };

        setInvalid();

        return () => inputRef.current.setCustomValidity('');
    }, [isInvalid]);

    return (
        <FormGroup xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} xxxl={xxxl} className={classNameField}>
            {(title || label) && (
                <FormLabel textSmall={labelTextSmall && titleTextSmall} className={classNameLabel} required={props.required}>
                    {title || label}
                </FormLabel>
            )}

            <InputMask
                {...props}
                {...extendesClassname(['exp-form-control', className])}
                inputRef={(r) => (inputRef.current = r)}
                mask={mask}
                maskChar={maskChar}
            />
            {children}
            {errorMsg && <FormInvalidFeedback>{errorMsg}</FormInvalidFeedback>}
            {recommendMsg && <FormRecommend>{recommendMsg}</FormRecommend>}
        </FormGroup>
    );
}

export default FieldMask;
