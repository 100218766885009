export function generateCssLang(variable = '') {
    let subclassEN = '';
    let subclassTH = '';

    if (variable.th) {
        if (typeof variable.th === 'object') {
            Object.keys(variable.th).forEach((key) => {
                subclassTH += `--${key}: ${variable.th[key]};`;
            });
        }
    }
    if (variable.en) {
        if (typeof variable.en === 'object') {
            Object.keys(variable.en).forEach((key) => {
                subclassEN += `--${key}: ${variable.en[key]};`;
            });
        }
    }
    return `&[lang="th"],&[lang="TH"] {${subclassTH}} &[lang="en"],&[lang="EN"] {${subclassEN}}`;
}
export function generateCssColor(value = '') {
    if (typeof value === 'object') {
        let subclass = '';
        Object.keys(value).forEach((key) => {
            subclass += `--${key}: ${value[key]};`;
        });
        return subclass;
    }
}
export function generateCssComponents(variables = '') {
    let themeVariable = '';

    Object.keys(variables).forEach((key) => {
        themeVariable += generateCssVariable(key, variables[key]);
    });

    return themeVariable;
}
function generateCssVariable(variable = '', value = '') {
    if (variable.startsWith('other')) {
        // return '';
        if (typeof value === 'object') {
            let subclass = '';
            Object.keys(value).forEach((key) => {
                subclass += `--${key}: ${value[key]};`;
            });
            return subclass;
        }
    }
    if (variable.startsWith('font') || variable.startsWith('components')) {
        // return '';
        if (typeof value === 'object') {
            let subclass = '';
            Object.keys(value).forEach((key) => {
                subclass += generateCssVariable2(key, value[key]);
            });

            return `${subclass}`;
        }
    }

    if (typeof value === 'object') {
        let subclass = '';
        Object.keys(value).forEach((key) => {
            subclass += generateCssVariable(key, value[key]);
        });

        return `${variable} {${subclass}}`;
    }
    return `--${variable}: ${value};`;
}
