import styled from '@emotion/styled';
import { EXPCollapseComplete } from 'components/common/exp-collapse';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Style = styled.div`
    label: privacy-policy-with-theme;

    width: 94%;
    max-width: 900px;
    padding-bottom: 40px;
    margin: 0 auto;

    .custom-collapse-title {
        border-radius: 10px;
        padding: 10px;
        margin-top: 10px;
        font-size: 1.2rem;
        background-color: var(--lightseagreen-2);
    }
`;

function PrivacyPolicyRabbit() {
    const { t } = useTranslation();

    return (
        <Style className='custom-panel'>
            <div className='list-style-nested-number'>
                <h4 className='text-primary text-center'>{t('privacy-policy-rabbit:title')}</h4>
                <h4 className='text-primary text-center'>{t('privacy-policy-rabbit:subtitle')}</h4>

                <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:detail1') }} />
                <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:detail2') }} />
                {t('privacy-policy-rabbit:detail3')}

                {/* <Collaspe_rabbit1 /> */}
                <EXPCollapseComplete
                    defaultShow={true}
                    classNameTitle='custom-collapse-title'
                    className='list-style-nested-number-increment'
                    title={<>1. {t('privacy-policy-rabbit:collapse1.title')}</>}
                >
                    <div className='detail'>
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail1') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2') }} />
                        <ul className='list-style-number-right-dot'>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_1.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_1.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_2.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_2.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_3.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_3.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_4.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_4.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_5.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_5.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_6.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_6.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_7.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_7.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_8.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_8.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_9.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_9.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_10.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_10.detail1') }}
                                />
                            </li>
                            <li>
                                <strong dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_11.title') }} />
                                <div
                                    className='d-inline'
                                    dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail2_11.detail1') }}
                                />
                            </li>
                        </ul>
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail3') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail4') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail5') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail6') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail7') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse1.detail8') }} />
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit2 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    className='list-style-nested-number-increment'
                    title={<>2. {t('privacy-policy-rabbit:collapse2.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>
                        <p>{t('privacy-policy-rabbit:collapse2.detail1')}</p>
                        <ul className='list-style-nested-number-nomal'>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse2.detail1_1.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse2.detail1_1.detail1')}</div>
                                <ul className='list-style-number-right-dot'>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse2.detail1_1_1.title') }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse2.detail1_1_1.detail1') }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse2.detail1_1_2.title') }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse2.detail1_1_2.detail1') }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse2.detail1_1_3.title') }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse2.detail1_1_3.detail1') }}
                                        />
                                    </li>

                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse2.detail1_1_4.title') }}
                                        />

                                        <ul className='list-style-number-right-dot'>
                                            <li>
                                                <strong
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('privacy-policy-rabbit:collapse2.detail1_1_4_1.title'),
                                                    }}
                                                />
                                                <div
                                                    className='d-inline'
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('privacy-policy-rabbit:collapse2.detail1_1_4_1.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <strong
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('privacy-policy-rabbit:collapse2.detail1_1_4_2.title'),
                                                    }}
                                                />
                                                <div
                                                    className='d-inline'
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('privacy-policy-rabbit:collapse2.detail1_1_4_2.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <strong
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('privacy-policy-rabbit:collapse2.detail1_1_4_3.title'),
                                                    }}
                                                />
                                                <div
                                                    className='d-inline'
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('privacy-policy-rabbit:collapse2.detail1_1_4_3.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <strong
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('privacy-policy-rabbit:collapse2.detail1_1_4_4.title'),
                                                    }}
                                                />
                                                <div
                                                    className='d-inline'
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('privacy-policy-rabbit:collapse2.detail1_1_4_4.detail1'),
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse2.detail1_2.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse2.detail1_2.detail1')}</div>
                                <ul className='list-style-number-right-dot mt-1'>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_1.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_2.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_3.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_4.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_4.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_5.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_5.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_6.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_6.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_7.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_7.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_8.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_8.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_9.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_9.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_10.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_10.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_11.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_11.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_12.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_12.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_13.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_13.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_14.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_14.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_15.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_15.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_16.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_16.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_17.title'),
                                            }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-rabbit:collapse2.detail1_2_17.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                                <div className='mt-3'>{t('privacy-policy-rabbit:collapse2.detail1_2.detail2')}</div>
                            </li>
                        </ul>
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit3 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    className='list-style-nested-number-increment'
                    title={<>3. {t('privacy-policy-rabbit:collapse3.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>
                        <p>{t('privacy-policy-rabbit:collapse3.detail1')}</p>
                        <ul className='list-style-nested-number-nomal'>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_1.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_1.detail1')}</div>
                                <ul className='list-style-number-right-bracket'>
                                    <li>
                                        <strong>{t('privacy-policy-rabbit:collapse3.detail1_1_1.title')} </strong>
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse3.detail1_1_1.detail1') }}
                                        />
                                    </li>
                                    <li>
                                        <strong>{t('privacy-policy-rabbit:collapse3.detail1_1_2.title')} </strong>
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse3.detail1_1_2.detail1') }}
                                        />
                                    </li>
                                    <li>
                                        <strong>{t('privacy-policy-rabbit:collapse3.detail1_1_3.title')} </strong>
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse3.detail1_1_3.detail1') }}
                                        />
                                    </li>
                                </ul>
                                <div className='mt-3'>{t('privacy-policy-rabbit:collapse3.detail1_1.detail2')}</div>
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_2.title')}</strong>
                                <p>{t('privacy-policy-rabbit:collapse3.detail1_2.detail1')}</p>
                                {t('privacy-policy-rabbit:collapse3.detail1_2.detail2')}
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_3.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_3.detail1')}</div>
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_4.title')}</strong>
                                <p>{t('privacy-policy-rabbit:collapse3.detail1_4.detail1')}</p>
                                {t('privacy-policy-rabbit:collapse3.detail1_4.detail2')}
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_5.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_5.detail1')}</div>
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_6.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_6.detail1')}</div>
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_7.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_7.detail1')}</div>
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_8.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_8.detail1')}</div>
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_9.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_9.detail1')}</div>
                            </li>
                            <li className='mt-3'>
                                <strong>{t('privacy-policy-rabbit:collapse3.detail1_10.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_10.detail1')}</div>
                                <div>{t('privacy-policy-rabbit:collapse3.detail1_10.detail2')}</div>
                                <ul className='list-style-number-right-dot mt-1'>
                                    <li dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse3.detail1_10_1.detail1') }} />
                                    <li dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse3.detail1_10_2.detail1') }} />
                                    <li dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse3.detail1_10_3.detail1') }} />
                                </ul>
                                {t('privacy-policy-rabbit:collapse3.detail1_10.detail3')}
                            </li>
                        </ul>
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit4 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    title={<>4. {t('privacy-policy-rabbit:collapse4.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>{t('privacy-policy-rabbit:collapse4.detail1')}</div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit5 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    title={<>5. {t('privacy-policy-rabbit:collapse5.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>{t('privacy-policy-rabbit:collapse5.detail1')}</div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit6 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    title={<>6. {t('privacy-policy-rabbit:collapse6.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>
                        <p>{t('privacy-policy-rabbit:collapse6.detail1')}</p>
                        <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-rabbit:collapse6.detail2') }}></p>
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit7 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    title={<>7. {t('privacy-policy-rabbit:collapse7.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>
                        <p>{t('privacy-policy-rabbit:collapse7.detail1')}</p>
                        <p>{t('privacy-policy-rabbit:collapse7.detail2')}</p>
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit8 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    title={<>8. {t('privacy-policy-rabbit:collapse8.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>
                        <p>{t('privacy-policy-rabbit:collapse8.detail1')}</p>
                        <ul className='list-style-number-right-dot'>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse8.detail1_1.title')}</strong>
                                {t('privacy-policy-rabbit:collapse8.detail1_1.detail1')}
                            </li>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse8.detail1_2.title')}</strong>
                                {t('privacy-policy-rabbit:collapse8.detail1_2.detail1')}
                            </li>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse8.detail1_3.title')}</strong>
                                {t('privacy-policy-rabbit:collapse8.detail1_3.detail1')}
                            </li>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse8.detail1_4.title')}</strong>
                                {t('privacy-policy-rabbit:collapse8.detail1_4.detail1')}
                            </li>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse8.detail1_5.title')}</strong>
                                {t('privacy-policy-rabbit:collapse8.detail1_5.detail1')}
                            </li>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse8.detail1_6.title')}</strong>
                                {t('privacy-policy-rabbit:collapse8.detail1_6.detail1')}
                            </li>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse8.detail1_7.title')}</strong>
                                {t('privacy-policy-rabbit:collapse8.detail1_7.detail1')}
                            </li>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse8.detail1_8.title')}</strong>
                                {t('privacy-policy-rabbit:collapse8.detail1_8.detail1')}
                            </li>
                        </ul>
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit9 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    title={<>9. {t('privacy-policy-rabbit:collapse9.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>{t('privacy-policy-rabbit:collapse9.detail1')}</div>
                </EXPCollapseComplete>

                {/* <Collaspe_rabbit10 /> */}
                <EXPCollapseComplete
                    classNameTitle='custom-collapse-title'
                    title={<>10. {t('privacy-policy-rabbit:collapse10.title')}</>}
                    defaultShow={true}
                >
                    <div className='detail'>
                        <p>{t('privacy-policy-rabbit:collapse10.detail1')}</p>
                        <ul className='list-style-number-right-bracket'>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse10.detail1_1.title')}</strong>
                                {t('privacy-policy-rabbit:collapse10.detail1_1.detail1')}
                                <div>{t('privacy-policy-rabbit:collapse10.detail1_1.detail2')}</div>
                                <div>{t('privacy-policy-rabbit:collapse10.detail1_1.detail3')}</div>
                            </li>
                            <li>
                                <strong>{t('privacy-policy-rabbit:collapse10.detail1_2.title')}</strong>
                                <div>{t('privacy-policy-rabbit:collapse10.detail1_2.detail1')}</div>
                                <div>{t('privacy-policy-rabbit:collapse10.detail1_2.detail2')}</div>
                            </li>
                        </ul>
                    </div>
                </EXPCollapseComplete>
            </div>
        </Style>
    );
}

export default PrivacyPolicyRabbit;
