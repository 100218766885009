import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/button';
import ServiceDetail from 'modules/page/landing/ServiceDetail';
import { cryptojs } from 'helpers';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useAuth from 'hooks/useAuth';
import type { DataEncodeURLProps } from 'types';
import ServiceSymstom from 'modules/page/landing/ServiceSymstom';
import ServiceMethod from 'modules/page/landing/ServiceMethod';
import ServiceRating from 'modules/page/landing/ServiceRating';
import ServiceQeustionAndAnswer, { QeustionBox } from 'modules/page/landing/ServiceQnA';
import ServiceRelate from 'modules/page/landing/ServiceRelate';
import LandingLogin from 'modules/page/landing/LandingLogin';

function TLMNCDServiceLanding() {
    const navigate = useNavigate();
    const { auth } = useAuth();

    const timer = useRef<NodeJS.Timeout>();
    const [showMethod, setShowMethod] = useState(1);

    const params = useMemo(() => {
        const origin = window.location.origin;
        return origin.includes('fastcare.co') ? '?voucher=fastcare01' : '';
    }, [window.location.origin]);

    const onNextPage = () => {
        let path = process.env.REACT_APP_LIFF_REDIRECT_DEFAULT;
        let defaultPath = '/tlm-ncd/claim';

        let dataForEncode: DataEncodeURLProps = {
            path: defaultPath,
            params: window.location.search || '?fromService=true',
            data: {
                authorization: null,
                userId: null,
                autoLogin: 0,
            },
        };
        if (window.location.origin.includes('fastcare.co')) {
            path = process.env.REACT_APP_LIFF_REDIRECT_FASTCARE;
            dataForEncode = {
                ...dataForEncode,
                params: window.location.search || '?voucher=fastcare01&fromService=true',
                data: {
                    authorization: null,
                    userId: null,
                    autoLogin: 0,
                    partner: '18',
                },
            };
        }
        const encode = cryptojs.encode(dataForEncode);
        if (auth.isAuth) {
            return navigate(dataForEncode.path + dataForEncode.params);
        }
        return window.location.assign(path + '?to=' + encode);
    };

    const onChangeMethod = (method: number) => {
        let _method = method;

        clearInterval(timer.current);
        setShowMethod(_method);

        timer.current = setInterval(() => {
            _method++;

            if (_method < 4) {
                setShowMethod(_method);
            } else {
                setShowMethod(1);
                _method = 1;
            }
        }, 5000);
    };

    useEffect(() => {
        onChangeMethod(1);

        return () => clearInterval(timer.current);
    }, []);

    return (
        <>
            <ServiceDetail primaryBgc='#b57ba6' secondBgc='#f9f9fa'>
                {/* <ServiceDetail primaryBgc='#3a94fc' secondBgc='#f9f9fa'> */}
                <div className='landing-service'>
                    <div className='service-panel service-content pb-0'>
                        <div className='title'>บริการ</div>
                        <h1 className='sub-title'>ปรึกษาแพทย์โรคเรื้อรัง</h1>
                        <h4 className='detail'>{/* <div>ปรึกษาแพทย์ผู้เชี่ยวชาญเฉพาะทาง </div> */}</h4>
                        <div className='service-image mb-3'>
                            <img src='/images/service-landing/service-tlm-desktop.webp' className='desktop' alt='desktop' />
                            <img src='/images/service-landing/service-tlm-mobile.webp' className='mobile' alt='mobile' />
                        </div>
                    </div>
                    <div className='service-panel service-detail'>
                        <h3 className='title'>รายละเอียดบริการ</h3>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_tlm' iconName='sevice_detail_time' />
                            </div>
                            <h4>ให้บริการ 09.00-20.00 น. สำหรับโรคเรื้อรัง</h4>
                        </div>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_tlm' iconName='sevice_detail' />
                            </div>
                            <h4>ปรึกษาแพทย์ผู้เชี่ยวชาญเฉพาะทาง รับใบสั่งยาพร้อมบริการจัดส่งถึงบ้าน</h4>
                        </div>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_tlm' iconName='dalivery_time' />
                            </div>
                            <h4>ระยะเวลาจัดส่งประมาณ 2-3 ชม.</h4>
                        </div>
                        <h3 className='title'>อัตราค่าบริการ</h3>
                        <div className='sevice-rate-panel'>
                            <div className='sevice-rate'>
                                <div className='list'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_tlm' iconName='sevice_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <div className='list-title'>ค่าแพทย์</div>
                                        <h4 className='list-detail'>
                                            <span>เริ่มต้นที่ 200 บาท</span>
                                        </h4>
                                    </div>
                                </div>
                                <div className='list special-row'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_tlm' iconName='medicine_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <div className='list-title'>ค่ายา (ถ้ามี)</div>
                                        <h4 className='list-detail'>
                                            <span>ราคาตามหน้าร้านยา</span>
                                        </h4>
                                    </div>
                                </div>
                                <div className='list'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_tlm' iconName='dalivery_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่าจัดส่ง</h3>
                                        <h4 className='list-detail'>
                                            <div>ไม่เกิน 45 บาท</div>
                                            {/* <span>เริ่มต้นที่ 57 บาท</span> */}
                                        </h4>
                                    </div>
                                </div>
                                <div className='list'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_tlm' iconName='management_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่าธรรมเนียมการจัดการ</h3>
                                        <h4 className='list-detail'>
                                            <div className='line-through'>55 บาท </div>
                                            <div>(*ฟรีค่าธรรมเนียมถึง ธันวาคม 2566)</div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='panel-footer'>
                                <Button
                                    data-test-id='consultNow'
                                    style={{ backgroundColor: '#b57ba6', color: 'var(--white)' }}
                                    onClick={() => onNextPage()}
                                >
                                    ปรึกษาแพทย์โรคเรื้อรังตอนนี้
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='consult-now'>
                    <div className='custom-btn'>
                        <Button style={{ backgroundColor: '#b57ba6', color: 'var(--white)' }} onClick={() => onNextPage()}>
                            ปรึกษาแพทย์โรคเรื้อรังตอนนี้
                        </Button>
                    </div>
                </div>
            </ServiceDetail>
            <ServiceMethod secondBgc='#f9f9fa'>
                <div className='landing-panel'>
                    <h3 className='title'>ขั้นตอนในการรับบริการ</h3>
                    <div className='method'>
                        <div className='method-row mark-1'>
                            <div className='method-row mark-2'>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 1 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(1)}
                                    >
                                        <div className='circle'>1</div>
                                        <h3>คุยกับเแพทย์</h3>
                                    </div>
                                </div>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 2 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(2)}
                                    >
                                        <div className='circle'>2</div>
                                        <h3>รอใบสั่งยา</h3>
                                    </div>
                                </div>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 3 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(3)}
                                    >
                                        <div className='circle'>3</div>
                                        <h3>รอรับยา</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='method-row mark-3 line-center'>
                                <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-tlm-1.webp' title='คุยกับเแพทย์' alt='คุยกับเแพทย์' />
                                    </div>
                                </div>
                                <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-tlm-2.webp' title='รอใบสั่งยา' alt='รอใบสั่งยา' />
                                    </div>
                                </div>
                                <div className={showMethod === 3 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-3.webp' title='รอรับยา' alt='รอรับยา' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='method-row'>
                            <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>1. ปรึกษาแพทย์</div>
                                    </div>
                                    <p>กรอกข้อมูลสุขภาพ อาการของคุณ และเริ่มต้นสนทนากับแพทย์ ภายใน 5 นาที</p>
                                </div>
                            </div>
                            <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>2. รอใบสั่งยา</div>
                                    </div>
                                    <p>แพทย์จะเขียนใบสั่งยาให้ตามอาการเบื้องต้นของคุณ</p>
                                </div>
                            </div>
                            <div className={showMethod === 3 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>3. รอรับยา</div>
                                    </div>
                                    <p>ยืนยันรายการ ที่อยู่จัดส่ง เพื่อรอรับยาพร้อมติดตามสถานะแบบเรียลไทม์</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ServiceMethod>
            <ServiceRating>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ได้คุยกับคุณหมอ ทำให้คลายความกังวลเกี่ยวกับอาการที่เป็นอยู่ไปมากเลยค่ะ
                            แถมช่วงนี้งานยุ่งยังไม่มีเวลาเดินทางไปโรงพยาบาล นี่สามารถคุยผ่านวีดีโอคอลปรึกษากับคุณหมอ สะดวกมาก ๆ เลยค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlm' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>เจ</div>
                            <div className='rating-date'>15 Jan 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            งานก็ยุ่ง ไปรอที่โรงพยาบาลก็ต้องลางาน ใช้บริการของฟาร์มแคร์แค่จองเวลา พอถึงเวลานัดก็แค่ยกโทรศัพท์
                            สามารถปรึกษากับคุณหมอได้ทันที เป็นบริการที่ตอบโจทย์ในยุคนี้มากครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlm' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>เกณิกา</div>
                            <div className='rating-date'>10 Mar 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ชอบที่คุยกับหมอจบ ที่นี่มีบริการส่งยาจากร้านยาให้ถึงบ้าน รอแค่ไม่กี่ชั่วโมง ก็ได้รับยาแล้ว สะดวกมากครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlm' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ไมค์</div>
                            <div className='rating-date'>25 Jun 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ช่วงโควิด แถมงานก็ยุ่ง ไม่อยากลางานเพื่อไปนั่งรอที่โรงพยาบาล มาเจอบริการปรึกษาแพทย์ของที่นี่ ตอบโจทย์มากค่ะ
                            อยากชวนให้มาลองใช้กันค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlm' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>วิน</div>
                            <div className='rating-date'>18 Aug 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            วีดีโอคอลปรึกษาแพทย์ของฟาร์มแคร์ มีแพทย์เฉพาะทางให้เราสามารถเลือกได้ แถมสามารถจองเวลาที่เราสะดวกได้
                            คุณหมอก็น่ารัก เป็นกันเองมาก รู้สึกเหมือนไปพบที่โรงพยาบาลเลยค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlm' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>นนท์</div>
                            <div className='rating-date'>23 Dec 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ไม่ต้องกังวลกับอาการที่ผิดปกตินาน ๆ เพียงแค่จองเวลาพบแพทย์ รอเวลานัด ก็ได้คุยกับคุณหมอตัวจริง
                            แถมคุณหมอจ่ายยาให้ร้านยาส่งถึงบ้านให้ได้ด้วย สะดวกมากเลยครับ คิดว่าคงกลับมาใช้บริการอีกแน่ ๆ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlm' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>สุธาวัลย์</div>
                            <div className='rating-date'>27 Dec 2020</div>
                        </div>
                    </div>
                </div>
            </ServiceRating>
            <ServiceQeustionAndAnswer>
                <div className='landing-panel'>
                    <h2 className='title'>คำถามที่พบบ่อย</h2>
                    <div className='landing-content'>
                        <div className='landing-image'>
                            <img src='/images/service-landing/question.webp' alt='คำถามที่พบบ่อย' title='คำถามที่พบบ่อย' />
                        </div>
                        <div className='question-n-answer'>
                            <QeustionBox question={'1. มีบริการในจังหวัดใดบ้าง'}> บริการเราสามารถใช้ได้ทุกจังหวัดทั่วประเทศไทย</QeustionBox>
                            <QeustionBox question={'2. บริการ “ปรึกษาแพทย์ตอนนี้” กับบริการ “จองเวลาปรึกษาแพทย์เฉพาะทาง” ต่างกันอย่างไร'}>
                                <div>
                                    แพทย์ทั่วไปทันทีโดยไม่ต้องทำการนัดหมาย คุณไม่สามารถเลือกแพทย์ที่คุณต้องการปรึกษาได้
                                    เหมาะสำหรับท่านที่ป่วยทั่วไป ไม่จำเป็นต้องปรึกษาแพทย์เฉพาะทาง
                                </div>
                                <div>
                                    บริการจองเวลาปรึกษาแพทย์เฉพาะทาง เป็นบริการจองเวลาปรึกษากับแพทย์เฉพาะทาง ท่านสามารถเลือกแผนก
                                    และแพทย์เฉพาะทางที่ท่านต้องการปรึกษาได้เลย เช่น จิตแพทย์ สุขภาพผู้หยิง ผิวหนัง
                                    บริการนี้เหมาะสำหรับท่านที่มี่ปัญหาหรือโรคเฉพาะทางที่ต้องการปรึกษาแพทย์เฉพาะทาง
                                </div>
                            </QeustionBox>
                            <QeustionBox question={'3. มีบริการแพทย์เฉพาะทาง ให้เลือกปรึกษาไหมคะ'}>
                                ท่านสามารถเลือกแพทย์เฉพาะทาง และจองเวลาปรึกษาในช่วงเวลานั้น ๆ ได้เลยค่ะ
                            </QeustionBox>
                            <QeustionBox question={'4. หากจองเวลาปรึกษา แล้วไม่ได้ไปใช้บริการ เนื่องจากลืม หรือติดธุระ ควรทำอย่างไร'}>
                                ปกติเมื่อใกล้ถึงเวลารับบริการ ท่านจะได้รับการแจ้งเตือนผ่านระบบ แต่หากไม่สะดวก ท่านสามารถเลือนเวลานัดได้ 24
                                ชม ก่อนเวลาจองค่ะ
                            </QeustionBox>
                            <QeustionBox question={'5. หากจำเป็นต้องใช้ยา ถ้าอยากได้ยาเลย มีบริการจ่ายยาแล้วส่งถึงบ้านไหม'}>
                                <p>
                                    หากเป็นยาที่สามารถจำหน่ายได้ในร้านยา มีบริการจ่ายยาโดยเภสัชกรร้านยา
                                    สำหรับบริการจัดส่งยาในเขตกทมและปริมณฑล คนไข้จะได้รับยาภายใน 3 ชม
                                </p>
                                <div>
                                    ยกเว้นรหัสไปรษณีย์ (10280, 10550, 10560, 10570, 11150, 12110, 12170, 74120, 74130) ใช้เวลาจัดส่ง 1-3 วัน
                                </div>
                                <div>สำหรับบริการจัดส่งยาในต่างจังหวัด คนไข้จะได้รับยาภายใน 1-3 วัน</div>
                            </QeustionBox>
                            <QeustionBox question={'6. ชำระเงินผ่านช่องทางใดได้บ้าง'}>
                                สามารถชำระด้วยบัตรเครดิต/เดบิท หรือ โอนเงินผ่านธนาคารโดยใช้ Promptpay
                            </QeustionBox>
                        </div>
                    </div>
                </div>
            </ServiceQeustionAndAnswer>
            <ServiceRelate serviceList={['telepharmacy', 'risk']} />
            <LandingLogin params={params} />
        </>
    );
}

export default TLMNCDServiceLanding;
