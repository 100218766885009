import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { extendesClassname } from 'helpers';

/**
 * @function SearchPlace
 * @param {{
 * className: string,
 * onSelectPlace: (geocoder:geocode)=> void
 * } &  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} props
 * @returns
 */
export default function SearchPlace({ className, onChange, onSelectPlace, ...props }) {
    const refs = useRef(null);
    const pacContainer = useRef(null);

    const [autoComplete, setAutoComplete] = useState(null);
    const [geocoder, setGeocoder] = useState(null);

    const onSelectPlaceId = (placeId, description) => {
        geocoder
            .geocode({ placeId: placeId })
            .then(({ results }) => {
                if (results[0]) {
                    refs.current.value = description;
                    refs.current.blur();
                    pacContainer.current.style.display = 'none';
                    onSelectPlace(results[0]);
                } else {
                    window.alert('No results found');
                }
            })
            .catch((e) => window.alert('Geocoder failed due to: ' + e));
    };

    const createPacItem = (placeId, description, structuredFormatting) => {
        const item = document.createElement('div');
        const itemQuery = document.createElement('span');
        const span = document.createElement('span');
        const icon = document.createElement('span');

        // pac-item-query

        item.setAttribute('class', 'pac-item');
        item.onmousedown = () => onSelectPlaceId(placeId, description);

        icon.setAttribute('class', 'pac-icon');

        itemQuery.setAttribute('class', 'pac-item-query');
        if (structuredFormatting.main_text_matched_substrings) {
            const substrings = structuredFormatting.main_text_matched_substrings[0];
            const textReplace = structuredFormatting.main_text.substring(substrings.offset, substrings.offset + substrings.length);

            itemQuery.innerHTML = structuredFormatting.main_text.replace(
                textReplace,
                '<span class="pac-matched">' + textReplace + '</span>'
            );
        } else {
            itemQuery.innerHTML = structuredFormatting.main_text;
        }

        if (structuredFormatting.secondary_text_matched_substrings) {
            const substrings = structuredFormatting.main_text_matched_substrings[0];
            const textReplace = structuredFormatting.secondary_text.substring(substrings.offset, substrings.offset + substrings.length);

            span.innerHTML = structuredFormatting.secondary_text.replace(
                textReplace,
                '<span class="pac-matched">' + textReplace + '</span>'
            );
        } else {
            span.innerHTML = structuredFormatting.secondary_text;
        }

        item.appendChild(icon);
        item.appendChild(itemQuery);
        item.appendChild(span);

        return item;
    };

    const displaySuggestions = (predictions, status) => {
        pacContainer.current.innerHTML = '';

        if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            console.warn('predictions status warning: ', status);
            return;
        }

        predictions.forEach((prediction) => {
            const item = createPacItem(prediction.place_id, prediction.description, prediction.structured_formatting);
            pacContainer.current.appendChild(item);
        });
    };

    const onPlaceChanged = (event) => {
        if (event.target.value) {
            autoComplete.getPlacePredictions({ input: event.target.value, ...SearchPlace.options }, displaySuggestions);
        } else {
            pacContainer.current.innerHTML = '';
        }

        onChange(event);
    };

    const onSelect = () => {
        if (refs.current?.value) refs.current.select();
        pacContainer.current.style.display = 'block';
    };

    const onBlur = (event) => {
        if (pacContainer.current && refs.current && event.target !== refs.current) {
            pacContainer.current.style.display = 'none';
        }
    };

    useEffect(() => {
        const init = async () => {
            if (window.google?.maps && (!autoComplete || !geocoder)) {
                const { AutocompleteService } = await window.google.maps.importLibrary('places');
                const { Geocoder } = await google.maps.importLibrary('geocoding');

                setAutoComplete(new AutocompleteService());
                setGeocoder(new Geocoder());
            }
        };

        init();
    }, [window.google, window.google?.maps]);

    useEffect(() => {
        document.body.addEventListener('click', onBlur);

        return () => document.body.removeEventListener('click', onBlur);
    }, []);

    if (!window.google?.maps) return null;

    return (
        <Style {...extendesClassname([className])}>
            <input ref={refs} onFocus={onSelect} onChange={onPlaceChanged} {...props} />
            <div ref={pacContainer} className='pac-container'></div>
        </Style>
    );
}

SearchPlace.options = {
    strictBounds: false,
    componentRestrictions: { country: ['th'] },
    fields: ['formatted_address', 'geometry', 'place_id', 'address_components', 'name'],
    types: ['establishment', 'geocode'],
};
SearchPlace.defaultProps = {
    onChange: () => null,
    onSelectPlace: () => null,
};
SearchPlace.propTypes = {
    onSelectPlace: PropTypes.func,
};

const Style = styled.div`
    position: relative;

    input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        border: 1px solid var(--text-normal-4);
        border-radius: 0.25rem;
        &:focus {
            border-color: var(--input-border-focus);
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    .pac-container {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-radius: 2px;
        border-top: 1px solid #d9d9d9;
        box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
        background-color: #fff;
        overflow: hidden;
        z-index: 1000;
    }

    .pac-item {
        cursor: default;
        padding: 0 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 30px;
        text-align: left;
        border-top: 1px solid #e6e6e6;
        font-size: 0.825rem;
        color: #999;
    }

    .pac-icon {
        width: 15px;
        height: 20px;
        margin-right: 7px;
        margin-top: 6px;
        display: inline-block;
        vertical-align: top;
        background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
        background-size: 34px;
        background-position: -1px -161px;
    }

    .pac-item-query {
        font-size: 1rem;
        padding-right: 3px;
        color: var(--text-normal-3);
    }

    .pac-matched {
        color: var(--text-normal-1);
        font-weight: 500;
    }
`;
