import { breakpoint } from 'helpers';
import styled from '@emotion/styled';

interface NavbarWrapperProps {
    $hasInProgress?: boolean; // optional prop
}

export const NavbarWrapper = styled('div')<NavbarWrapperProps>`
    label: nav;

    position: relative;
    display: flex;
    height: var(--navbar-height);
    align-items: center;
    padding: 15px 5%;
    background-color: var(--navbar-background-color);
    z-index: var(--navbar-zindex);

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: ${(props) => (props.$hasInProgress ? 'block' : 'none')};
        background-color: transparent;
        /* background-color: #f00; */
    }

    ${breakpoint('XL')} {
        padding: 10px 5%;
    }
    ${breakpoint('LG')} {
        justify-content: flex-end;
    }
`;
