import React from 'react';
import styled from '@emotion/styled';

function SideBarOverlay({ show, onClose = () => null }) {
    if (!show) return null;
    return <Style onClick={onClose} />;
}

export default SideBarOverlay;

const Style = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: var(--sidebar-overlay-zindex);
    background-color: var(--text-normal-3);
    transition: all 0.3s;
`;
