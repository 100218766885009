import Button from 'components/common/button';
import { Field, FormField, FormGroup } from 'components/common/exp-form';
import EXPLiftUp from 'components/common/exp-lift-up';
import { NEW_CONSENT_WITH_THEME_LIST } from 'constants/default-config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrivacyPolicyPharmcare } from '../privacy-policy-with-theme';
import styled from '@emotion/styled';
import { EXPCollapse, EXPCollapseComplete } from 'components/common/exp-collapse';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { TermsAndConditionsPharmcare } from '../terms-and-conditions-with-theme';
import { TextTitle } from 'components/common/styled-components-common';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';

const LiffupFooter = styled.div`
    text-align: center;
    padding: 1.5rem;

    button {
        width: 100%;
        max-width: 400px;
    }
`;

/**
 *
 * @param {{
 * onSubmit:(never:{c1: 'Y'|'N'|'', c2: 'Y'|'N'|'', c3: 'Y'|'N'|'', c4: 'Y'|'N'|'' })=> void
 * }} props
 * @returns
 */
function PharmcareConsent({ onSubmit = () => null }) {
    const { t } = useTranslation();
    const theme = useThemeWithPartner();
    //
    const [consentInputSubmit, setConsentInputSubmit] = React.useState('');
    const [consentInput, setConsentInput] = React.useState({ c1: '', c2: 'Y', c3: 'Y', c4: '' });
    const [consentInputC4, setConsentInputC4] = React.useState({ c4_1: 'Y', c4_2: 'Y' });
    const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = React.useState(false);
    const [consentStep, setConsentStep] = React.useState(1);
    const [showCollapseConsent2, setShowCollapseConsent2] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    //
    const onChangeConsent = (event) => {
        setConsentInput({ ...consentInput, [event.target.name]: event.target.value });
    };

    const onChangeConsentC4 = (event) => {
        let _consentInputC4 = { ...consentInputC4 };
        if (event.target.checked) {
            _consentInputC4[event.target.name] = event.target.value;
        } else {
            _consentInputC4[event.target.name] = '';
        }

        if (Object.values(_consentInputC4).some((s) => s === 'Y')) {
            setConsentInput({ ...consentInput, c4: 'Y' });
        } else {
            setConsentInput({ ...consentInput, c4: 'N' });
        }

        setConsentInputC4({ ...consentInputC4, ..._consentInputC4 });
    };
    const onSubmitConsent = () => {
        setLoading(true);
        onSubmit(consentInput);
        setTimeout(() => {
            setLoading(false);
        }, 120);
    };
    const onSubmitConsent2 = (event) => {
        if (NEW_CONSENT_WITH_THEME_LIST.some((value) => value === theme.name) && consentInput.c4 === '') {
            const _consentInputSubmit = { ...consentInput, c4: 'Y' };
            setConsentInput({ ..._consentInputSubmit });
        }
        setConsentInputSubmit(event.target.value);
    };
    const onNextStepConsent = () => {
        setConsentStep(0);

        setTimeout(() => {
            setConsentStep(2);
        }, 100);
    };

    const onBackStepConsent = () => {
        setConsentStep(0);

        setTimeout(() => {
            setConsentStep(1);
        }, 100);
    };

    const onShowPrivacyPolicyPopup = (event) => {
        event.preventDefault();

        setShowPrivacyPolicyPopup(true);
    };

    if (NEW_CONSENT_WITH_THEME_LIST.some((value) => value === theme.name)) {
        return (
            <>
                {consentStep === 1 && (
                    <div id='pharmcare-consent-slide-1'>
                        <TermsAndConditionsPharmcare />
                        <div className='consent-panel-sticky'>
                            <FormField onSubmit={onNextStepConsent}>
                                <div className='consent-panel'>
                                    <div id='consent-section1' className='consent-section __border-bottom'>
                                        <EXPCollapseComplete
                                            defaultShow
                                            title={
                                                <div className='consent-important consent-underline'>
                                                    {t('consent-pharmcare:section1.title')}
                                                </div>
                                            }
                                        >
                                            <p dangerouslySetInnerHTML={{ __html: t('consent-pharmcare:section1.detail1') }} />
                                            <p dangerouslySetInnerHTML={{ __html: t('consent-pharmcare:section1.detail2') }} />
                                        </EXPCollapseComplete>
                                        <div className='consent-input'>
                                            <Field.RadioGroup
                                                type='radio-group'
                                                name='c1'
                                                value={consentInput.c1}
                                                radioListGroup={[
                                                    { label: t('common:accept'), value: 'Y', xs: 6 },
                                                    { label: t('common:not_accept'), value: 'N', xs: 6 },
                                                ]}
                                                classNameField='input-radio-type4 input-radio-button text-center'
                                                onChange={onChangeConsent}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div data-test-id='consentFooter' className='consent-footer'>
                                    <Button data-test-id='confirm' variant='primary' disabled={consentInput.c1 !== 'Y'}>
                                        {t('common:confirm')}
                                    </Button>
                                </div>
                            </FormField>
                        </div>
                    </div>
                )}
                {consentStep === 2 && (
                    <div id='pharmcare-consent-slide-2'>
                        <FormField onSubmit={onSubmitConsent}>
                            <div className='consent-panel'>
                                <div id='consent-section2'>
                                    <TextTitle className='consent-title' onClick={() => setShowCollapseConsent2(!showCollapseConsent2)}>
                                        <div className='consent-important consent-underline'>{t('consent-pharmcare:section2.title')}</div>
                                        <IconConfigWithTheme
                                            pageName='collapse_complete'
                                            iconName='show_more'
                                            className={`${showCollapseConsent2 ? 'fa-rotate-0' : 'fa-rotate-180'}`}
                                        />
                                    </TextTitle>
                                    <p>{t('consent-pharmcare:section2.detail1')}</p>
                                    <EXPCollapse show={showCollapseConsent2}>
                                        <div className='consent-table'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan='2' className='bg_gray'>
                                                            <div>
                                                                {t('consent-pharmcare:section2.title1')} &nbsp;
                                                                <span className='fw-400'>
                                                                    {t('consent-pharmcare:section2.sub_title1')}{' '}
                                                                </span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='bg_transparent'>
                                                        <td className='fw-500'>{t('consent-pharmcare:section2.purposes')}</td>
                                                        <td className='_accept'>{t('consent-pharmcare:section2.consent')}</td>
                                                    </tr>
                                                    <tr className='bg_white'>
                                                        <td>{t('consent-pharmcare:section2.detail1_1')}</td>
                                                        <td className='_accept'>
                                                            <FormGroup className='input-checkbox-type1'>
                                                                <Field.Checkbox
                                                                    name='c2'
                                                                    value='Y'
                                                                    checked={consentInput.c2 === 'Y'}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                    <tr className='bg_white'>
                                                        <td colSpan='2' className='text-red-1'>
                                                            {t('consent-pharmcare:section2.note1')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan='2' className='bg_gray'>
                                                            <div>{t('consent-pharmcare:section2.title2')}</div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='bg_transparent'>
                                                        <td className='fw-500'>{t('consent-pharmcare:section2.purposes')}</td>
                                                        <td className='_accept'>{t('consent-pharmcare:section2.consent')}</td>
                                                    </tr>
                                                    <tr className='bg_white'>
                                                        <td>{t('consent-pharmcare:section2.detail2_1')}</td>
                                                        <td className='_accept'>
                                                            <FormGroup className='input-checkbox-type1'>
                                                                <Field.Checkbox
                                                                    name='c3'
                                                                    value='Y'
                                                                    checked={consentInput.c3 === 'Y'}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                    <tr className='bg_white'>
                                                        <td colSpan='2' className='text-red-1'>
                                                            {t('consent-pharmcare:section2.note2')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan='2' className='bg_gray'>
                                                            <div>{t('consent-pharmcare:section2.title3')}</div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='bg_transparent'>
                                                        <td className='fw-500'>{t('consent-pharmcare:section2.purposes')}</td>
                                                        <td className='_accept'>{t('consent-pharmcare:section2.consent')}</td>
                                                    </tr>
                                                    <tr className='bg_white'>
                                                        <td>{t('consent-pharmcare:section2.detail3_1')}</td>
                                                        <td className='_accept'>
                                                            <FormGroup className='input-checkbox-type1'>
                                                                <Field.Checkbox
                                                                    name='c4_1'
                                                                    value='Y'
                                                                    checked={consentInputC4.c4_1 === 'Y'}
                                                                    onChange={onChangeConsentC4}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                    <tr className='bg_white'>
                                                        <td>{t('consent-pharmcare:section2.detail3_2')}</td>
                                                        <td className='_accept'>
                                                            <FormGroup className='input-checkbox-type1'>
                                                                <Field.Checkbox
                                                                    name='c4_2'
                                                                    value='Y'
                                                                    checked={consentInputC4.c4_2 === 'Y'}
                                                                    onChange={onChangeConsentC4}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                    <tr className='bg_white'>
                                                        <td colSpan='2' className='text-red-1'>
                                                            {t('consent-pharmcare:section2.note3')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div> {t('consent-pharmcare:section2.note4')}</div>
                                        </div>
                                    </EXPCollapse>
                                    <div className='consent-input'>
                                        <Field.RadioGroup
                                            type='radio-group'
                                            name='consentInputSubmit'
                                            value={consentInputSubmit}
                                            radioListGroup={[
                                                { label: t('common:i_consent'), value: 'Y', xs: 6 },
                                                { label: t('common:i_not_consent'), value: 'N', xs: 6 },
                                            ]}
                                            classNameField='input-radio-type4 input-radio-button text-center '
                                            onChange={onSubmitConsent2}
                                        />
                                    </div>
                                    <p>
                                        <a href='/privacy-policy' onClick={onShowPrivacyPolicyPopup}>
                                            {t('consent-pharmcare:detail_for_privacy')}
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div data-test-id='consentFooter' className='consent-footer __double-button'>
                                <Button type='button' variant='second' onClick={onBackStepConsent}>
                                    {t('common:back')}
                                </Button>
                                <Button data-test-id='confirm' loading={loading} variant='primary' disabled={consentInputSubmit !== 'Y'}>
                                    {t('common:confirm')}
                                </Button>
                            </div>
                        </FormField>
                    </div>
                )}
                <EXPLiftUp.slideUp
                    show={showPrivacyPolicyPopup}
                    zIndex={'var(--liftup-consent-zindex)'}
                    onClose={() => setShowPrivacyPolicyPopup(false)}
                >
                    <div className='pt-3'>
                        <PrivacyPolicyPharmcare />
                        <LiffupFooter>
                            <Button variant='primary' onClick={() => setShowPrivacyPolicyPopup(false)}>
                                {t('common:back')}
                            </Button>
                        </LiffupFooter>
                    </div>
                </EXPLiftUp.slideUp>
            </>
        );
    }

    return (
        <>
            <TermsAndConditionsPharmcare />
            <FormField onSubmit={onSubmitConsent}>
                <div className='consent-panel'>
                    <div id='consent-section1' className='consent-section __border-bottom'>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: t('consent-default:section1.detail1') }} />
                            <p className='consent-remark' dangerouslySetInnerHTML={{ __html: t('consent-default:section1.detail2') }} />
                            <p dangerouslySetInnerHTML={{ __html: t('consent-default:section1.detail3') }} />
                            <p>
                                {t('consent-default:section1.detail4_1')}{' '}
                                <a href='#terms-conditions-pharmcare'>{t('consent-default:section1.detail4_2')}</a>{' '}
                                {t('consent-default:section1.detail4_3')}{' '}
                                <a href='/privacy-policy' onClick={onShowPrivacyPolicyPopup}>
                                    {t('consent-default:section1.detail4_4')}
                                </a>{' '}
                                {t('consent-default:section1.detail4_5')}
                            </p>
                            {/* <p dangerouslySetInnerHTML={{ __html: t('consent-default:section1.detail4') }} /> */}
                        </div>
                        <div className='consent-input'>
                            <Field.RadioGroup
                                type='radio-group'
                                name='c1'
                                value={consentInput.c1}
                                radioListGroup={[
                                    { label: t('common:accept'), value: 'Y', xs: 6 },
                                    { label: t('common:not_accept'), value: 'N', xs: 6 },
                                ]}
                                classNameField='input-radio-type4 input-radio-button text-center'
                                onChange={onChangeConsent}
                            />
                        </div>
                    </div>
                </div>
                <div className='consent-footer' data-test-id='consentFooter'>
                    <Button data-test-id='confirm' variant='primary' loading={loading} disabled={consentInput.c1 !== 'Y'}>
                        {t('common:confirm')}
                    </Button>
                </div>
            </FormField>
            <EXPLiftUp.slideUp
                show={showPrivacyPolicyPopup}
                zIndex={'var(--liftup-consent-zindex)'}
                onClose={() => setShowPrivacyPolicyPopup(false)}
            >
                <div className='pt-3'>
                    <PrivacyPolicyPharmcare />
                    <LiffupFooter>
                        <Button variant='primary' onClick={() => setShowPrivacyPolicyPopup(false)}>
                            {t('common:back')}
                        </Button>
                    </LiffupFooter>
                </div>
            </EXPLiftUp.slideUp>
        </>
    );
}

export default PharmcareConsent;
