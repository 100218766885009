import React, { useEffect } from 'react';
import { PageLoadingStyle } from './PageLoading.styled';

export type PageLoadingProps = {
    active: boolean;
    type: 'spinner' | 'rocket';
    spinnerColor?: string;
};
function PageLoading({ active = false, type = 'spinner', ...props }: Readonly<PageLoadingProps>) {
    useEffect(() => {
        if (active) {
            document.body.classList.add('page-loding');
        } else {
            document.body.classList.remove('page-loding');
        }
    }, [active]);

    if (type === 'spinner') {
        return (
            <PageLoadingStyle {...props} className={active ? '__active' : '__inactive'} data-test-active={`${active}`}>
                <div className='spinner'>
                    <div className='rect1'></div>
                    <div className='rect2'></div>
                    <div className='rect3'></div>
                    <div className='rect4'></div>
                    <div className='rect5'></div>
                </div>
            </PageLoadingStyle>
        );
    }

    if (type === 'rocket') {
        return (
            <PageLoadingStyle {...props} className={active ? '__active' : '__inactive'}>
                <div className='rocket'>
                    <img src='/images/common/rocket-green.gif' alt='rocket-green' />
                </div>
            </PageLoadingStyle>
        );
    }

    return null;
}

export default PageLoading;
