import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { breakpoint } from 'helpers';

export default function ArticleBox({ data, path, ...rest }) {
    return (
        <Wrapper data-test-id='articleCard' {...rest}>
            <Img>
                <img src={data.articleImageUrl} alt={data.seo?.urlParameter} title={data.seo?.urlParameter} />
            </Img>
            <Content>
                <Title href={path}>{data.title}</Title>
                <Detail>{data.title}</Detail>
            </Content>
            <Date>{moment(data.createdAt).format('DD MMM YYYY')}</Date>
            <ContentTag>
                <TagList>
                    {data?.tag?.map((e, i) => (
                        <Tag key={i}>#{e.name}</Tag>
                    ))}
                </TagList>
            </ContentTag>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 330px;
    border-radius: 10px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 1.2rem;
    cursor: pointer;
    padding-bottom: 12px;
`;

const Img = styled.div`
    /* width: 330px; */
    width: 100%;
    height: 210px;
    img {
        object-fit: cover;
    }
`;
const Content = styled.div`
    width: 100%;
    padding: 18px 18px 0 18px;
`;
const Title = styled.a`
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--text-normal-1) !important;
    margin-bottom: 0.5rem;
    text-decoration: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
const Detail = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0.5rem;
    font-size: 90%;
`;
const ContentTag = styled.div`
    width: 100%;
    max-height: 204px;
    overflow: hidden;

    ${breakpoint('XS')} {
        max-height: 65px;
    }
`;
const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: auto;
`;
const Tag = styled.div`
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 4px 16px;
    border-radius: 20px;
    background-color: #cfeae9;
    font-weight: 500;
    color: var(--green-3);
    font-size: 80%;
    height: fit-content;
`;
const Date = styled.div`
    margin-top: auto;
    margin-bottom: 0.5rem;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 90%;
`;
