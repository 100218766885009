import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/button';
import ServiceDetail from 'modules/page/landing/ServiceDetail';
import { cryptojs } from 'helpers';
import moment from 'moment';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useAuth from 'hooks/useAuth';
import type { DataEncodeURLProps } from 'types';
import ServiceSymstom from 'modules/page/landing/ServiceSymstom';
import ServiceMethod from 'modules/page/landing/ServiceMethod';
import ServicePharmacyNearby from 'modules/page/landing/ServicePharmacyNearby';
import ServiceRating from 'modules/page/landing/ServiceRating';
import ServiceQeustionAndAnswer, { QeustionBox } from 'modules/page/landing/ServiceQnA';
import ServiceRelate from 'modules/page/landing/ServiceRelate';
import LandingLogin from 'modules/page/landing/LandingLogin';

function NTServiceLanding() {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const timer = useRef<NodeJS.Timeout>();
    const [showMethod, setShowMethod] = useState(1);

    const onNextPage = () => {
        let path = process.env.REACT_APP_LIFF_REDIRECT_DEFAULT;
        let dataForEncode: DataEncodeURLProps = {
            path: '/nt/claim',
            params: window.location.search || '?fromService=true',
            data: {
                authorization: null,
                userId: null,
                autoLogin: 0,
            },
        };
        if (window.location.origin.includes('fastcare.co')) {
            path = process.env.REACT_APP_LIFF_REDIRECT_FASTCARE;
            dataForEncode = {
                ...dataForEncode,
                params: window.location.search || '?voucher=fastcare01&fromService=true',
                data: {
                    authorization: null,
                    userId: null,
                    autoLogin: 0,
                    partner: '18',
                },
            };
        }
        const encode = cryptojs.encode(dataForEncode);
        if (auth.isAuth) {
            return navigate(dataForEncode.path + dataForEncode.params);
        }
        return window.location.assign(path + '?to=' + encode);
    };

    const onChangeMethod = (method) => {
        let _method = method;

        clearInterval(timer.current);
        setShowMethod(_method);

        timer.current = setInterval(() => {
            _method++;

            if (_method < 4) {
                setShowMethod(_method);
            } else {
                setShowMethod(1);
                _method = 1;
            }
        }, 5000);
    };

    const params = useMemo(() => {
        const origin = window.location.origin;
        return origin.includes('fastcare.co') ? '?voucher=fastcare01' : '';
    }, [window.location.origin]);

    useEffect(() => {
        onChangeMethod(1);

        return () => clearInterval(timer.current);
    }, []);

    return (
        <>
            <ServiceDetail primaryBgc='#3ba274' secondBgc='#f9f9fa'>
                <div className='landing-service'>
                    <div className='service-panel service-content pb-0'>
                        <div className='title'>บริการ</div>
                        <h1 className='sub-title'>ปรึกษานักกำหนดอาหาร</h1>
                        <h4 className='detail'>
                            <div>
                                <div>ให้คำแนะนำและประเมินพฤติกรรม</div>
                                <div>การบริโภคโดยนักกำหนดอาหาร</div>
                            </div>
                        </h4>
                        <div className='service-image mb-0'>
                            <img src='/images/service-landing/service-nt.webp' className='desktop' alt='desktop' />
                            <img src='/images/service-landing/service-nt.webp' className='mobile' alt='mobile' />
                        </div>
                    </div>
                    <div className='service-panel service-detail'>
                        <h3 className='title'>รายละเอียดบริการ</h3>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_nt' iconName='sevice_detail_time' />
                            </div>
                            <h4>ให้บริการ 10.00-20.00 น. ทุกวัน</h4>
                        </div>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_nt' iconName='sevice_detail' />
                            </div>
                            <h4>ประเมินภาวะโภชนาการ วิเคราะห์พฤติกรรมการบริโภคอาหาร</h4>
                        </div>

                        <h3 className='title'>อัตราค่าบริการ</h3>
                        <div className='sevice-rate-panel'>
                            <div className='sevice-rate'>
                                <div className='list special-row'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_nt' iconName='sevice_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่าปรึกษา</h3>
                                        <h4 className='list-detail'>
                                            <span>230 บาท (15 นาที)</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='panel-footer'>
                                <Button style={{ backgroundColor: '#3ba274', color: 'var(--white)' }} onClick={() => onNextPage()}>
                                    ปรึกษานักกำหนดอาหาร
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='consult-now'>
                    <div className='custom-btn'>
                        <Button style={{ backgroundColor: '#3ba274', color: 'var(--white)' }} onClick={() => onNextPage()}>
                            ปรึกษานักกำหนดอาหาร
                        </Button>
                    </div>
                </div>
            </ServiceDetail>
            <ServiceSymstom primaryBgc='#e5f0eb' secondBgc='#f9f9fa' imageBgc='green'>
                <div className='landing-panel'>
                    <div className='landing-icon'>
                        <img src='/images/pharmcare-short-logo.svg' alt='pharmcare-icon' />
                    </div>
                    <h2>
                        <div className='title'>หัวข้อสุขภาพ</div>
                        <div className='sub-title'>ที่นักกำหนดอาหารให้คำแนะนำ</div>
                    </h2>
                    <div className='landing-content'>
                        <div className='landing-image'>
                            <img src='/images/service-landing/symptoms-nt.webp' alt='symptoms' />
                        </div>
                        <div className='symptoms'>
                            <div className='symptoms-about'>หัวข้อที่ให้คำแนะนำ...</div>
                            <ul className='symptoms-list mark-responsive'>
                                <li>
                                    <h4>ลดน้ำหนัก</h4>
                                </li>
                                <li>
                                    <h4>การทำ IF</h4>
                                </li>
                                <li>
                                    <h4>ปรึกษาเรื่อง อาหารเสริม</h4>
                                </li>
                                <li>
                                    <h4>อาหารสำหรับผู้ป่วยเบาหวาน โรคเรื้อรังต่าง ๆ</h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ServiceSymstom>
            <ServiceMethod secondBgc='#f9f9fa'>
                <div className='landing-panel'>
                    <h3 className='title'>ขั้นตอนในการรับบริการ</h3>
                    <div className='method'>
                        <div className='method-row mark-1'>
                            <div className='method-row mark-2'>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 1 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(1)}
                                    >
                                        <div className='circle'>1</div>
                                        <h3>ปรึกษานักกำหนดอาหาร</h3>
                                    </div>
                                </div>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 2 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(2)}
                                    >
                                        <div className='circle'>2</div>
                                        <h3>รับคำแนะนำ</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='method-row mark-3 line-center'>
                                <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-nt-1.webp' alt='method-nt-1' />
                                    </div>
                                </div>
                                <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-pt-2.webp' alt='method-pt-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='method-row'>
                            <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>1. ปรึกษาเภสัชกร</div>
                                    </div>
                                    <p>กรอกข้อมูลสุขภาพ อาการของคุณ และเริ่มต้นสนทนากับนักกำหนดอาหาร ภายใน 5 นาที</p>
                                </div>
                            </div>
                            <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>2. รับคำแนะนำ</div>
                                    </div>
                                    <p>รับคำแนะนำจากนักกำหนดอาหาร</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ServiceMethod>
            <ServiceRating>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            เพิ่งทราบว่าอาหารที่ทานเป็นประจำ (ชอบทานเบียร์กับหมูกระทะ) ทำให้โรคเกาต์ที่เป็นอยู่แย่ลง
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_nt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>วิบูลย์ </div>
                            <div className='rating-date'>20 Jan 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            คุมอาหารมานาน น้ำหนักไม่ลงสักที ปรึกษาพี่นักกำหนดอาหารที่นี่ แล้วลองไปทำตาม ผ่านไป 1 เดือน ผลตอนนี้ พอใจมาก
                            ที่สำคัญไม่รู้สึกหมดแรงด้วยค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_nt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>จรัสพงษ์</div>
                            <div className='rating-date'>10 Feb 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            เป็นความดันสูง แต่อยากทานอาหารเสริมบำรุงร่างกาย เลยมาปรึกษาที่นี่ ได้ความรู้และคำแนะนำดี ๆมากเลยครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_nt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>วิน</div>
                            <div className='rating-date'>21 Feb 202</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ไปหาหมอทุก 3 เดือน เพราะเป็นเบาหวาน ถูกหมอบ่นจนท้อใจ เพราะคุมน้ำตาลไม่ได้ เลยลองมาใช้บริการที่นี่ กลับไปล่าสุด
                            คุณหมอชมเลยค่ะ ว่ามาถูกทางแล้ว ดีใจมากค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_nt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>เมย์</div>
                            <div className='rating-date'>10 Mar 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            อยากทำ IF เพื่อลดน้ำหนัก เนื่องจากมีปัญหาเรื่องข้อเข่า มาปรึกษากับนักกำหนดอาหารที่นี่ ตอนนี้ลง ไป 3 กิโลแล้ว
                            เข่าก็ไม่ค่อยปวดแล้วด้วย ขอบคุณมากครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_nt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ไมค์</div>
                            <div className='rating-date'>26 May 2022</div>
                        </div>
                    </div>
                </div>
            </ServiceRating>
            <ServiceQeustionAndAnswer>
                <div className='landing-panel'>
                    <h2 className='title'>คำถามที่พบบ่อย</h2>
                    <div className='landing-content'>
                        <div className='landing-image'>
                            <img src='/images/service-landing/question.webp' alt='question' />
                        </div>
                        <div className='question-n-answer'>
                            <QeustionBox question={'1. มีบริการในจังหวัดใดบ้าง'}>
                                เปิดให้บริการทุกจังหวัด เป็นบริการให้คำปรึกษาโดยนักกำหนดอาหาร ผ่านระบบวีดีโอคอล
                            </QeustionBox>
                            <QeustionBox question={'2. หากมีการแนะนำเมนูอาหารที่ควรรับประทาน จะมีเอกสารแจงรายละเอียดให้หรือไม่'}>
                                บริการนี้เป็นการให้คำปรึกษาแบบแนะนำสารอาหาร หรือเมนูอาหารที่เหมาะสมแบบง่าย ๆ ทั่วไป
                                จึงอาจไม่มีเอกสารเป็นทางการให้ การให้คำปรึกษาจะผ่านระบบวีดีโอคอล
                                หากมีข้อสงสัยสามารถซักถามเหมือนพูดคุยแบบเห็นหน้าได้ทันที
                            </QeustionBox>
                            {/* <QeustionBox question={'3. หากต้องการกลับไปปรึกษากับนักกำหนดอาหารท่านเดิม สามารถทำได้ไหม'}></QeustionBox> */}
                            <QeustionBox question={'3. หากปรึกษาเกิน 15 นาที คิดค่าบริการอย่างไร'}>
                                ถ้าเวลาเกินนิดหน่อย ระบบจะยังคงให้บริการได้อยู่โดยไม่ได้คิดค่าใช้จ่ายเพิ่ม นักกำหนดอาหารจะเป็นผู้ดูแล
                                ควบคุมการให้คำปรึกษา คำแนะนำภายในเวลาที่กำหนด
                            </QeustionBox>
                        </div>
                    </div>
                </div>
            </ServiceQeustionAndAnswer>
            <ServiceRelate serviceList={['telepharmacy', 'telemed']} />
            <LandingLogin onNextPath={'/nt/claim'} params={params} />
        </>
    );
}

export default NTServiceLanding;
