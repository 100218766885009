import { cryptojs } from 'helpers';
import Button from 'components/common/button';
import { useNavigate } from 'react-router-dom';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useAuth from 'hooks/useAuth';
import { LoginStyle } from './Landing.styled';

export default function LandingLogin({ onNextPath, params }: Readonly<{ onNextPath?: string; params: string }>) {
    const navigate = useNavigate();
    const { auth } = useAuth();

    let dataForEncode: any = {
        path: onNextPath || window.location.pathname,
        params: window.location.search || params,
    };
    if (window.location.origin.includes('fastcare.co')) {
        dataForEncode = {
            ...dataForEncode,
            data: {
                authorization: null,
                userId: null,
                autoLogin: 0,
                partner: '18',
            },
        };
    }
    if (auth.isAuth) return null;

    return (
        <LoginStyle className='landing-login'>
            <div className='landing-panel'>
                <div className='landing-image'>
                    <img src='/images/service-landing/login-background.webp' alt='เข้าสู่ระบบ' title='เข้าสู่ระบบ' />
                </div>
                <div className='landing-content'>
                    <div className='title'>เราพร้อมมอบบริการที่ดี</div>
                    <div className='landing-row'>
                        <div className='landing-col detail'>ส่งมอบบริการสุขภาพที่ปลอดภัย เข้าถึงได้ เพื่อให้คุณได้รับบริการที่ดีที่สุด</div>
                        <div className='landing-col login-button'>
                            <div className='image'>
                                <img src='/images/service-landing/login-background.webp' alt='เข้าสู่ระบบ' title='เข้าสู่ระบบ' />
                            </div>
                            <Button onClick={() => navigate(`/line/redirect?to=${cryptojs.encode(dataForEncode)}`)}>
                                <IconConfigWithTheme pageName='landing_login' iconName='line' />

                                <span>เข้าสู่ระบบ/สร้างบัญชีผ่าน LINE</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </LoginStyle>
    );
}
