import Button from 'components/common/button';
import { Field, FormField } from 'components/common/exp-form';
import EXPLiftUp from 'components/common/exp-lift-up';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import PrivacyPolicyCentral20 from '../privacy-policy-with-theme/privacy-policy-central20';

const LiffupFooter = styled.div`
    text-align: center;
    padding: 1.5rem;

    button {
        width: 100%;
        max-width: 400px;
    }
`;

/**
 *
 * @param {{
 * onSubmit:(never:{c1: 'Y'|'N'|'', c2: 'Y'|'N'|'', c3: 'Y'|'N'|'', c4: 'Y'|'N'|'' })=> void
 * }} props
 * @returns
 */
function Central20Consent({ onSubmit = () => null }) {
    const { t } = useTranslation();
    //
    const [consentInput, setConsentInput] = React.useState({ c1: '', c2: '', c3: 'Y', c4: 'Y' });
    const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    //
    const onChangeConsent = (event) => {
        setConsentInput({ ...consentInput, [event.target.name]: event.target.value });
    };

    const onSubmitConsent = () => {
        setLoading(true);
        onSubmit(consentInput);
        setTimeout(() => {
            setLoading(false);
        }, 120);
    };

    const onShowPrivacyPolicyPopup = (event) => {
        event.preventDefault();

        setShowPrivacyPolicyPopup(true);
    };

    return (
        <>
            <FormField onSubmit={onSubmitConsent}>
                <div className='consent-panel'>
                    <div id='consent-section1' className='consent-section'>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: t('consent-central20:section1.detail1') }} />
                            <p className='consent-remark' dangerouslySetInnerHTML={{ __html: t('consent-central20:section1.detail2') }} />
                            <p dangerouslySetInnerHTML={{ __html: t('consent-central20:section1.detail3') }} />
                            <p>
                                {t('consent-central20:section1.detail4_1')}{' '}
                                <a href='#terms-conditions-pharmcare'>{t('consent-central20:section1.detail4_2')}</a>{' '}
                                {t('consent-central20:section1.detail4_3')}{' '}
                                <a href='/privacy-policy' onClick={onShowPrivacyPolicyPopup}>
                                    {t('consent-central20:section1.detail4_4')}
                                </a>{' '}
                                {t('consent-central20:section1.detail4_5')}
                            </p>
                        </div>
                        <div className='consent-input'>
                            <Field.RadioGroup
                                type='radio-group'
                                name='c1'
                                value={consentInput.c1}
                                radioListGroup={[
                                    { label: t('common:accept'), value: 'Y', xs: 6 },
                                    { label: t('common:not_accept'), value: 'N', xs: 6 },
                                ]}
                                classNameField='input-radio-type4 input-radio-button text-center'
                                onChange={onChangeConsent}
                            />
                        </div>
                    </div>
                    <div id='consent-section2' className='consent-section __border-bottom'>
                        <div>
                            <p className='fw-600'>{t('consent-central20:section2.title')}</p>
                            <p dangerouslySetInnerHTML={{ __html: t('consent-central20:section2.detail1') }} />
                            <p className='consent-remark' dangerouslySetInnerHTML={{ __html: t('consent-central20:section2.detail2') }} />
                            <p>
                                <a href={t('consent-central20:section2.detail3')}>{t('consent-central20:section2.detail3')}</a>
                            </p>
                        </div>
                        <div className='consent-input'>
                            <Field.RadioGroup
                                type='radio-group'
                                name='c2'
                                value={consentInput.c2}
                                radioListGroup={[
                                    { label: t('common:i_consent'), value: 'Y', xs: 6 },
                                    { label: t('common:i_not_consent'), value: 'N', xs: 6 },
                                ]}
                                classNameField='input-radio-type4 input-radio-button text-center'
                                onChange={onChangeConsent}
                            />
                        </div>
                    </div>
                </div>
                <div data-test-id='consentFooter' className='consent-footer'>
                    <Button
                        data-test-id='confirm'
                        variant='primary'
                        loading={loading}
                        disabled={consentInput.c1 !== 'Y' || consentInput.c2 !== 'Y'}
                    >
                        {t('common:confirm')}
                    </Button>
                </div>
            </FormField>
            <EXPLiftUp.slideUp
                show={showPrivacyPolicyPopup}
                zIndex={'var(--liftup-consent-zindex)'}
                onClose={() => setShowPrivacyPolicyPopup(false)}
            >
                <div className='pt-3'>
                    <PrivacyPolicyCentral20 />
                    <LiffupFooter>
                        <Button variant='primary' onClick={() => setShowPrivacyPolicyPopup(false)}>
                            {t('common:back')}
                        </Button>
                    </LiffupFooter>
                </div>
            </EXPLiftUp.slideUp>
        </>
    );
}

export default Central20Consent;
