import React from 'react';
import { useTranslation } from 'react-i18next';
import { paramsjs } from 'helpers';
import { css } from '@emotion/react';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import useServiceConfig from 'hooks/useServiceConfig';
import { POST } from 'api';
// import { ProgressBar as ProgressbarUI } from 'anythink-ui'
import { Stepper } from '../Stepper';

const style = css`
    label: progressbar;

    position: relative;
    display: flex;
    width: 100%;
    height: var(--progressbar-with-theme-height);
    overflow: hidden;

    .progressbar-bar {
        display: inline-flex;
        margin: auto;
    }

    .progressbar-item {
        position: relative;

        .progressbar-point {
            position: relative;
            width: var(--progressbar-point-size-active);
            height: var(--progressbar-point-size-active);
            overflow: hidden;
            z-index: 1;

            &::before {
                content: '';
                position: absolute;
                top: calc(50% - (var(--progressbar-point-size-inactive) / 2));
                left: calc(50% - (var(--progressbar-point-size-inactive) / 2));
                display: block;
                width: var(--progressbar-point-size-inactive);
                height: var(--progressbar-point-size-inactive);
                border-radius: 50%;
                background-color: var(--progressbar-point-background-color);
            }
            &::after {
                content: '';
                position: absolute;
                top: calc(50% - (var(--progressbar-circle-in-point-size-inactive) / 2));
                left: calc(50% - (var(--progressbar-circle-in-point-size-inactive) / 2));
                display: block;
                width: var(--progressbar-circle-in-point-size-inactive);
                height: var(--progressbar-circle-in-point-size-inactive);
                border-radius: 50%;
                background-color: var(--progressbar-point-color);
            }

            &.__active-icon::after {
                display: none;
            }
        }

        .progressbar-icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: var(--progressbar-icon-in-point-color-inactive);
            font-size: var(--progressbar-icon-in-point-size-inactive);
            z-index: 2;
        }

        .progressbar-line {
            position: absolute;
            top: calc((var(--progressbar-point-size-active) / 2) - 1.5px);
            left: calc((var(--progressbar-point-size-active) / 2) - 1.5px);
            width: 100%;
            height: 3px;
            background-color: var(--progressbar-line-background-color);

            .progressbar-line-level {
                position: relative;
                width: 0;
                height: 100%;
                background-color: var(--progressbar-line-background-color-active);
                &::after {
                    content: '';
                    position: absolute;
                    top: -2.2px;
                    right: -3px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50px;
                }
            }
        }

        .progressbar-text {
            width: 200px;
            color: var(--progressbar-label-color);
            font-size: 75%;
            text-align: left;
            line-height: 1.2;
            word-break: break-word;
            padding-right: 18px;
            padding-top: 6px;
        }

        &:last-of-type .progressbar-line {
            width: 0;
        }

        &.__active {
            .progressbar-line .progressbar-line-level::after {
                background-color: var(--progressbar-line-background-color-active);
            }

            .progressbar-point {
                &::before {
                    background-color: var(--progressbar-point-background-color-active);
                }
                &::after {
                    background-color: var(---progressbar-point-color-active);
                }
            }

            .progressbar-icon {
                color: var(--progressbar-icon-in-point-color-active);
                font-size: var(--progressbar-icon-in-point-size-inactive);
            }

            .progressbar-text {
                color: var(--progressbar-label-color-active);
            }
        }

        &.__active.__current-active {
            .progressbar-point::before {
                top: calc(50% - (var(--progressbar-point-size-active) / 2));
                left: calc(50% - (var(--progressbar-point-size-active) / 2));
                width: var(--progressbar-point-size-active);
                height: var(--progressbar-point-size-active);
            }
            .progressbar-text {
                font-weight: 600;
            }
        }

        &.__adjust-size {
            .progressbar-point::after {
                top: calc(50% - (var(---progressbar-point-size-active) / 2));
                left: calc(50% - (var(---progressbar-point-size-active) / 2));
                width: var(---progressbar-point-color-active);
                height: var(---progressbar-point-color-active);
            }

            .progressbar-line .progressbar-line-level::after {
                display: none;
            }
        }
        &.__adjust-size.__current-active {
            .progressbar-point::before {
                top: calc(50% - (var(--progressbar-point-size-inactive) / 2));
                left: calc(50% - (var(--progressbar-point-size-inactive) / 2));
                width: var(--progressbar-point-size-inactive);
                height: var(--progressbar-point-size-inactive);
            }
        }
    }
`;

function ProgressBar(props) {
    const theme = useThemeWithPartner();
    const serviceConfig = useServiceConfig();
    const { i18n } = useTranslation();

    // state
    const [progressBarList, setProgressBarList] = React.useState([]);
    const [progressPercent, setProgressPercent] = React.useState(0);
    const [activeKey, setActiveKey] = React.useState(0);

    // method

    React.useEffect(() => {
        let isUnmount = false;

        const init = async () => {
            if (!serviceConfig.name) return;

            const { task } = paramsjs.query();
            let _pathname = window.location.pathname;

            if (_pathname.endsWith('/')) {
                _pathname = _pathname.substring(0, _pathname.length - 1);
            }

            try {
                const res = await POST('/misc/progress-bar', {
                    taskId: task || null,
                    origin: serviceConfig.serviceOrigin,
                    name: serviceConfig.name,
                    pathname: _pathname,
                    framework: 'react',
                });

                const _pointLabel = theme.name === 'rabbit' ? '' : '●';
                const _progressList = res.progressList.map((item, index) => {
                    const lastIndex = res.progressList.length - 1;
                    return {
                        key: index + 1,
                        label: item,
                        title: item,
                        pointLabel: lastIndex === index ? <i className='fas fa-check-circle'></i> : _pointLabel,
                        icon: lastIndex === index ? <i className='fas fa-check-circle'></i> : undefined,
                    };
                });

                if (!isUnmount) {
                    setActiveKey(res.step);
                    setProgressBarList(_progressList);
                    setProgressPercent(res.level);
                }
            } catch (error) {
                console.error('get progress bar>> ', window.location.pathname, '>> error:', error);
            }
        };

        init();

        return () => (isUnmount = true);
    }, [serviceConfig, i18n.language]);

    if (!progressBarList || progressBarList.length === 0) return null;

    // return (
    //     <ProgressbarUI {...props} activeKey={activeKey} progressPercent={progressPercent} items={progressBarList} className='progressbar' />
    // )

    return (
        <div
            className='progressbar'
            style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: 'var(--progressbar-label-font-size)' }}
        >
            <Stepper
                itemWidth={140}
                fixHeight={'var(--progressbar-height)'}
                current={activeKey - 1}
                percent={progressPercent}
                items={progressBarList}
            />
        </div>
    );
}

export default ProgressBar;
export { ProgressBar };
