import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { extendesClassname } from 'helpers';

/**
 * @version 1.0.3 check tab active to show only that tab
 * @version 1.0.2 change tag div(tab) to "button"
 * @version 1.0.1 create
 *
 * @param {{
 * activeKey: string,
 * defaultActiveKey: string,
 * tabPosition: 'top' | 'left' | 'right',
 * children: element,
 * onChange: (currentTabKey: string)=> void
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> } props
 * @returns
 */
function Tabs({ children, activeKey, defaultActiveKey, tabPosition, onChange, ...props }) {
    const [tabKey, setTabKey] = useState(null);
    const [defaultActiveWorkDone, setDefaultActiveWorkDone] = useState(false);

    const onChangeTab = (index, tab) => {
        setTabKey(index);
        onChange(tab || index);
    };

    useEffect(() => {
        if (!defaultActiveWorkDone && defaultActiveKey && Array.isArray(children) && children.find((f) => f.key === defaultActiveKey)) {
            setTabKey(children.findIndex((f) => f.key === defaultActiveKey));
            setDefaultActiveWorkDone(true);
            return;
        }

        if (!defaultActiveWorkDone) setDefaultActiveWorkDone(true);
    }, [defaultActiveKey]);

    useEffect(() => {
        if (defaultActiveWorkDone && activeKey && Array.isArray(children) && children.find((f) => f.key === activeKey)) {
            return setTabKey(children.findIndex((f) => f.key === activeKey));
        }

        if (defaultActiveWorkDone && !tabKey) return setTabKey(0);
    }, [activeKey, defaultActiveWorkDone]);

    if (!tabKey && tabKey !== 0) return null;

    if (!Array.isArray(children))
        return (
            <Style {...props}>
                <div className='tabs-nav'>
                    <button className='tabs-tab tabs-tab-active' disabled={children.props?.disabled}>
                        {children.props.tab}
                    </button>
                </div>
                <div {...extendesClassname(['tabs-content', children.props.className])}>{children.props.children}</div>
            </Style>
        );

    return (
        <Style directionTabs={Tabs.DIRECTION_TABS[tabPosition]} directionNav={Tabs.DIRECTION_NAV[tabPosition]} {...props}>
            <div className='tabs-bar'>
                <div className='tabs-nav'>
                    {children.map(
                        (values, index) =>
                            values.props?.tab && (
                                <button
                                    type='button'
                                    key={index}
                                    tabIndex={index}
                                    className={index === tabKey ? 'tabs-tab tabs-tab-active' : 'tabs-tab'}
                                    disabled={values.props?.disabled}
                                    onClick={() => onChangeTab(index, values.key)}
                                >
                                    {values.props.tab}
                                </button>
                            )
                    )}
                </div>
            </div>

            {children[tabKey].props?.tab && (
                <div aria-labelledby={'tab-' + (tabKey + 1)} {...extendesClassname(['tabs-content', children[tabKey].props?.className])}>
                    {children[tabKey].props?.children}
                </div>
            )}
        </Style>
    );
}

Tabs.DIRECTION_TABS = {
    top: 'column',
    left: 'row',
    bottom: 'column-reverse',
    right: 'row-reverse',
};
Tabs.DIRECTION_NAV = {
    top: 'row',
    left: 'column',
    bottom: 'row',
    right: 'column',
};

Tabs.defaultProps = {
    tabPosition: 'top',
    onChange: () => null,
};
Tabs.propsType = {
    activeKey: PropTypes.string,
    tabPosition: PropTypes.oneOf(['top', 'left', 'right']),
    onChange: PropTypes.func,
};

/**
 * @version 1.0.2 add parameter "disabled"
 * @version 1.0.1 create
 *
 * @see tab: text in TabPane's head
 * @see key: TabPane's key
 * @param {{
 * key: string,
 * tab: string | Element,
 * disabled: boolean,
 * children: Element,
 * className: string
 * }} props
 * @returns
 */
function TabPane({ key, tab, children, className, disabled = false }) {
    return (
        <div key={key} className={className}>
            <button type='button' disabled={disabled}>
                {tab}
            </button>
            <div>{children}</div>
        </div>
    );
}

TabPane.propsType = {
    key: PropTypes.string,
    className: PropTypes.string,
    tab: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export { Tabs, TabPane };

const Style = styled.div`
    label: tabs;

    display: flex;
    flex-direction: ${(props) => props.directionTabs || 'column'};
    margin: 0;
    padding: 0;
    overflow: hidden;

    .tabs-bar {
        position: relative;
        overflow: hidden;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 1.2rem;
            z-index: 2;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }
    .tabs-nav {
        position: relative;
        display: flex;
        flex: none;
        align-items: center;
        flex: 1 0 auto;
        flex-direction: ${(props) => props.directionNav || 'row'};
        min-width: 50px;
        transition: transform 0.3s;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .tabs-tab {
        position: relative;
        /* display: inline-flex; */
        /* align-items: center; */
        padding: 8px 24px;
        text-align: center;
        cursor: pointer;
    }

    .tabs-tab-active {
        color: #f00;
    }

    .tabs-content {
        width: 100%;
    }
`;
