import Breadcrumbs from 'components/common/breadcrumbs';
import { ClaimPidStyled } from './Claim.styled';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'components/common/progress-bar';
import { Field, FormField } from 'components/common/exp-form';
import { FormFooter, FormInput, FormPanel } from 'components/layouts/page-layout';
import { TextTitle } from 'components/common/styled-components-common';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import moment from 'moment';
import { Else, If, Then } from 'components/common/if-condition';
import Button from 'components/common/button';
import EXPPopupJs from 'components/common/exp-popupjs';
import { DEFAULT_PHARMCARE_PROGRAM } from 'constants/service-config';
import useServiceConfig from 'hooks/useServiceConfig';
import { getApiCallTypeWithTheme, getSelectCoverage } from 'api/api-often';
import useAuth from 'hooks/useAuth';
import usePageContext from 'hooks/usePageContext';
import { POST } from 'api';
import { checkDevice } from 'helpers';
import { useEffect, useState } from 'react';
import { ClaimCheckCoveragePayload, TaskAlreadyDetail } from 'types';
import useQueryParams from 'hooks/useQueryParams';
import { INSURANCE_COMPANY, TASK_REJECT_TASK_STATUS_CODE_LIST } from 'constants/default-config';
import { idCardPattern, passportPattern } from 'constants/regex-pattern';
import ClaimCancelTask from 'modules/conditional/claim-cancel-task';
import CameraThaiIdCard from 'components/elements/camera-thai-id-card';
import CameraProfilePicture from 'components/elements/camera-profile-picture';

interface FormData {
    insuranceCompanyCode: string | null;
    userPidType: string;
    idCard?: string | null;
    passport?: string | null;
    dateOfBirth: string | null;
    policyNumber: string | null;
    privilegeCardNo: string | null;
}

interface InsuranceCompanyData {
    label: React.ReactNode;
    value: string;
    xs: string;
}

function ClaimPidV2() {
    const { t } = useTranslation(['claim']);
    const { profile, getProfile, updateProfile } = useAuth();
    const { setPageLoaded } = usePageContext();
    const navigate = useNavigate();
    const theme = useThemeWithPartner();
    const serviceConfig = useServiceConfig();
    const { query: params, queryToParams } = useQueryParams();
    //
    const [taskAlreadyDetail, setTaskAlreadyDetail] = useState<TaskAlreadyDetail>({
        taskId: '',
        processCode: 0,
    });
    const [formData, setFormData] = useState<FormData>({
        insuranceCompanyCode: null,
        userPidType: '01',
        dateOfBirth: null,
        policyNumber: null,
        privilegeCardNo: null,
    });
    const [userPid, setUserPid] = useState<string | null>(null);
    const [insuranceCompanyList, setInsuranceCompanyList] = useState<InsuranceCompanyData[]>([]);
    const [isSendBypass, setIsSendBypass] = useState<boolean>(false);
    const [isSendData, setIsSendData] = useState<boolean>(false);
    const [showCancel, setShowCancel] = useState<boolean>(false);
    const [reqPolicyNumberForSundaay, setReqPolicyNumberForSundaay] = useState<boolean>(false);
    const [step, setStep] = useState<'PID' | 'ID_CARD' | 'FACE_ID'>('PID');
    //

    const onCheckBTNCancel = (data: any = {}) => {
        const redirectUrl = data.ctaRedirectUrl + '?task=' + data.taskId + '&flag=' + data?.task?.phrExistFlag;

        if (data.taskStatusCode < 10 && data.ppointmentNo === null && data.pharmacyServiceId) {
            return [
                {
                    label: t('claim:cancel_task.complete_ongoing_service'),
                    variant: 'primary',
                    action: () => navigate(redirectUrl),
                },
                {
                    label: t('claim:cancel_task.cancel_ongoing_service'),
                    className: 'btn-underline',
                    action: () => setShowCancel(false),
                },
            ];
        } else {
            return [
                {
                    label: t('claim:cancel_task.complete_ongoing_service'),
                    variant: 'primary',
                    action: () => navigate(redirectUrl),
                },
            ];
        }
    };

    const onSelectCoverage = async (coverageData: any, userPid: string, phrExistFlag: string | number) => {
        const { pharmacyCode, hspCode } = params;

        try {
            const res = await getSelectCoverage({
                userPid: userPid as string,
                pharmacyCode: pharmacyCode,
                hspCode: hspCode,
                phrExistFlag: phrExistFlag as number,
                serviceConfig: window.location.pathname.startsWith('/telepharmacy-ltc')
                    ? { ...serviceConfig, name: 'telepharmacyLTC' }
                    : serviceConfig,
                coverageData: coverageData,
                translation: t,
            });

            if (res.statusCode === 300) {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: t('common:warning'),
                    message: res.rejectReason,
                    classNames: { message: 'font-detail2' },
                    buttons: onCheckBTNCancel(res.taskAlready),
                });

                setIsSendBypass(false);
                setIsSendData(false);
                setTaskAlreadyDetail({ ...res.taskAlready });
            } else {
                navigate(res.redirectUrl);
            }
        } catch (error) {
            console.error('/claim/selectCoverage error', error);

            setIsSendBypass(false);
            setIsSendData(false);
        }
    };
    const onSubmitCoverage = async (bypassData?: any, defaultVoucher = false) => {
        let companyCode: string | null = null;
        const { voucher } = params;

        if (profile?.userXPartner && profile?.userXPartner?.partnerCompanyCode) {
            companyCode = profile.userXPartner?.partnerCompanyCode;
        }
        if (formData.insuranceCompanyCode) {
            companyCode = formData.insuranceCompanyCode;
        }

        if (bypassData) {
            companyCode = null;
        }

        const apiCallType = getApiCallTypeWithTheme(theme.name, companyCode as string);
        let payload: ClaimCheckCoveragePayload = {
            companyCode: companyCode,
            policyNumber: formData.policyNumber,
            voucherType: null,
            voucherCode: voucher,
            pharmcareProgram: DEFAULT_PHARMCARE_PROGRAM[serviceConfig.name],
            apiCallType,
            taskCommunicationType: serviceConfig.communicationType,
            privilegeCardNo: formData.privilegeCardNo || null,
            insuranceCode: profile.userRichMenu?.insuranceCode || null,
            validatePid: 1,
            data: {
                firstName: profile.firstName as string,
                lastName: profile.lastName as string,
                userPidType: formData.userPidType,
                userPid: formData?.userPidType === '01' ? (formData?.idCard as string) : (formData?.passport as string),
                userEmail: profile.userEmail as string,
                dateOfBirth: formData.dateOfBirth ? moment(formData.dateOfBirth).format('YYYY-MM-DD') : null,
                phoneNumber: profile.phoneNumber as string,
            },
        };

        // override
        if (bypassData) {
            payload.data.userPidType = bypassData.userPidType;
            payload.data.userPid = bypassData?.userPid;
            payload.data.dateOfBirth = bypassData.dateOfBirth ? moment(bypassData.dateOfBirth).format('YYYY-MM-DD') : null;
        }
        if (bypassData && theme.name === 'mtl') {
            payload.apiCallType = undefined;
        }
        if (defaultVoucher) {
            payload.voucherCode = params.billing;
        }

        // alway set
        setUserPid(payload.data.userPid);
        // start call api
        setIsSendData(true);
        try {
            const res = await POST('/claim/checkCoverage', payload);

            const amountZero = res.data?.filter((e) => e.amount < 1) || [];
            const isAllAmountZero = amountZero.length === res.data?.length;
            await updateProfile();
            if (res?.redirectUrl !== null && isAllAmountZero) {
                const nextParams = queryToParams({
                    ...params,
                    voucherCode: payload.voucherCode,
                    companyCode: companyCode,
                    policyNumber: payload.policyNumber,
                    privilegeCardNo: payload.privilegeCardNo,
                });

                navigate(serviceConfig.servicePath + '/claim/not-found/' + nextParams);
            } else if (['mtl', 'default_oceanopd'].some((f) => f === theme.name) || companyCode === '19') {
                // convert
                // const resSaveTempData = await POST('/user/save-temp-data', {
                //     // userId: profile.userId,
                //     userTempData: {
                //         ...userTempData,
                //         voucherCode: payload.voucherCode,
                //         companyCode: _companyCode || null,
                //         defaultVoucherWithBilling: defaultVoucherWithBilling,
                //         policyNumber: payload.policyNumber,
                //         privilegeCardNo: payload.privilegeCardNo,
                //         ltcFlag: window.location.pathname.startsWith('/telepharmacy-ltc') ? 1 : 0,
                //     },
                // });
                // await updateProfile();
                // if (resSaveTempData.statusCode === 200) {
                //     const { isWebview, redirectUrl } = checkDevice(serviceConfig.servicePath + '/claim/profile/id-card', '', profile);
                //     if (isWebview && theme.name !== 'workplaze') {
                //         window.open(redirectUrl);
                //         window.location.replace('/');
                //     } else {
                //         navigate(serviceConfig.servicePath + '/claim/profile/id-card' + window.location.search);
                //     }
                // }
                if (bypassData) {
                    onSelectCoverage(res.data[0], payload.data.userPid as string, res.phrExistFlag);
                } else if (step === 'PID') {
                    setIsSendData(false);
                    setStep('ID_CARD');
                } else {
                    const nextParams = queryToParams({
                        ...params,
                        voucherCode: payload.voucherCode,
                        companyCode: companyCode,
                        policyNumber: payload.policyNumber,
                        privilegeCardNo: payload.privilegeCardNo,
                    });

                    navigate(serviceConfig.servicePath + '/claim/select/' + nextParams);
                }
            } else if (res?.statusCode === 300) {
                EXPPopupJs.alert({
                    type: 'warning',
                    icon: 'warning',
                    title: t('common:error'),
                    message: res?.rejectReason,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: () => navigate('/service?tabs=inprogress'),
                        },
                    ],
                });
            } else if (bypassData && theme.name === 'default') {
                const coverageWithVoucher = res.data?.find((f) => f.voucherCode === payload.voucherCode);
                if (coverageWithVoucher) {
                    onSelectCoverage(coverageWithVoucher, payload.data.userPid as string, res.phrExistFlag);
                } else {
                    onSelectCoverage(res.data[0], payload.data.userPid as string, res.phrExistFlag);
                }
            } else if (res.data?.length > 1) {
                const nextParams = queryToParams({
                    ...params,
                    voucherCode: payload.voucherCode,
                    companyCode: companyCode,
                    policyNumber: payload.policyNumber,
                    privilegeCardNo: payload.privilegeCardNo,
                });

                navigate(serviceConfig.servicePath + '/claim/select/' + nextParams);
            } else {
                onSelectCoverage(res.data[0], payload.data.userPid as string, res.phrExistFlag);
            }
        } catch (error: any) {
            console.error('/claim/checkCoverage error', JSON.stringify(error));

            setIsSendBypass(false);
            setIsSendData(false);

            if (error.statusCode === 404 && error.redirectUrl) {
                const nextParams = queryToParams({
                    ...params,
                    voucherCode: payload.voucherCode,
                    companyCode: companyCode,
                    policyNumber: payload.policyNumber,
                    privilegeCardNo: payload.privilegeCardNo,
                });

                navigate(serviceConfig.servicePath + '/claim/not-found/' + nextParams);
            } else if (error.statusCode === 404 && theme.name === 'central20') {
                EXPPopupJs.simple('warning', t('common:error'), 'ไม่พบสิทธิ์ของท่าน หรือ สิทธิ์ของท่านได้ถูกใช้ไปแล้ว');
            } else if (error.statusCode === 404 && params.isBilling) {
                EXPPopupJs.alert({
                    type: 'warning',
                    icon: 'warning',
                    title: 'เราไม่พบข้อมูลคุณในฐานข้อมูล Humanica',
                    message: 'ถ้าคุณไม่ใช่พนักงาน Humanica กรุณารับบริการแบบไม่เบิกบริษัท',
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: 'รับบริการแบบไม่เบิก',
                            variant: 'primary',
                            action: onSubmitCoverage(false),
                        },
                        {
                            label: t('common:please_try_again'),
                            className: 'btn-underline',
                        },
                    ],
                });
            } else if (error.isNoPopup) {
                EXPPopupJs.simple('warning', t('common:error'), error.rejectReason);
            }
        }
    };

    const onImageSaved = () => {
        onSubmitCoverage();
    };

    const onBypassPid = async () => {
        setIsSendBypass(true);
        try {
            const res = await POST('/user/bypassPid', {
                pid: null,
                pidType: null,
                dateOfBirth: null,
            });

            onSubmitCoverage({
                userPidType: formData?.userPidType || res.userProfile.userPid,
                userPid:
                    (formData?.userPidType === '01' ? (formData?.idCard as string) : (formData?.passport as string)) ||
                    res.userProfile.userPidType,
                dateOfBirth: formData?.dateOfBirth ? formData?.dateOfBirth : res.userProfile.dateOfBirth,
            });
        } catch (error) {
            console.error('/user/bypassPid => error: ', error);
            setIsSendBypass(false);
        }
    };

    const onCancel = async (res: any = {}) => {
        setShowCancel(false);

        if (
            TASK_REJECT_TASK_STATUS_CODE_LIST.some((e) => e === res.data.task.taskStatusCode) &&
            serviceConfig.servicePath === '/telepharmacy'
        ) {
            navigate('/telepharmacy/service/task-reject?task=' + res.data.task.taskId);
        } else {
            navigate('/home');
        }
    };

    const onChangeFormData = (event: any) => {
        if (event?.persist) event?.persist();

        let value = event.target.value;

        if (event.target.name === 'privilegeCardNo') {
            value = event.target.value.toUpperCase();
        }
        setFormData((prevState) => ({ ...prevState, [event.target.name]: value }));
    };

    const getInsuranceCompanyList = () => {
        let insuranceCompanyList: {
            imageUrl: string;
            companyCode: string;
        }[] = [];

        if (serviceConfig.name === 'tlm') {
            insuranceCompanyList.push(INSURANCE_COMPANY.sunday);
        }
        if (process.env.REACT_APP_OPEN_COMPANY_03 === 'true') {
            insuranceCompanyList.push(INSURANCE_COMPANY.pharmcare);
        }

        return insuranceCompanyList.map((items) => {
            return {
                label: <img src={items.imageUrl} alt='' />,
                value: items.companyCode,
                xs: 'auto',
            };
        });
    };

    useEffect(() => {
        const init = async () => {
            try {
                let insuranceCompanyCode: string | null = null;
                const { policyNumber, privilegeCardNo } = params;
                const userProfile = await getProfile();
                const insuranceList = getInsuranceCompanyList();

                if (theme.name === 'default' && serviceConfig.name === 'telepharmacy') {
                    insuranceCompanyCode = '19';
                }
                if (
                    profile.userRichMenu?.richMenuType === '01' &&
                    ['SUNDAYCARE_MOBILE_APP', 'AXA'].some((f) => f === profile.userRichMenu?.insuranceCode)
                ) {
                    setReqPolicyNumberForSundaay(true);
                }
                setFormData((prevState) => ({
                    ...prevState,
                    insuranceCompanyCode: insuranceCompanyCode,
                    policyNumber: policyNumber,
                    privilegeCardNo: privilegeCardNo,
                    userPidType: userProfile.userPidType,
                    idCard: userProfile.userPidType === '01' ? userProfile.userPid : null,
                    passport: userProfile.userPidType === '02' && userProfile.fakePidFlag === 0 ? userProfile.userPid : null,
                    dateOfBirth: userProfile.dateOfBirth ? moment(userProfile.dateOfBirth).format('YYYY-MM-DD') : null,
                }));

                setInsuranceCompanyList([...insuranceList]);

                setPageLoaded(true);
            } catch (error) {
                console.error('error: ', error);
                setPageLoaded(true);
            }
        };

        if (serviceConfig.readyConfig) {
            init();
        }
    }, [serviceConfig]);

    return (
        <>
            <Breadcrumbs
                title={t('claim:breadcrumbs.patient_details')}
                icon={<IconConfigWithTheme pageName='claim_pid' iconName='breadcrumbs' />}
                onBack={() => navigate(-1)}
            />
            <ClaimPidStyled>
                <ProgressBar />
                {/* pid */}
                {step === 'PID' && (
                    <FormField onSubmit={() => onSubmitCoverage()}>
                        <FormPanel className='custom-panel'>
                            <FormInput maxWidth='500px'>
                                <TextTitle id='title'>
                                    {theme.name === 'mtl'
                                        ? t('claim:pid.additional_patient_information')
                                        : t('claim:pid.check_coverage_detail')}
                                </TextTitle>
                                <div className='ads-coverage'>
                                    <div className='ads-coverage-content'>
                                        <div className='ads-coverage-content-left'>
                                            <div className='ads-coverage-content-title'>
                                                {theme.name === 'mtl'
                                                    ? t('claim:pid.applicable_for_insured_patients')
                                                    : t('claim:pid.check_coverage_with_pharmcare')}
                                            </div>
                                            <div className='ads-coverage-content-descript'>
                                                {theme.name === 'mtl'
                                                    ? t('claim:pid.enter_policy_number_or_privilege_card')
                                                    : t('claim:pid.for_coverage_discounts')}
                                            </div>
                                        </div>
                                        <div className='ads-coverage-content-right'>
                                            <img src='/images/claim/claim-pid.png' alt='claim-pid' />
                                        </div>
                                    </div>
                                </div>
                                {theme.name === 'default' && (
                                    <div>
                                        <small>เลือกบริษัทประกัน</small>
                                        <div className='content-insurance'>
                                            <Field.RadioGroup
                                                name='insuranceCompanyCode'
                                                value={formData.insuranceCompanyCode ?? ''}
                                                radioListGroup={insuranceCompanyList}
                                                classNameField='input-radio-type5 select-insurance'
                                                onChange={onChangeFormData}
                                                required
                                            />
                                        </div>
                                    </div>
                                )}
                                <Field.RadioGroup
                                    title={t('common:profile.personal_identification')}
                                    type='radio-group'
                                    name='userPidType'
                                    value={formData.userPidType}
                                    radioListGroup={[
                                        { label: t('common:profile.id_card'), value: '01', xs: 12, sm: 6 },
                                        { label: t('common:profile.passport'), value: '02', xs: 12, sm: 6 },
                                    ]}
                                    onChange={onChangeFormData}
                                    classNameField='input-radio-type1'
                                    // required
                                />
                                {formData.userPidType === '01' && (
                                    <Field.Control
                                        title={t('common:profile.id_card_number')}
                                        name='idCard'
                                        value={formData.idCard || ''}
                                        onChange={onChangeFormData}
                                        placeholder={t('common:profile.fill_id_card_number')}
                                        required
                                        pattern={idCardPattern}
                                        errorMsg={t('common:profile.please_id_card_number')}
                                    />
                                )}

                                {formData.userPidType === '02' && (
                                    <Field.Control
                                        title={t('common:profile.passport_number')}
                                        name='passport'
                                        value={formData.passport || ''}
                                        onChange={onChangeFormData}
                                        placeholder={t('common:profile.fill_passport_number')}
                                        required
                                        pattern={passportPattern}
                                        errorMsg={t('common:profile.please_passport_number')}
                                    />
                                )}

                                <Field.Date
                                    title={t('common:profile.date_of_birth')}
                                    dateType='datepickerslider'
                                    name='dateOfBirth'
                                    value={formData.dateOfBirth || ''}
                                    placeholder='DD/MM/YYYY'
                                    formatDate='DD/MM/YYYY'
                                    errorMsg={t('common:profile.please_date_of_birth')}
                                    recommendMsg={t('common:profile.please_date_of_birth')}
                                    min={moment().add(-150, 'year').format('YYYY-MM-DD')}
                                    max={moment().add(-1, 'year').endOf('month').endOf('year').format('YYYY-MM-DD')}
                                    onChange={onChangeFormData}
                                    required
                                />

                                {(theme.name === 'rabbit' || theme.name === 'default_sunday') && (
                                    <Field.Control
                                        title={t('common:profile.policy_number')}
                                        name='policyNumber'
                                        value={formData.policyNumber || ''}
                                        onChange={onChangeFormData}
                                        placeholder={t('common:profile.fill_policy_number')}
                                        required={theme.name === 'rabbit' || reqPolicyNumberForSundaay ? true : false}
                                        minLength={5}
                                        errorMsg={t('common:profile.please_policy_number')}
                                    />
                                )}

                                {theme.name === 'mtl' && (
                                    <Field.Mask
                                        title={t('common:profile.please_specify_your_policy_number')}
                                        name='privilegeCardNo'
                                        value={formData.privilegeCardNo ?? ''}
                                        maskChar={null}
                                        mask='*-***-****-********-**'
                                        placeholder={t('common:profile.please_specify_policy_number')}
                                        onChange={onChangeFormData}
                                        pattern='[\w\-]{22,22}$'
                                        errorMsg={t('common:profile.please_specify_policy_number')}
                                    />
                                )}
                            </FormInput>
                            <If
                                condition={
                                    !['default_oceanopd', 'default_sunday', 'rabbit'].some((f) => f === theme.name) &&
                                    !theme.name.includes('central') &&
                                    params.isBilling === 'false'
                                }
                            >
                                <Then>
                                    <FormFooter className='-action'>
                                        <Button
                                            type='button'
                                            variant='second'
                                            data-test-id='submitSelfPay'
                                            className='btn-confirm'
                                            loading={isSendBypass}
                                            disabled={isSendData}
                                            onClick={onBypassPid}
                                        >
                                            {theme.name === 'mtl'
                                                ? t('common:self_pay_mtl')
                                                : theme.name === 'default'
                                                ? t('common:self_pay')
                                                : t('common:fill_later')}
                                        </Button>

                                        <Button
                                            variant='primary'
                                            data-test-id='submitInsuranceHolder'
                                            className='btn-confirm ml-auto'
                                            disabled={(!formData.idCard && !formData.passport && !formData.dateOfBirth) || isSendBypass}
                                            loading={isSendData}
                                        >
                                            {theme.name === 'mtl'
                                                ? t('common:check_insurance_eligibility')
                                                : theme.name === 'default'
                                                ? t('common:insurance_holder')
                                                : t('common:confirm')}
                                        </Button>
                                    </FormFooter>
                                </Then>
                                <Else>
                                    <FormFooter className='-action -action-rabbit'>
                                        <Button
                                            variant='primary'
                                            data-test-id='submit'
                                            className='btn-confirm ml-auto'
                                            disabled={(!formData.idCard && !formData.passport && !formData.dateOfBirth) || isSendBypass}
                                            loading={isSendData}
                                        >
                                            {t('common:confirm')}
                                        </Button>
                                    </FormFooter>
                                </Else>
                            </If>
                        </FormPanel>
                    </FormField>
                )}
                {/* id card */}
                {step === 'ID_CARD' && (
                    <FormPanel className='custom-form-panel'>
                        <div className='thai-id-card-header'>
                            <TextTitle style={{ justifyContent: 'center' }} className='header-title'>
                                {t('claim:pid.take_a_photo_of_your_identification_document')}
                            </TextTitle>
                            <div className='header-subtitle'>{t('claim:pid.place_your_document_within_the_regtangle')}</div>
                        </div>
                        <div className='thai-id-card-panel'>
                            <CameraThaiIdCard pid={userPid} servicePath={serviceConfig.servicePath} onFinished={() => setStep('FACE_ID')} />
                        </div>
                    </FormPanel>
                )}
                {/* profile face */}
                {step === 'FACE_ID' && (
                    <FormPanel className='custom-form-panel'>
                        <div className='profile-picture-header'>
                            <TextTitle style={{ justifyContent: 'center' }} className='header-title'>
                                {t('claim:pid.capture_face_image')}
                            </TextTitle>
                        </div>
                        <div className='profile-picture-panel'>
                            <CameraProfilePicture pid={userPid} onImageSaved={onImageSaved} />
                        </div>
                    </FormPanel>
                )}
            </ClaimPidStyled>
            <ClaimCancelTask
                open={showCancel}
                serviceOrigin={serviceConfig.serviceOrigin}
                taskDetail={taskAlreadyDetail}
                onSubmited={onCancel}
                onClose={() => setShowCancel(false)}
            />
        </>
    );
}

export default ClaimPidV2;
