import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { breakpoint, paramsjs } from 'helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import useThemeWithPartner from 'hooks/useThemeWithPartner';

const Style = styled.div`
    label: nav-menu;

    display: flex;
    align-items: center;
    height: 100%;

    ${breakpoint('LG')} {
        display: none;
    }

    .list {
        margin-left: 50px;
        a {
            color: var(--navbar-menu-bar-color);
            font-weight: 600;
            font-size: 1.2rem;
        }
    }
`;

/**
 *
 * @param {{
 * to: String,
 * className: String,
 * classActive: String,
 * tag: String,
 * } & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>} props
 * @returns
 */
function NavLink({ to = '', className = '', classActive = '', tag = '', children, ...props }) {
    const location = useLocation();

    const getClassName = () => {
        let isActive = false;

        if (location.pathname || (tag && location.pathname.includes(tag))) isActive = true;

        if (className && isActive && classActive) return { className: className + ' ' + classActive };

        if (!className && isActive && classActive) return { className: classActive };

        if (className) return { className };

        return null;
    };

    if (to) {
        return (
            <a href={to} {...props} {...getClassName()}>
                {children}
            </a>
        );
    }
    return <button {...getClassName()}>{children}</button>;
}

function NavMenuBar({ role }) {
    const { t } = useTranslation();
    const theme = useThemeWithPartner();
    const navigate = useNavigate();

    const getGoHome = (e) => {
        e.preventDefault();

        const params = paramsjs.query()?.voucher ? '?voucher=' + paramsjs.query()?.voucher : '';

        if (theme.name === 'betterperiod') return '#';
        if (theme.name === 'bilaxten') return navigate('/activities');

        return navigate('/home' + params);
    };

    if (role === 'ROLE_WRITER') {
        return (
            <Style data-test-id='menu'>
                <div className='list'>
                    <NavLink to='/article-management'>{t('navbar:menu.article')}</NavLink>
                </div>
            </Style>
        );
    }

    if (role === 'ROLE_ADMIN') {
        return (
            <Style data-test-id='menu'>
                <div className='list'>
                    <NavLink to='/home' onClick={getGoHome}>
                        {t('navbar:menu.homepage')}
                    </NavLink>
                </div>
                <div className='list'>
                    <NavLink to='/article-management'>{t('navbar:menu.article')}</NavLink>
                </div>
            </Style>
        );
    }

    return (
        <Style data-test-id='menu'>
            <div className='list'>
                <NavLink to='/home' onClick={getGoHome}>
                    {t('navbar:menu.homepage')}
                </NavLink>
            </div>
        </Style>
    );
}

export default NavMenuBar;
