import { POST } from 'api';
import React, { useEffect, useState } from 'react';
import { MenuBox, Navigation } from 'components/common/navigation';
import { Panel } from 'components/common/styled-components-common';
import { HomeServiceAlive } from 'modules/page/home';
import Breadcrumbs from 'components/common/breadcrumbs';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { useTranslation } from 'react-i18next';
import WorkplazeThemeHideElement from 'components/elements/workplaze-theme-hide-element';
import { HomeAliveWrapper } from './Home.styled';
import { numberjs } from 'helpers';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

interface DefaultCoverage {
    defaultCoverageFlag?: number;
    amount?: number;
    imageUrl?: string;
}

function HomeAlive() {
    const navigate = useNavigate();
    const { name: theme } = useThemeWithPartner();
    const { t } = useTranslation(['home', 'navigation']);
    const { auth, profile } = useAuth();
    const [defaultCoverage, setDefaultCoverage] = useState<DefaultCoverage>({});

    const getCoverage = async () => {
        let companyCode: string | null = null;
        const data = {
            phoneNumber: profile.phoneNumber,
            userPidType: profile.userPidType,
            userPid: profile.userPid ?? null,
            dateOfBirth: profile.dateOfBirth,
            validatePid: 0,
        };

        if (profile?.userXPartner?.partnerCompanyCode) {
            companyCode = profile.userXPartner.partnerCompanyCode;
        }

        try {
            const result = await POST('/claim/checkCoverage', { data, companyCode });
            setDefaultCoverage(result.defaultCoverage || {});
        } catch (error) {
            console.error('claim/checkCoverage --> error', error);
        }
    };

    const removeRichmenu = async () => {
        if (auth.isAuth) {
            try {
                await POST('/user/remove-richmenu');
                window.location.reload();
            } catch (error) {
                console.error('/user/remove-richmenu ~ error', error);
            }
        }
    };

    useEffect(() => {
        if (theme !== 'default') {
            removeRichmenu();
        } else {
            getCoverage();
        }
    }, [theme]); // Only run when the theme changes

    return (
        <React.Fragment>
            <Breadcrumbs
                onBack={() => navigate(-1)}
                title={theme.includes('default') ? t('home:pharmcare_service') : t('home:service')}
                icon={<IconConfigWithTheme pageName='home_alive' iconName='breadcrumbs' />}
            />
            <HomeAliveWrapper>
                <Panel className='profile-panel'>
                    <div className='profile-photo'>
                        <img src={profile?.userImgUrl as string} alt='profile photo' />
                    </div>
                    <div className='profile-detail'>
                        <div className='text-gray-1'>{profile.displayName}</div>
                        {defaultCoverage?.defaultCoverageFlag === 1 && (
                            <div>วงเงิน: {numberjs(defaultCoverage?.amount ?? 0).format('double', 0, 2)} บาท</div>
                        )}
                    </div>
                    {defaultCoverage?.defaultCoverageFlag === 1 && (
                        <div className='coverage-logo'>
                            <img src={defaultCoverage?.imageUrl} alt='coverage logo' />
                        </div>
                    )}
                </Panel>
                <div className='service-title'>{t('home:our_services_choose')}</div>
                <HomeServiceAlive />
            </HomeAliveWrapper>
            <WorkplazeThemeHideElement>
                <Navigation>
                    {!['bilaxten'].some((f) => f === theme) && (
                        <MenuBox
                            to='/home'
                            tag='/home'
                            title={t('navigation:home')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='home' />}
                        />
                    )}

                    {!['mtl', 'default_oceanopd'].some((f) => f === theme) && (
                        <MenuBox
                            to='/phr/existing'
                            tag='/phr'
                            title={t('navigation:health_record')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='phr' />}
                        />
                    )}
                    <MenuBox
                        to='/service'
                        tag='/service'
                        title={t('navigation:acitivites')}
                        icon={<IconConfigWithTheme pageName='navigation' iconName='service' />}
                    />
                </Navigation>
            </WorkplazeThemeHideElement>
        </React.Fragment>
    );
}

export default HomeAlive;
