import React from 'react';
import styled from '@emotion/styled';
import useOnClickOutside from 'hooks/useOnClickOutside';
import Button from '../button';
import useAuth from 'hooks/useAuth';
import { POST } from 'api';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { browserName, isIOS, osVersion } from 'react-device-detect';
import { updateUserLanguage } from 'api/api-often';
import { Item, NavTool, Tool, ToolToggle, Wrapper } from './deverloper-tools-styled';
import { Field, FormField } from '../exp-form';

function DeveloperTools() {
    const enableDeveloperTools = window.localStorage.getItem('enableDeveloperTools');
    //
    const { auth, profile, updateProfile, userLogout } = useAuth();
    const theme = useThemeWithPartner();
    //
    const ref = React.useRef();
    //
    const [toggle, setToggle] = React.useState(false);

    useOnClickOutside(ref, () => setToggle(false));

    const removeLine = async () => {
        try {
            await POST('/user/removeLineUserId', {
                userId: profile.userId,
            });
            window.location.reload();
        } catch (error) {
            console.error('removeLineUserId ~ error', error);
        }
    };

    const onLogin = async (tokenKey = 'default', token) => {
        // const defaultToken =
        //     'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjMzOSwicm9sZUlkIjozLCJyb2xlQXV0aG9yaXR5IjoiUk9MRV9VU0VSIiwidXNlck5hbWUiOiJVNTE4YzRkNzEzNTJkMzQ2NzU4YzFiZDUyOThkNTQ5YTciLCJ1c2VyRW1haWwiOiJraGF3a3JpYWIuZGV2QG91dGxvb2suY29tIiwiaWF0IjoxNzI4NTMyNzgwLCJleHAiOjE3Mjg1MzI3ODAsImlzcyI6Imh0dHBzOi8vYXBwLWFwaS1zaXQucGhhcm1jYXJlLmNvIn0.Q_ocF9oabYc9IhK7_pcGmbVjTF8_A2zr6EHUPVrpWh0';
        const defaultToken =
            'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjM2Miwicm9sZUlkIjoxLCJyb2xlQXV0aG9yaXR5IjoiUk9MRV9BRE1JTiIsInVzZXJOYW1lIjoiVTgzNjY4NjBlNWZiMjVlMTU5NWM0MmFmY2JiNDkxOWM0IiwiYWN0dWFsRXhwIjoiMjAyNC0wOS0yNlQyMDo1OToyMy44ODRaIiwiaWF0IjoxNzI3MzIzMTYzLCJleHAiOjE4MTM3MjMxNjMsImlzcyI6Imh0dHBzOi8vYXBwLWFwaS1zaXQucGhhcm1jYXJlLmNvL2FwaSJ9.0JA1PPmg5TMY9HzUVF4qG3_2huvAvhWTVsexrF-moB4';
        const rabbitToken =
            'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjMzNiwicm9sZUlkIjozLCJyb2xlQXV0aG9yaXR5IjoiUk9MRV9VU0VSIiwidXNlck5hbWUiOiJVZmRmYmQyMDgyYWE3MWU1MzUxMjU4MGVmZTZkNmI2YzAiLCJ1c2VyRW1haWwiOiJraGF3a3JpYWIuZGV2QGdtYWlsLmNvbSIsImlhdCI6MTcyNjk5NzY1MCwiZXhwIjoxNzI2OTk3NjUwLCJpc3MiOiJodHRwczovL2FwcC1hcGktc2l0LnBoYXJtY2FyZS5jbyJ9.uklMpuALOjz8Z2DsiQ7gcAiEVv5EeF09F0VOHrgWkJc';
        const central20Token =
            'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjUyMywicm9sZUlkIjozLCJyb2xlQXV0aG9yaXR5IjoiUk9MRV9VU0VSIiwidXNlck5hbWUiOiJVNzRiNjVmNWZiOGI4YTcxYzFmNjM1M2M0MWIyNjdhOTEiLCJ1c2VyRW1haWwiOiJraGF3a3JpYWIuZGV2QG91dGxvb2suY29tIiwiYWN0dWFsRXhwIjoiMjAyMy0wNy0xMVQwMDoyNDoxNi43MDBaIiwiaWF0IjoxNzc1MzczODU2LCJleHAiOjE3NzUzNzM4NTYsImlzcyI6Imh0dHBzOi8vYXBwLWFwaS1zaXQucGhhcm1jYXJlLmNvL2FwaSJ9.2wuLo7qbB05jUnniWwYVEEgK5yqWyBm5MO5z6NsaWjQ';

        const betterperiodToken =
            'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjMzOCwicm9sZUlkIjozLCJyb2xlQXV0aG9yaXR5IjoiUk9MRV9VU0VSIiwidXNlck5hbWUiOiJVMjBlNDg1MmU1MmQzNWQ0YzVjOTlhZmM1OWRhZTc2ZjIiLCJ1c2VyRW1haWwiOiJraGF3a3JpYWIuZGV2QGdtYWlsLmNvbSIsImlhdCI6MTcyOTQ4NjY2MSwiZXhwIjoxNzI5NDg2NjYxLCJpc3MiOiJodHRwczovL2FwcC1hcGktc2l0LnBoYXJtY2FyZS5jbyJ9.rEKZoLby7B6BwlbhN0Rxjw1TydwIeklBn16u-axku40';
        const tokens = {
            default: defaultToken,
            rabbit: rabbitToken,
            central20: central20Token,
            betterperiod: betterperiodToken,
        };

        try {
            await updateProfile(token ?? tokens[tokenKey]);
        } catch (error) {
            console.error('%c>> updateProfile error:', 'background: #00f; color: #fff', error);
        }
    };

    const onChangeLanguage = async (language = '') => {
        if (auth.isAuth) {
            await updateUserLanguage(language, auth.accessToken);
            await updateProfile(auth.accessToken);
        } else {
            let _params = window.location.search;

            if (_params) {
                _params += '&lng=' + language;
            } else {
                _params += '?lng=' + language;
            }

            window.location.assign(window.location.pathname + _params);
        }
    };

    if (theme.name === 'workplaze' && process.env.REACT_APP_FILE_NAME === '.env.sit') {
        return (
            <Wrapper ref={ref} data-toggle={toggle}>
                <NavTool style={{ display: toggle ? 'block' : 'none' }}>
                    <div>Theme: {theme.name}</div>
                    <div>browserName: {browserName}</div>
                    <div>isIOS: {isIOS ? 'Y' : 'N'}</div>
                    <div>osVersion: {osVersion}</div>
                    <div>process.env.NODE_ENV: {process.env.NODE_ENV}</div>
                    <div>process.env.REACT_APP_FILE_NAME: {process.env.REACT_APP_FILE_NAME}</div>
                    <div>process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE: {process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE}</div>

                    <div>
                        login: {auth.isAuth ? 'Yes' : 'No'}
                        {auth.isAuth && `, role: ${auth.role}, userId: ${profile.userId}`}
                    </div>
                    {auth.isAuth && <div>displayName: {profile.displayName}</div>}
                    <hr />
                    <Tool>
                        <Item>
                            <Button variant='danger' onClick={() => window.location.assign('/template/user-sso')}>
                                sso template test
                            </Button>
                        </Item>
                        <Item>
                            <Button variant='danger' onClick={() => window.location.reload()}>
                                refresh
                            </Button>
                        </Item>
                        <Item>
                            <Button variant='danger' onClick={() => removeLine()}>
                                Remove LINE from this user
                            </Button>
                        </Item>
                    </Tool>
                </NavTool>
                <ToolToggle data-test='toggle' onClick={() => setToggle(!toggle)} />
            </Wrapper>
        );
    }

    if (process.env.NODE_ENV === 'development' || enableDeveloperTools === 'true') {
        return (
            <Wrapper ref={ref} data-toggle={toggle}>
                <NavTool style={{ display: toggle ? 'block' : 'none' }}>
                    <div>Theme: {theme.name}</div>
                    <div>browserName: {browserName}</div>
                    <div>isIOS: {isIOS ? 'Y' : 'N'}</div>
                    <div>osVersion: {osVersion}</div>
                    <div>process.env.NODE_ENV: {process.env.NODE_ENV}</div>
                    <div>process.env.REACT_APP_FILE_NAME: {process.env.REACT_APP_FILE_NAME}</div>
                    <div>process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE: {process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE}</div>
                    <div>
                        login: {auth.isAuth ? 'Yes' : 'No'}
                        {auth.isAuth && `, role: ${auth.role}, userId: ${profile.userId}`}
                    </div>
                    {auth.isAuth && <div>displayName: {profile.displayName}</div>}
                    <hr />
                    <FormField
                        style={{ display: 'flex' }}
                        onSubmit={(_, values) => {
                            onLogin('none', values.token);
                        }}
                    >
                        <Field.Control name='token' classNameField='w-100' placeholder='token' />
                        <Button variant='primary'>Login</Button>
                    </FormField>
                    <hr />
                    <Tool>
                        <Item>
                            <Button variant='info' onClick={() => window.location.assign('/template')}>
                                template
                            </Button>
                        </Item>
                        <Item>
                            <Button variant='second' onClick={() => window.location.assign('/template/theme')}>
                                template theme
                            </Button>
                        </Item>
                        <Item>
                            <Button variant='danger' onClick={() => window.location.reload()}>
                                refresh
                            </Button>
                        </Item>
                        <Item>
                            <Button variant='second' onClick={() => theme.setTheme('workplaze')}>
                                set theme workplaze
                            </Button>
                        </Item>
                        <Item>
                            <Button variant='primary' onClick={() => onChangeLanguage('th')}>
                                Change language: TH
                            </Button>
                        </Item>
                        <Item>
                            <Button variant='second' onClick={() => onChangeLanguage('en')}>
                                Change language: EN
                            </Button>
                        </Item>
                        <Item>
                            {!auth.isAuth ? (
                                <>
                                    <Button variant='primary' onClick={() => onLogin('default')}>
                                        Login default
                                    </Button>
                                    <Button variant='info' onClick={() => onLogin('rabbit')}>
                                        Login rabbit
                                    </Button>
                                    <Button variant='second' onClick={() => onLogin('central20')}>
                                        Login central20
                                    </Button>
                                    <Button variant='primary' onClick={() => onLogin('betterperiod')}>
                                        Login betterperiod
                                    </Button>
                                </>
                            ) : (
                                <Button variant='danger' onClick={() => userLogout(false)}>
                                    Logout
                                </Button>
                            )}
                        </Item>
                    </Tool>
                </NavTool>
                <ToolToggle data-test='toggle' onClick={() => setToggle(!toggle)} />
            </Wrapper>
        );
    }

    return null;
}

export default DeveloperTools;
