import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import { breakpoint } from 'helpers';
import useWindowSize from 'hooks/useWindowSize';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { ServiceRatingStyle } from './Landing.styled';

interface ServiceRatingProps {
    children: React.ReactNode[];
}

const sliderConfig = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                rows: 2,
            },
        },
    ],
};

function ServiceRating({ children }: Readonly<ServiceRatingProps>) {
    const slider = useRef<Slider | null>(null);
    const { width } = useWindowSize();
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const [quantity, setQuantity] = useState<number>(0);
    const [length, setLength] = useState<number>(children.length);

    const maxSlide = width < 768 ? Math.ceil(children.length / 2) : Math.ceil(children.length / 3);

    const onNext = (updateCount: number) => {
        if (updateCount + slideIndex >= 0 && updateCount + slideIndex < maxSlide) {
            setSlideIndex(updateCount + slideIndex);
            let next = updateCount + slideIndex;
            if (width > 768 && updateCount > 0) {
                next = quantity + Math.ceil(length / 3);
                setQuantity(next);
                setLength(length - Math.ceil(length / 3));
            }
            if (width > 768 && updateCount < 0) {
                next = quantity - Math.ceil(length / 3) - 1;
                setQuantity(next);
                setLength(length + Math.ceil(length / 3) + 1);
            }
            slider.current?.slickGoTo(next);
        }
    };

    return (
        <ServiceRatingStyle>
            <div className='landing-header'>
                <div className='header'>
                    <h3 className='title'>รีวิวจากผู้ใช้บริการ</h3>
                    <div className='star'>
                        <IconConfigWithTheme pageName='service_rating' iconName='star' />
                    </div>
                </div>
                <div className='score'>
                    <div>
                        <IconConfigWithTheme pageName='service_rating' iconName='star' />
                        <span>4.9</span>
                    </div>
                    <div>429 Ratings</div>
                </div>
            </div>
            <div className='rating'>
                <Slider ref={slider} {...sliderConfig} className='slider'>
                    {children}
                </Slider>
                <div className='slider-goto'>
                    <div className={slideIndex === 0 ? 'arrows' : 'arrows active'} onClick={() => onNext(-1)}>
                        <IconConfigWithTheme pageName='service_rating' iconName='reverse' />
                    </div>
                    <div className='pagination'>
                        {slideIndex + 1} / {maxSlide}
                    </div>
                    <div className={slideIndex + 1 === maxSlide ? 'arrows' : 'arrows active'} onClick={() => onNext(1)}>
                        <IconConfigWithTheme pageName='service_rating' iconName='next' />
                    </div>
                </div>
            </div>
        </ServiceRatingStyle>
    );
}

export default ServiceRating;
