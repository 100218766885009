import React, { useEffect, useState } from 'react';
import EXPCollapse from './exp-collapse';
import styled from '@emotion/styled';
import { extendesClassname } from 'helpers';
import IconConfigWithTheme from '../icon-config-with-theme';

const Style = styled.div`
    position: relative;
    padding: 1px;

    .exp-collapse-complete-title {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.234rem;
        font-weight: 500;
        color: var(--primary-color);
        padding-right: 4px;
        z-index: 3;
        cursor: pointer;

        .fa-chevron-up {
            font-size: 80%;
        }
    }

    .exp-collapse-complete-up-down-icon {
        position: relative;
        display: inline-block;
        width: calc((1.234rem * 70) / 100);
        height: calc((1.234rem * 70) / 100);
        border: 3px solid transparent;
        border-top-color: var(--primary-color);
        border-left-color: var(--primary-color);
        border-radius: 2px;
        transform: rotate(45deg);

        &.icon-rotate-180 {
            transform: rotate(225deg);
        }
    }
`;

/**
 *
 * @param {{
 * defaultShow: boolean,
 * title?: String | Element,
 * classNameTitle?: String,
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */

function EXPCollapseComplete({ defaultShow = false, title, classNameTitle, children, ...props }) {
    const [show, setshow] = useState(false);

    useEffect(() => {
        setshow(defaultShow);
    }, [defaultShow]);

    return (
        <Style aria-expanded={show} {...props}>
            <div {...extendesClassname(['exp-collapse-complete-title', classNameTitle])} onClick={() => setshow(!show)}>
                <div>{title}</div>
                <IconConfigWithTheme
                    pageName='collapse_complete'
                    iconName='show_more'
                    className={`${show ? 'fa-rotate-0' : 'fa-rotate-180'}`}
                />
            </div>
            <EXPCollapse show={show}>{children}</EXPCollapse>
        </Style>
    );
}

export default EXPCollapseComplete;
