import { css } from '@emotion/react';
import { atFor } from './css-function';

const SPACE_SIZE = 1;

export const utilityStyle = css`
    /* cursor */
    .cursor {
        &-pointer {
            cursor: pointer;
        }
        &-default {
            cursor: default;
        }
    }

    /* scrollbar */
    .scrollbar {
        overflow: auto;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #e2e2e2;
        }
    }

    /* font weight */
    .fw {
        ${atFor('font-weight', 1, 9, 100, '')}
    }
    /* min-width */
    .miw {
        ${atFor('min-width', 1, 24, 25, 'px')}

        &-auto {
            min-width: auto !important;
        }
    }
    /*  max-width */
    .maw {
        ${atFor('max-width', 1, 24, 25, 'px')}

        &-auto {
            max-width: auto !important;
        }
    }
    /*  min-height */
    .mih {
        ${atFor('min-height', 1, 24, 25, 'px')}

        &-auto {
            min-height: auto !important;
        }
    }
    /*  max-height */
    .mah {
        ${atFor('max-height', 1, 24, 25, 'px')}

        &-auto {
            max-height: auto !important;
        }
    }
    /* text align */
    .text-align {
        &-center {
            text-align: center;
            justify-content: center;
        }
        &-left {
            text-align: left;
            justify-content: flex-start;
        }
        &-rigth {
            text-align: right;
            justify-content: flex-end;
        }
        &-middle {
            vertical-align: middle;
            align-items: center;
        }
    }

    /* text space */
    .text-space {
        &-1 {
            margin-right: calc(${SPACE_SIZE} * 0.25rem);
        }
        &-2 {
            margin-right: calc(${SPACE_SIZE} * 0.5rem);
        }
        &-3 {
            margin-right: ${SPACE_SIZE}rem;
        }
        &-4 {
            margin-right: calc(${SPACE_SIZE} * 1.5rem);
        }
        &-5 {
            margin-right: calc(${SPACE_SIZE} * 3rem);
        }
    }

    /* text underline */
    .text-underline {
        text-decoration: underline;
    }

    /* list style */
    .list-style {
        &-dash {
            &::before {
                content: '-';
                display: inline-block;
                padding-right: 0.5rem;
            }
        }
        &-circle {
            &::before {
                content: '○';
                display: inline-block;
                padding-right: 0.5rem;
            }
        }
        &-bullet {
            &::before {
                content: '●';
                display: inline-block;
                padding-right: 0.5rem;
            }
        }
        &-square {
            &::before {
                content: '□';
                display: inline-block;
                vertical-align: middle;
                padding-right: 0.5rem;
            }
        }
        &-bath {
            &::before {
                content: '฿';
                display: inline-block;
                padding-right: 0.5rem;
            }
        }
        &-bracket {
            &::before {
                content: '(';
                display: inline-block;
                padding-right: 0.5rem;
            }
            &::after {
                content: ')';
                display: inline-block;
                padding-left: 0.5rem;
            }
        }
    }

    /* font size */
    .text {
        &-nomal {
            font-size: 1rem;
        }
        &-small {
            font-size: 0.8rem;
        }
        &-large {
            font-size: 1.2rem;
        }
    }
`;
