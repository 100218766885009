import { css } from '@emotion/react';

export const input = css`
    // input
    input {
        /* type file */
        &[type='file'] {
            display: none;
        }

        /* type numbre Firefox */
        &[type='number'] {
            -moz-appearance: textfield;
        }

        /* type numbre Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            border-color: var(--input-border-focus);
            box-shadow: unset !important;
        }

        &:-internal-autofill-selected {
            transition: background-color 5000s;
            background-color: transparent !important;
        }

        &:-webkit-autofill {
            transition: background-color 5000s;
            background-color: transparent !important;
        }
        &::placeholder {
            color: var(--text-normal-5) !important;
        }
    }

    // custom style
    .input-radio-type1 {
        .exp-form-check {
            input[type='radio'] {
                display: none;
                & + label {
                    &::before {
                        content: '';
                        position: absolute;
                        top: calc(50% - 0.6rem);
                        left: 10px;
                        display: block;
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: 100%;
                        border: 1px solid var(--text-normal-4);
                        transition: all 0.2s ease;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: calc(50% - 0.4rem);
                        left: calc(10px + 0.2rem);
                        display: block;
                        width: 0.8rem;
                        height: 0.8rem;
                        border-radius: 100%;
                        background-color: var(--second-color);
                        transition: all 0.2s ease;
                    }
                }
                &:checked + label {
                    color: var(--second-color);
                    border: 1px solid var(--second-color);
                    background-color: var(--input-radio-type1-background-color);
                    &::before {
                        border-color: var(--second-color);
                    }
                    &::after {
                        transform: scale(1);
                    }
                }
                &:not(:checked) + label::after {
                    transform: scale(0);
                }
            }

            label {
                position: relative;
                width: 100%;
                padding: 0.475rem 10px 0.475rem calc(16px + 1.2rem);
                border-radius: 8px;
                border: 1px solid var(--text-normal-4);
                background: #fff;
            }
        }
    }
    .input-radio-type2 {
        .form-label,
        .exp-form-label {
            color: var(--primary-color) !important;
            font-size: 1rem;
        }
        .form-row,
        .exp-form-row {
            margin: 0;
            /* .-custom-col { */

            .exp-form-check {
                input[type='radio'] {
                    display: none;
                    + label {
                        width: 100%;
                        text-align: center;
                        vertical-align: middle;
                        padding: 0.575rem 10px;
                        border-top: 2px solid var(--second-color);
                        // border-left: 2px solid var(--second-color);
                        border-bottom: 2px solid var(--second-color);
                        background-color: var(--input-background-color);
                    }
                    &:checked + label {
                        color: var(--white);
                        background-color: var(--second-color);
                    }
                }

                &:nth-last-of-type(n + 1) {
                    padding-left: 0;
                }
                &:nth-last-of-type(n - 1) {
                    padding-right: 0;
                }
                &:last-of-type {
                    border-right: none;
                }

                &:first-of-type input[type='radio'] + label {
                    border-top-left-radius: 0.575rem;
                    border-bottom-left-radius: 0.575rem;
                }

                &:last-child input[type='radio'] + label {
                    border-top-right-radius: 0.575rem;
                    border-bottom-right-radius: 0.575rem;
                    border-right: 2px solid var(--second-color);
                }

                &:nth-last-of-type(n - 1) input[type='radio'] + label {
                    border-left: 2px solid var(--second-color);
                }
            }
        }
    }
    .input-radio-type3 {
        .exp-form-check {
            padding-left: 0.25rem;
            input[type='radio'] {
                display: none;
                & + label {
                    &::before {
                        content: '';
                        position: absolute;
                        top: calc(50% - 0.5rem);
                        left: 10px;
                        display: block;
                        width: 1rem;
                        height: 1rem;
                        border-radius: 100%;
                        border: 1px solid var(--text-normal-4);
                        transition: all 0.2s ease;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: calc(50% - 0.5rem);
                        left: 10px;
                        display: block;
                        width: 1rem;
                        height: 1rem;
                        border-radius: 100%;
                        background-color: var(--second-color);
                        transition: all 0.2s ease;
                    }
                }
                &:checked + label {
                    color: var(--second-color);
                    &::before {
                        border-color: var(--second-color);
                    }
                    &::after {
                        transform: scale(1);
                    }
                }
                &:not(:checked) + label::after {
                    transform: scale(0);
                }
            }

            label {
                position: relative;
                width: 100%;
                padding: 5px 10px 5px calc(15px + 1.2rem);
                border-radius: 8px;
            }
        }
    }
    .input-radio-type4 {
        .exp-form-check {
            label {
                position: relative;
                width: 100%;
                padding: 8px 10px;
                border-radius: 4px;
                border: 1px solid var(--second-color);
                background-color: var(--white);
                cursor: pointer;

                i {
                    color: var(--second-color);
                }
            }

            input[type='radio'] {
                display: none;

                &:checked + label {
                    color: var(--white);
                    background-color: var(--second-color);

                    i {
                        color: var(--white);
                    }
                }

                &:disabled + label {
                    cursor: default;
                }
            }
        }
    }
    .input-radio-type5 {
        .exp-form-check {
            label {
                position: relative;
                width: 100%;
                padding: 8px 10px;
                border-radius: 4px;
                border: 2px solid var(--input-border-color);
                cursor: pointer;
            }

            input[type='radio'] {
                display: none;

                &:checked + label {
                    color: var(--white);
                    border-color: var(--second-color);

                    i {
                        color: var(--white);
                    }
                }
            }
        }
    }

    .input-checkbox-type1 {
        .exp-form-check {
            input[type='checkbox'] {
                display: none;
                & + label {
                    position: relative;
                    min-height: 1em;
                    padding-left: 1.5em;
                    cursor: pointer;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0.125em;
                        left: 0;
                        display: inline-block;
                        width: 1em;
                        height: 1em;
                        border-radius: 3px;
                        border: solid 1px var(--primary-color);
                        margin-right: 0.5rem;
                        background-color: var(--white);
                        transition: all 0.2s ease;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0.275em;
                        left: 0.125em;
                        display: block;
                        width: 0.8em;
                        height: 0.5em;
                        border-radius: 0.125em;
                        border-left: 0.225em solid var(--white);
                        border-bottom: 0.225em solid var(--white);
                        transition: all 0.2s ease;
                    }
                }
                &:checked + label {
                    &::before {
                        border: solid 1px var(--primary-color);
                        background-color: var(--primary-color);
                    }
                    &::after {
                        transform: scale(1) rotate(-45deg);
                    }
                }
                &:not(:checked) + label::after {
                    transform: scale(0) rotate(-45deg);
                }
            }
        }
    }

    .input-checkbox-type2,
    .input-checkbox-type2.exp-form-group {
        .exp-form-check {
            &.col-auto {
                padding-right: 0;
            }
            label {
                min-width: 100px;
                color: var(--input-checkbox-type2-bubble-text-color-inactive);
                font-size: 1rem;
                font-family: var(--font-detail2);
                text-align: center;
                padding: 0.275rem 1rem;
                border-radius: 1.275rem;
                border: solid 1px var(--input-checkbox-type2-bubble-border-color-inactive);
                background-color: var(--input-checkbox-type2-bubble-background-color-inactive);
                cursor: pointer;
            }

            input[type='checkbox'] {
                display: none;

                &:checked + label {
                    color: var(--input-checkbox-type2-bubble-text-color-active);
                    border-color: var(--input-checkbox-type2-bubble-border-color-active);
                    background-color: var(--input-checkbox-type2-bubble-background-color-active);

                    &::after {
                        content: '\\f00d';
                        display: inline-block;
                        color: var(--input-checkbox-type2-bubble-remove-icon-color);
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 400;
                        font-size: 1rem;
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        font-style: normal;
                        font-feature-settings: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        line-height: 1;
                        margin-left: 0.675rem;
                    }
                }
            }
        }
    }

    .input-file-type1 {
        width: 100px;
        color: var(--second-color);
        margin-top: 0.5rem;
        margin-right: 10px;
        // background-color: var(--second-color);
        input ~ label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100px;
            height: 100px;
            text-align: center;
            cursor: pointer;
            border-radius: 10px;
            border: 2px dashed var(--second-color);

            i {
                font-size: 1.5rem;
            }
        }
    }

    /* valid */
    .was-validated .input-checkbox-type2 .form-check-input:valid ~ .form-check-label {
        color: var(--input-checkbox-type2-bubble-text-color-active);
        border-color: var(--input-checkbox-type2-bubble-border-color-active);
    }
    .was-validated .input-checkbox-type2 .form-check-input:valid:checked ~ .form-check-label {
        border-color: #e2eff0;
    }

    /* invalid */
    .exp-was-validated .input-file-type1 fieldset:invalid label,
    .exp-was-validated .input-file-type1:invalid label {
        color: var(--input-invalid-color);
        border-color: var(--input-invalid-border-color);
    }
`;
