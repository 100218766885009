import styled from '@emotion/styled';
import React from 'react';
import { breakpoint } from 'helpers';

const Section = styled.section`
    label: section;

    position: relative;
    width: 100%;
    min-height: var(--section-min-height);
    padding-top: var(--section-padding-top);
    background-color: var(--section-background-color);
    z-index: var(--section-zindex);

    ${breakpoint('XS')} {
        border-top-left-radius: var(--section-border-top-radius);
        border-top-right-radius: var(--section-border-top-radius);
    }
`;

export default Section;
