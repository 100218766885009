import { extendesClassname } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @version 1.0.1
 * @param {{
 * className?: string,
 * children?: element
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */

function Row({ children, className, ...props }) {
    return (
        <div {...extendesClassname(['row', className])} {...props}>
            {children}
        </div>
    );
}

Row.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Row;
