import { css } from '@emotion/react';
import { breakpoint } from 'helpers';

export const root = css`
    html {
        /* page layout padding */
        --page-layout-padding-x: 24px;
        --page-layout-padding-y: 24px;
        --page-layout-padding: var(--page-layout-padding-y) var(--page-layout-padding-x);
        /* panel */
        --panel-padding-x: var(--page-layout-padding-x);
        --panel-padding-y: var(--page-layout-padding-y);
        --panel-padding: var(--page-layout-padding-y) var(--page-layout-padding-x);
        --panel-border-radius: 24px;

        /* navbar */
        --navbar-height: 75px;
        /* breadcrumbs */
        --breadcrumbs-height: 85px;

        ${breakpoint('LG')} {
            --navbar-height: 50px;
        }

        ${breakpoint('XS')} {
            --breadcrumbs-height: 50px;
        }

        /* main */
        main {
            --main-min-height: calc(var(--body-height) - var(--navbar-height));
            /* section */
            --section-padding-top: 45px;
            --section-border-top-radius: 30px;
            --section-background-color: var(--white);
            --section-min-height: calc(var(--main-min-height) - var(--breadcrumbs-height));
            /* form panel */
            --form-panel-padding-x: var(--page-layout-padding-x);
            --form-panel-padding-y: var(--page-layout-padding-y);
            --form-panel-margin-bottom: 30px;
            --form-panel-min-height: 450px;
            /* form input panel */
            --form-input-panel-max-width: 500px;
            /* form footer panel */
            --form-footer-panel-height: 75px;
            --form-footer-panel-padding-x: var(--page-layout-padding-x);
            --form-footer-panel-padding-y: 0;
            --form-footer-panel-button-max-width: 350px;
            /* form footer panel sticky */
            --form-footer-panel-sticky-border-top-radius: 12px;
            --form-footer-panel-sticky-background-color: var(--white);

            ${breakpoint('XS')} {
                --section-padding-top: 25px;
                --form-panel-margin-bottom: 0px;
                --form-panel-min-height: calc(
                    var(--section-min-height) - var(--section-padding-top) - var(--progressbar-with-theme-height) -
                        var(--form-panel-margin-bottom)
                );
            }
        }

        /* //! **for no header, overide */
        & :not(header) + main {
            --main-min-height: var(--body-height);
            --section-min-height: calc(var(--main-min-height) - var(--breadcrumbs-height));
            --form-panel-min-height: calc(
                var(--section-min-height) - var(--section-padding-top) - var(--progressbar-with-theme-height) -
                    var(--form-panel-margin-bottom)
            );
        }

        /* z-index */
        /* over page layout */
        --page-loading-zindex: 1000;
        --popup-zindex: 999;
        --liftup-zindex: 997;
        --liftup-consent-zindex: 1000;
        --api-loading-overlay-zindex: 99;
        /* page lay out */
        --sidebar-zindex: 64;
        --sidebar-overlay-zindex: 62;
        --navbar-zindex: 60;
        --main-zindex: 2;
        /* in page layout */
        --cookie-consent-zindex: 99;
        --sticky-panel-zindex: 50;
        --form-footer-panel-sticky-zindex: 50;
        --autocomplete-zindex: 40;
        --navigation-zindex: 20;
        --cta-zindex: 10;
        --delivery-fee-zindex: 10;
        --loading-join-call-zindex: 10;
        --section-zindex: 8;
        --breadcrumbs-zindex: 6;
        --panel-zindex: 1;

        --gray-2: #707070;
    }
`;
