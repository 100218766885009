import React from 'react';
import styled from '@emotion/styled';
import Navbar from './navbar';
import Sidebar from './sidebar';
import WorkplazeThemeHideElement from 'components/elements/workplaze-theme-hide-element';

const Main = styled.main`
    label: main-layout;

    position: relative;
    width: 100%;
    min-height: var(--main-min-height);
    z-index: var(--main-zindex);
`;

const Header = styled.header`
    label: header;

    position: relative;
    width: 100%;
`;

function Layout({ showHeader = true, requireInProgress = false, requireMobileLanguage = false, children }) {
    return (
        <>
            <WorkplazeThemeHideElement>
                {showHeader && (
                    <Header>
                        <Navbar hasInProgress={requireInProgress} mobileLanguage={requireMobileLanguage} />
                        <Sidebar hasInProgress={requireInProgress} />
                    </Header>
                )}
            </WorkplazeThemeHideElement>
            <Main>{children}</Main>
        </>
    );
}

export default Layout;
