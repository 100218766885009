import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/button';
import ServiceDetail from 'modules/page/landing/ServiceDetail';
import { cryptojs } from 'helpers';
import moment from 'moment';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useAuth from 'hooks/useAuth';
import type { DataEncodeURLProps } from 'types';
import ServiceSymstom from 'modules/page/landing/ServiceSymstom';
import ServiceMethod from 'modules/page/landing/ServiceMethod';
import ServicePharmacyNearby from 'modules/page/landing/ServicePharmacyNearby';
import ServiceRating from 'modules/page/landing/ServiceRating';
import ServiceQeustionAndAnswer, { QeustionBox } from 'modules/page/landing/ServiceQnA';
import ServiceRelate from 'modules/page/landing/ServiceRelate';
import LandingLogin from 'modules/page/landing/LandingLogin';

function PTServiceLanding() {
    const navigate = useNavigate();
    const { auth } = useAuth();

    const timer = useRef<NodeJS.Timeout>();
    const [showMethod, setShowMethod] = useState(1);

    const params = useMemo(() => {
        const origin = window.location.origin;
        return origin.includes('fastcare.co') ? '?voucher=fastcare01' : '';
    }, [window.location.origin]);

    const onNextPage = () => {
        let path = process.env.REACT_APP_LIFF_REDIRECT_DEFAULT;
        let dataForEncode: DataEncodeURLProps = {
            path: '/pt/claim',
            params: window.location.search || '?fromService=true',
            data: {
                authorization: null,
                userId: null,
                autoLogin: 0,
            },
        };
        if (window.location.origin.includes('fastcare.co')) {
            path = process.env.REACT_APP_LIFF_REDIRECT_FASTCARE;
            dataForEncode = {
                ...dataForEncode,
                params: window.location.search || '?voucher=fastcare01&fromService=true',
                data: {
                    authorization: null,
                    userId: null,
                    autoLogin: 0,
                    partner: '18',
                },
            };
        }
        const encode = cryptojs.encode(dataForEncode);
        if (auth.isAuth) {
            return navigate(dataForEncode.path + dataForEncode.params);
        }
        return window.location.assign(path + '?to=' + encode);
    };

    const onChangeMethod = (method) => {
        let _method = method;

        clearInterval(timer.current);
        setShowMethod(_method);

        timer.current = setInterval(() => {
            _method++;

            if (_method < 4) {
                setShowMethod(_method);
            } else {
                setShowMethod(1);
                _method = 1;
            }
        }, 5000);
    };

    useEffect(() => {
        onChangeMethod(1);

        return () => clearInterval(timer.current);
    }, []);

    return (
        <>
            <ServiceDetail primaryBgc='#4f9ecb' secondBgc='#f9f9fa'>
                <div className='landing-service'>
                    <div className='service-panel service-content pb-0'>
                        <div className='title'>บริการ</div>
                        <h1 className='sub-title'>ปรึกษานักกายภาพบำบัด</h1>
                        <h4 className='detail'>
                            <div>ให้คำแนะนำและประเมินอาการโดย </div>
                            <div>นักกายภาพบำบัด</div>
                        </h4>
                        <div className='service-image mb-3'>
                            <img src='/images/service-landing/service-pt.webp' className='desktop' alt='desktop' />
                            <img src='/images/service-landing/service-pt.webp' className='mobile' alt='mobile' />
                        </div>
                    </div>
                    <div className='service-panel service-detail'>
                        <h3 className='title'>รายละเอียดบริการ</h3>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_pt' iconName='sevice_detail_time' />
                            </div>
                            <h4>ให้บริการ 10.00-20.00 น. ทุกวัน</h4>
                        </div>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_pt' iconName='sevice_detail' />
                            </div>
                            <h4>ประเมินอาการ ให้คำแนะนำท่าออกกำลังกายเพื่อรักษา และเรื่องการเปลี่ยนพฤติกรรม</h4>
                        </div>

                        <h3 className='title'>อัตราค่าบริการ</h3>
                        <div className='sevice-rate-panel'>
                            <div className='sevice-rate'>
                                <div className='list'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_pt' iconName='sevice_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่าปรึกษา</h3>
                                        <h4 className='list-detail'>
                                            <span>390 บาท (30 นาที)</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='panel-footer'>
                                <Button style={{ backgroundColor: '#4f9ecb', color: 'var(--white)' }} onClick={() => onNextPage()}>
                                    ปรึกษานักกายภาพบำบัด
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='consult-now'>
                    <div className='custom-btn'>
                        <Button style={{ backgroundColor: '#4f9ecb', color: 'var(--white)' }} onClick={() => onNextPage()}>
                            ปรึกษานักกายภาพบำบัด
                        </Button>
                    </div>
                </div>
            </ServiceDetail>
            <ServiceSymstom primaryBgc='#e7eff4' secondBgc='#f9f9fa' imageBgc='blue'>
                <div className='landing-panel'>
                    <div className='landing-icon'>
                        <img src='/images/service-landing/pharmcare-short-logo.svg' alt='pharmcare-icon' />
                    </div>
                    <h2>
                        <div className='title'>ลักษณะอาการ</div>
                        <div className='sub-title'>ที่นักกายภาพบำบัดให้คำปรึกษา</div>
                    </h2>
                    <div className='landing-content'>
                        <div className='landing-image'>
                            <img src='/images/service-landing/symptoms-pt.webp' alt='symptoms' />
                        </div>
                        <div className='symptoms'>
                            <div className='symptoms-about'>อาการเกี่ยวกับ...</div>
                            <ul className='symptoms-list'>
                                <li>
                                    <h4>Office Syndrome</h4>
                                </li>
                                <li>
                                    <h4>ปวดคอ</h4>
                                </li>
                                <li>
                                    <h4>Long Covid</h4>
                                </li>
                                <li>
                                    <h4>หลังผ่าตัด</h4>
                                </li>
                                <li>
                                    <h4>ปวดหลัง</h4>
                                </li>
                                <li>
                                    <h4>ปวดบ่า ไหล่</h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ServiceSymstom>
            <ServiceMethod secondBgc='#f9f9fa'>
                <div className='landing-panel'>
                    <h3 className='title'>ขั้นตอนในการรับบริการ</h3>
                    <div className='method'>
                        <div className='method-row mark-1'>
                            <div className='method-row mark-2'>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 1 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(1)}
                                    >
                                        <div className='circle'>1</div>
                                        <h3>ปรึกษานักกายภาพบำบัด</h3>
                                    </div>
                                </div>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 2 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(2)}
                                    >
                                        <div className='circle'>2</div>
                                        <h3>รับคำแนะนำ</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='method-row mark-3 line-center'>
                                <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-pt-1.webp' alt='method-pt-1' />
                                    </div>
                                </div>
                                <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-pt-2.webp' alt='method-pt-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='method-row'>
                            <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>1. ปรึกษานักกายภาพบำบัด</div>
                                    </div>
                                    <p>กรอกข้อมูลสุขภาพ อาการของคุณ และเริ่มต้นสนทนากับนักกายภาพบำบัด ภายใน 5 นาที</p>
                                </div>
                            </div>
                            <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>2. รับคำแนะนำ</div>
                                    </div>
                                    <p>รับคำแนะนำจากนักกายภาพบำบัด</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ServiceMethod>
            <ServiceRating>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            หลังจากทำตามคำแนะนำของน้อง PT อาการปวดหลังก็ดีขึ้นมากค่ะ ไม่ค่อยต้องใช้ยาแก้ปวดแล้วค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_pt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>แป้ง</div>
                            <div className='rating-date'>20 Jan 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ช่วง WFH ทำให้มีอาการปวดบ่า และหลังเรื้อรัง เครียดมาก แต่พอได้บริหารหลังตามคำแนะนำทุกวัน
                            รู้สึกว่านั่งทำงานได้นานขึ้น อาการปวดก็น้อยลง ขอบคุณมากค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_pt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>กีรติ</div>
                            <div className='rating-date'>10 Feb 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            หลังผ่าตัดมา ไม่สะดวกเดินทางไปกายภาพ จึงลองใช้บริการปรึกษานักกายภาพแบบวีดีโอคอล ตอบโจทย์มากจริงๆครับ
                            ไม่ต้องรบกวนใคร สามารถทำเองได้ที่บ้าน ตอนนี้อาการดีขึ้นจนเกือบเป็นปกติแล้วครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_pt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>เมย์</div>
                            <div className='rating-date'>21 Feb 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            หลังจากเป็นโควิด ก็กังวลกับอาการเหนื่อยง่าย แต่พอได้ปรึกษากับนักกายภาพที่นี่
                            แล้วทำตามก็รู้สึกกังวลน้อยลงและอาการก็ดีขึ้นครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_pt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ชาคริยา</div>
                            <div className='rating-date'>10 Mar 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ทานยาแก้ปวดจนเป็นกระเพาะ อาการปวดคอก็ไม่ดีขึ้น จนมาปรึกษาพี่นักกายภาพที่ดี ก็ได้คำแนะนำและท่ากายภาพง่าย ๆไปทำ
                            ตอนนี้แทบไม่ต้องใช้ยาแก้ปวดแล้วค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_pt' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ญาตา</div>
                            <div className='rating-date'>26 May 2022</div>
                        </div>
                    </div>
                </div>
            </ServiceRating>
            <ServiceQeustionAndAnswer>
                <div className='landing-panel'>
                    <h2 className='title'>คำถามที่พบบ่อย</h2>
                    <div className='landing-content'>
                        <div className='landing-image'>
                            <img src='/images/service-landing/question.webp' alt='question' />
                        </div>
                        <div className='question-n-answer'>
                            <QeustionBox question={'1. มีบริการในจังหวัดใดบ้าง'}>
                                เปิดให้บริการทุกจังหวัด เป็นการให้คำปรึกษาโดยนักกายภาพบำบัด ผ่านระบบวีดีโอคอล
                            </QeustionBox>
                            <QeustionBox question={'2. รูปแบบบริการจะเป็นประมาณไหน'}>
                                รูปแบบบริการจะเป็นการปรึกษานักกายภาพผ่านวีดีโอคอล คนไข้สามารถปรึกษาอาการให้นักกายภาพแนะนำท่าออกกำลังกายและ
                                การเปลี่ยนพฤติกรรม
                            </QeustionBox>
                            <QeustionBox
                                question={'3. การให้บริการคำปรึกษาแนะนำ เช่น ท่าออกกำลังกาย จะแจ้งรายละเอียดให้ผู้ป่วยทราบอย่างไร'}
                            >
                                ค่าปรึกษานักกายภาพบำบัด: 390 บาท ต่อ 30 นาที
                            </QeustionBox>
                            <QeustionBox
                                question={'4. การให้บริการคำปรึกษาแนะนำ เช่น ท่าออกกำลังกาย จะแจ้งรายละเอียดให้ผู้ป่วยทราบอย่างไร'}
                            >
                                จะมีการสาธิตและอธิบายขณะให้คำปรึกษาผ่านระบบวีดีโอคอล
                                ซึ่งผู้รับบริการสามารถฝึกทำตามโดยนักกายภาพจะสามารถสังเกตได้ว่าถูกต้องหรือไม่
                                นอกจากนี้ในบางอาการพื้นฐานทั่วไปจะมีการส่งเอกสารแนะนำแนบไปหลังจบการให้บริการด้วย
                            </QeustionBox>
                            <QeustionBox question={'5. หากประเมินแล้ว อาการไม่เหมาะที่จะรับบริการผ่านนี้ จะต้องทำอย่างไรบ้าง'}>
                                หากนักกายภาพบำบัดประเมินอาการแล้ว
                                ควรต้องมีการตรวจเพิ่มหรือควรใช้เครื่องมือพิเศษบางอย่างเพื่อรักษาจะแนะนำไปให้ไปรับบริการที่คลินิกกายภาพใกล้บ้านที่ท่านสะดวกหรือเหมาะสมแทน
                            </QeustionBox>
                        </div>
                    </div>
                </div>
            </ServiceQeustionAndAnswer>
            <ServiceRelate serviceList={['telepharmacy', 'telemed']} />
            <LandingLogin onNextPath={'/pt/claim'} params={params} />
        </>
    );
}

export default PTServiceLanding;
