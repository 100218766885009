import React, { useEffect, useState } from 'react';
import Button from 'components/common/button';
import { locationjs, numberjs } from 'helpers';
import { POST } from 'api';
import { useNavigate } from 'react-router-dom';
import { IMAGES_CONFIG_WITH_THEME } from 'constants/images-config';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { ServicePharmacyNearbyStyle } from './Landing.styled';
import { DEFAULT_LATLNG } from 'constants/default-config';
import { browserName, isAndroid, isIOS, isMobile } from 'react-device-detect';

interface PharmacyData {
    pharmacyBranchNumber: string;
    pharmacyStoreName: string;
    address: string;
    pharmacyStoreLatitude: number;
    pharmacyStoreLongitude: number;
    radiusKm: number;
    open: string;
    close: string;
    qualityType: string;
    pharmacyImgUrl: string;
    deliveryFlag: 0 | 1;
    pharmacyFavouriteFlag: 0 | 1;
    page: number;
    ratingAvgScore: number;
    ratingCounts: number;
}
export default function ServicePharmacyNearby({ serviceLink }) {
    const navigate = useNavigate();
    const theme = useThemeWithPartner();
    const [pharmacyList, setPharmacyList] = useState<PharmacyData[]>([]);

    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = event.currentTarget;
        target.onerror = null; // Prevent infinite loop if fallback fails
        target.src =
            IMAGES_CONFIG_WITH_THEME['store']['store_detail'][theme.name] || IMAGES_CONFIG_WITH_THEME['store']['store_detail']['default']; // Set the fallback image
    };

    useEffect(() => {
        // clean up controller
        let isSubscribed = true;

        const getPharmacyNearby = async () => {
            try {
                let latlng = DEFAULT_LATLNG;

                if (!(isMobile && (browserName === 'Line' || browserName === 'Chrome WebView'))) {
                    latlng = await locationjs.getCurrentLocation();
                }

                const res = await POST('/location/getPharmacy-optimize/v2', {
                    latitude: latlng.latitude,
                    longitude: latlng.longitude,
                    companyCode: '00',
                    pharmacyFilterCriteria: {
                        minRatingScore: 0,
                        pharmcareProgram: ['VIDEO_CALL'],
                    },
                });
                if (isSubscribed) setPharmacyList(res.data.pharmacyStores);
            } catch (error) {
                console.error('api error: /location/getPharmacy-optimize/v2', error);
            }
        };

        getPharmacyNearby();

        // cancel subscription to useEffect
        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <ServicePharmacyNearbyStyle>
            <div className='landing-panel'>
                <div className='landing-image'>
                    <img src='/images/service-landing/delivery.webp' title='ซื้อยา บริการจัดส่งถึงบ้าน' alt='ซื้อยา บริการจัดส่งถึงบ้าน' />
                </div>
                <div className='pharmacy-nearby'>
                    <div className='pharmacy-panel'>
                        <h3 className='title'>ร้านยาคุณภาพใกล้คุณ</h3>
                        <div className='pharmacy-list'>
                            {pharmacyList.map((pharmacy, index) => (
                                <div key={index} className='list'>
                                    <div className='circle'>
                                        <img
                                            src={
                                                pharmacy.pharmacyImgUrl ??
                                                IMAGES_CONFIG_WITH_THEME['store']['store_detail'][theme.name] ??
                                                IMAGES_CONFIG_WITH_THEME['store']['store_detail']['default']
                                            }
                                            alt='ร้านขายยาใกล้ฉัน'
                                            title='ร้านขายยาใกล้ฉัน'
                                            onError={handleImageError}
                                        />
                                    </div>
                                    <div className='name'>{pharmacy.pharmacyStoreName}</div>
                                    <div className='radius'>{numberjs(pharmacy.radiusKm).format('double', 2)} km</div>
                                </div>
                            ))}
                        </div>
                        <div className='pharmacy-footer'>
                            <Button variant='info' onClick={() => window.location.assign(serviceLink)}>
                                PharmCare เลือกร้านยาให้
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </ServicePharmacyNearbyStyle>
    );
}
