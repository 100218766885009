import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useQueryParams() {
    const location = useLocation();

    const queryToParams = (query: { [key: string]: string | null | undefined } = {}) => {
        let params = '';
        Object.keys(query).forEach((key) => {
            if (!query[key]) {
                // return;
            } else if (params) {
                params += `&${key}=${query[key]}`;
            } else {
                params += `?${key}=${query[key]}`;
            }
        });

        return params;
    };

    const paramsToQuery = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        const queryParams: any = {};

        for (const [key, value] of searchParams.entries()) {
            queryParams[key] = value;
        }

        return queryParams;
    }, [location]);

    return { query: paramsToQuery, queryToParams: queryToParams };
}

export default useQueryParams;
