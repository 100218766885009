import { POST } from 'api';
import { getApiCallTypeWithTheme } from 'api/api-often';
import Button from 'components/common/button';
import EXPPopupJs from 'components/common/exp-popupjs';
import { Field, FormField } from 'components/common/exp-form';
import useAuth from 'hooks/useAuth';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {{
 * open: boolean
 * showClose?: boolean
 * isClickOutSideClose?: boolean
 * serviceOrigin: string
 * taskDetail: any
 * onClose?: ()=> void
 * onSubmited: ()=> void
 * }} props
 * @returns
 */
function ClaimCancelTask({
    open = false,
    serviceOrigin = '',
    taskDetail = { taskId: '', processCode: 0 },
    onClose = () => null,
    onSubmited = () => null,
    showClose = true,
    isClickOutSideClose = true,
}) {
    const { t, i18n } = useTranslation();
    const { profile } = useAuth();
    const theme = useThemeWithPartner();
    // state
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [rejectCode, setRejectCode] = React.useState('1');
    // constant
    const reasonCancelList = React.useMemo(() => {
        return [
            {
                label: t('claim:cancel_task.reason.1_wait_too_long', { serviceOrigin: serviceOrigin }),
                value: '1',
                xs: 12,
                sm: 12,
            },
            {
                label: t('claim:cancel_task.reason.2_inconvenient_video_call'),
                value: '2',
                xs: 12,
                sm: 12,
            },
            {
                label: t('claim:cancel_task.reason.3_accidentally_received_service'),
                value: '3',
                xs: 12,
                sm: 12,
            },
            {
                label: t('claim:cancel_task.reason.4_received_service_another'),
                value: '4',
                xs: 12,
                sm: 12,
            },
            {
                label: t('claim:cancel_task.reason.9_other'),
                value: '9',
                xs: 12,
                sm: 12,
            },
        ];
    }, [serviceOrigin, i18n.language]);

    const showPopup = React.useMemo(() => open, [open]);

    const onSubmit = async () => {
        setIsSubmitting(true);

        const apiCallType = getApiCallTypeWithTheme(theme.name);

        const { label } = reasonCancelList.find((f) => rejectCode === f.value);
        try {
            const res = await POST('/service/taskUpdate', {
                pid: profile.userPid,
                taskId: taskDetail.taskId,
                processCode: taskDetail.processCode,
                rejectCode: rejectCode,
                rejectReason: label,
                apiCallType: apiCallType,
            });
            setIsSubmitting(false);
            onSubmited(res);
        } catch (error) {
            console.error('cancel task --> api:/service/taskUpdate --> error: ', error);
            setIsSubmitting(false);
            onClose();
            if (error.statusCode === 400) {
                EXPPopupJs.simple('warning', t('common:error'), error.rejectReason);
            }
        }
    };

    return (
        <EXPPopupJs.Jsx
            show={showPopup}
            onClose={onClose}
            data-test-id='popup-cancel-task'
            showClose={showClose}
            isClickOutSideClose={isClickOutSideClose}
        >
            <div className='exp-popupjs-title text-primary'>{t('claim:cancel_task.please_indicat_reason')}</div>
            <FormField onSubmit={onSubmit}>
                <div className='exp-popupjs-body text-primary text-left font-detail2'>
                    <Field.RadioGroup
                        classNameField='input-radio-type3 text-left'
                        type='radio-group'
                        name='processCode'
                        value={rejectCode}
                        radioListGroup={reasonCancelList}
                        onChange={(e) => setRejectCode(e.target.value)}
                        required
                    />
                </div>
                <div className='exp-popupjs-footer'>
                    <Button variant='danger' disabled={rejectCode === null} loading={isSubmitting}>
                        {t('claim:cancel_task.confirm_cancel_service')}
                    </Button>
                </div>
            </FormField>
        </EXPPopupJs.Jsx>
    );
}

export default ClaimCancelTask;
