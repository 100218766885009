import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { extendesClassname, locationjs } from 'helpers';
import IconConfigWithTheme from '../icon-config-with-theme';
import { useTranslation } from 'react-i18next';

/**
 * @version 1.0.1 create
 * @param {{
 * onGetLocation:(latlng:{lat: number, lng: number})=> void
 * } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} props
 * @returns
 */
export default function CurrentLocation({ children, className, onGetLocation = () => null, ...props }) {
    const currentLocationIcon = useRef(null);
    const { i18n } = useTranslation();

    const onCheckCurrent = async () => {
        if (currentLocationIcon.current) currentLocationIcon.current.classList.add('checking');

        try {
            const { isAllowLocation, latitude, longitude } = await locationjs.getCurrentLocation();

            if (currentLocationIcon.current) currentLocationIcon.current.classList.remove('checking');

            if (isAllowLocation) {
                onGetLocation({ lat: latitude, lng: longitude });
            } else {
                alert('Please allow location');
            }
        } catch (error) {
            console.log('get current location error: ', error);
        }
    };

    return (
        <Style
            type='button'
            ref={currentLocationIcon}
            lang={i18n.language.toLowerCase()}
            onClick={onCheckCurrent}
            {...extendesClassname(['current-location', 'spinner', className])}
            {...props}
        >
            <IconConfigWithTheme pageName='current_location' iconName='location_arrow' />
        </Style>
    );
}

const Style = styled.button`
    label: current-location;

    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    color: #fff;
    font-size: 1.2rem;
    border: none;
    border-radius: 100%;
    background-color: var(--second-color);

    &:focus {
        outline: none;
    }
    &.checking {
        &::after {
            position: absolute;
            right: calc(100% + 0.275rem);
            font-size: 0.875rem;
            white-space: nowrap;
            border-radius: 0.275rem;
            padding: 0.275rem;
            background-color: #000;
        }
        &[lang='th']::after {
            content: 'กำลังรับข้อมูลตำแหน่ง';
        }
        &[lang='en']::after {
            content: 'Getting current location';
        }
        &::before {
            content: '';
            position: absolute;
            top: calc(50% - 0.6rem);
            left: calc(50% - 0.6rem);
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
            border: 3px solid #fff;
            border-right-color: transparent;
            animation: spinnerAnimetion 0.75s linear infinite;
        }

        .fas.fa-location-arrow {
            display: none;
        }
    }

    @keyframes spinnerAnimetion {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
