import React, { useState } from 'react';
import { breakpoint } from 'helpers';
import styled from '@emotion/styled';
import HamburgerIcon from './hamburger-icon';
import SideBarContent from './sidebar-content';
import SideBarOverlay from './sidebar-overlay';
import SidebarHeader from './sidebar-header';
import { SidebarWrapper } from './Sidebar.styled';

function Sidebar({ hasInProgress }) {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            <SidebarWrapper className={`sidebar ${showMenu ? 'show' : 'closed'}`}>
                <HamburgerIcon show={showMenu} onHandle={() => setShowMenu(!showMenu)} />
                <SidebarHeader />
                <SideBarContent hasInProgress={hasInProgress} />
            </SidebarWrapper>
            <SideBarOverlay show={showMenu} onClose={() => setShowMenu(!showMenu)} />
        </>
    );
}

export default Sidebar;
