import React from 'react';
import { SERVICE_CONFIG } from 'constants/service-config';
import ClaimSelectV2 from 'pages/task/claim/ClaimSelectV2';
import ClaimPidV2 from 'pages/task/claim/ClaimPidV2';
import ClaimStartV2 from 'pages/task/claim/ClaimStartV2';

// claim
// const Claim = React.lazy(() => import('pages/task/claim').then((module) => ({ default: module.Claim })))
const Claim = React.lazy(() => import('pages/task/claim/ClaimStart'));
const ClaimPid = React.lazy(() => import('pages/task/claim').then((module) => ({ default: module.ClaimPid })));
const ClaimSelect = React.lazy(() => import('pages/task/claim').then((module) => ({ default: module.ClaimSelect })));
// phr existing
const Existing = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.Existing })));
const ExistingPersonalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingPersonalInfo }))
);
const ExistingHealthInfo = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingHealthInfo })));
const ExistingAdditionalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingAdditionalInfo }))
);
// phr new
const PhrNewWhatDisease = React.lazy(() => import('pages/task/phr-new/phr-new-disease'));
const PhrNewDrugAllergy = React.lazy(() => import('pages/task/phr-new/phr-new-drug-allergy'));
const PhrNewDate = React.lazy(() => import('pages/task/phr-new/phr-new-date'));
const PhrNewGender = React.lazy(() => import('pages/task/phr-new/phr-new-gender'));
const PhrNewBody = React.lazy(() => import('pages/task/phr-new/phr-new-body'));
const PhrNewAdditional = React.lazy(() => import('pages/task/phr-new/phr-new-additional'));
// service srv
const SrvMedOrder = React.lazy(() => import('pages/task/srvmed-order/srvmed-order'));
const SrvMedFinish = React.lazy(() => import('pages/task/srvmed-finish/srvmed-finish'));
const SrvFinish = React.lazy(() => import('pages/task/srv-finish/srv-finish'));
const ChoosePharmacyFavourite = React.lazy(() =>
    import('pages/task/choose-pharmacy').then((module) => ({ default: module.ChoosePharmacyFavourite }))
);
const ChoosePharmacyStore = React.lazy(() =>
    import('pages/task/choose-pharmacy').then((module) => ({ default: module.ChoosePharmacyStore }))
);
// lift
// select address
const SelectAddressTLP = React.lazy(() => import('pages/task/select-address').then((module) => ({ default: module.SelectAddressTLP })));
const SelectAddressTLM = React.lazy(() => import('pages/task/select-address').then((module) => ({ default: module.SelectAddressTLM })));
const Location = React.lazy(() => import('pages/task/location/location'));
// med
const MedPendingTLP = React.lazy(() => import('pages/task/pending-med/med-pending-tlp'));
const MedConfirm = React.lazy(() => import('pages/task/confirm-med/med-confirm'));
const MedPendingPharmacistCancel = React.lazy(() => import('pages/task/pending-med/med-pending-pharmacist-cancel'));
// pickup
const Pickup = React.lazy(() => import('pages/task/pickup').then((module) => ({ default: module.Pickup })));
const NoPayment = React.lazy(() => import('pages/task/no-payment/no-payment'));
const Payment = React.lazy(() => import('pages/task/payment/payment'));
const MobileBanking = React.lazy(() => import('pages/task/payment/mobile-banking'));
//new fulfillment
const Diseases = React.lazy(() => import('pages/task/disease'));
const Prescription = React.lazy(() => import('pages/task/prescription'));
const ExistingLab = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLab })));
const ExistingLabDetail = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLabDetail })));
const ExistingPharmacistNoteList = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-list'));
const ExistingPharmacistNoteDetail = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-detail'));

const RejectTaskTLP = React.lazy(() => import('pages/task/reject-task/reject-task-tlp'));
const RecreateTask = React.lazy(() => import('pages/task/recreate-task'));

export const FulfillmentService = [
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: false,
        googleAnalytics: true,
        path: '/fulfillment/claim/select',
        component: ClaimSelectV2,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 30 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: false,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/fulfillment/claim/pid',
        component: ClaimPidV2,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 20 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: false,
        requireRemoteConfig: true,
        requireMobileLanguage: true,
        exact: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/fulfillment/claim',
        component: ClaimStartV2,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 10 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        isLoadPage: true,
        path: '/fulfillment/address',
        component: SelectAddressTLM,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 2, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        path: '/fulfillment/location',
        component: Location,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 2, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/fulfillment/choose-pharmacy-store',
        component: ChoosePharmacyStore,
        pharmcareProgram: ['GATE_WAY'],
        service: SERVICE_CONFIG.fulfillment,
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/fulfillment/choose-pharmacy-favourite',
        component: ChoosePharmacyFavourite,
        service: SERVICE_CONFIG.fulfillment,
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: false,
        exact: true,
        isLoadPage: true,
        path: '/fulfillment/phr/existing',
        component: Existing,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: false,
        isLoadPage: true,
        path: '/fulfillment/phr/existing/personal-info',
        component: ExistingPersonalInfo,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: false,
        isLoadPage: true,
        path: '/fulfillment/phr/existing/health-info',
        component: ExistingHealthInfo,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: false,
        isLoadPage: true,
        path: '/fulfillment/phr/existing/additional-info',
        component: ExistingAdditionalInfo,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/fulfillment/phr/existing/lab/:detail',
        component: ExistingLabDetail,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/fulfillment/phr/existing/lab',
        component: ExistingLab,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/fulfillment/phr/existing/pharmacist-note/list/:cpaType',
        component: ExistingPharmacistNoteList,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/fulfillment/phr/existing/pharmacist-note/detail/:detailType/:cpaType/:cpaTID',
        component: ExistingPharmacistNoteDetail,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },

    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        // isLoadPage: true,
        path: '/fulfillment/phr/new/disease',
        component: PhrNewWhatDisease,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },

    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/fulfillment/phr/new/drug-allergy',
        component: PhrNewDrugAllergy,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/fulfillment/phr/new/dob',
        component: PhrNewDate,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/fulfillment/phr/new/gender',
        component: PhrNewGender,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/fulfillment/phr/new/body',
        component: PhrNewBody,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/fulfillment/phr/new/additional',
        component: PhrNewAdditional,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 90 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        path: '/fulfillment/service/diseases',
        component: Diseases,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 1, level: 100 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        path: '/fulfillment/setup/prescription',
        component: Prescription,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 2, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        path: '/fulfillment/setup/doctor-appointment',
        component: Prescription,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/fulfillment/service/srvmed-finish',
        component: SrvMedFinish,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 5, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/fulfillment/service/srv-finish',
        component: SrvFinish,
        servicePath: '/fulfillment',
        service: SERVICE_CONFIG.fulfillment,
        googleAnalytics: true,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/fulfillment/service/srvmed-order',
        component: SrvMedOrder,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 4, level: 90 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        path: '/fulfillment/service/pending-med',
        component: MedPendingTLP,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 3, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        path: '/fulfillment/service/pharmacist-cancel',
        component: MedPendingPharmacistCancel,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        path: '/fulfillment/service/task-reject',
        component: RejectTaskTLP,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/fulfillment/service/recreate-task',
        component: RecreateTask,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 3, level: 90 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/fulfillment/service/confirm-med/address',
        component: SelectAddressTLP,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 4, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/fulfillment/service/confirm-med/location',
        component: Location,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 4, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/fulfillment/service/confirm-med',
        component: MedConfirm,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 4, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        exact: true,
        googleAnalytics: true,
        path: '/fulfillment/service/payment',
        component: Payment,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 4, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        path: '/fulfillment/service/payment/mobile',
        component: MobileBanking,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 4, level: 0 },
    },
    {
        requireInProgress: true,
        requireNavebar: true,
        requireParamsTask: true,
        exact: true,
        googleAnalytics: true,
        path: '/fulfillment/service/no-payment',
        component: NoPayment,
        service: SERVICE_CONFIG.fulfillment,
        progress: { name: 'progressFUL', step: 4, level: 0 },
    },
    {
        requireNavebar: false,
        requireParamsTask: true,
        path: '/fulfillment/service/pickup',
        component: Pickup,
        service: SERVICE_CONFIG.fulfillment,
    },
];
