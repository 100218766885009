import { POST } from 'api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { numberjs } from 'helpers';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import {
    HomeStepReceiveServiceWrapper,
    MethodBottomStepStyle,
    MethodBottomStyle,
    MethodTopContentStyle,
    MethodTopLeftStyle,
    MethodTopRightStyle,
} from './Home.styled';

function HomeStepsReceiveService() {
    const [statistics, setStatistics] = useState({});
    const getStatistics = async () => {
        try {
            let result = await POST('/cms/statistics/pharmacy', {});
            setStatistics(result.data);
        } catch (error) {
            console.error('cms/hero ~ error', error);
        }
    };
    useEffect(() => {
        getStatistics();
    }, []);

    return (
        <HomeStepReceiveServiceWrapper>
            <MethodTopContent statistics={statistics} />
            <MethodBottom />
        </HomeStepReceiveServiceWrapper>
    );
}
function MethodTopContent({ statistics }) {
    return (
        <MethodTopContentStyle>
            <MethodTopLeft statistics={statistics} />
            <MethodTopRight />
        </MethodTopContentStyle>
    );
}
function MethodTopLeft({ statistics }) {
    const { t } = useTranslation();
    const topBox = (img: string, text: string, num: string, alt: string) => {
        return (
            <div className='box'>
                <div className='-img'>
                    <img src={img} alt={alt} title={alt} />
                </div>
                <div>
                    <span>{text}</span>
                    <br />
                    <span className='text-primary fw-500'>{numberjs(num).format('int')}</span>
                </div>
            </div>
        );
    };
    return (
        <MethodTopLeftStyle>
            {topBox(
                '/images/home/pill-jar.png',
                t('home:number_of_pharmacies_in_our_network'),
                statistics?.totalStores,
                ' ร้านขายยาในเครือ Pharmcare'
            )}
            {topBox('/images/home/stethoscope.png', t('home:total_number_of_services'), statistics?.totalServices, 'บริการที่ผ่านมา')}
            {topBox(
                '/images/home/pharmacist-icon.png',
                t('home:experienced_pharmacist_in_our_netork'),
                statistics?.totalPharmacists,
                ' เภสัชกรมากประสบการณ์'
            )}
        </MethodTopLeftStyle>
    );
}
function MethodTopRight() {
    return (
        <MethodTopRightStyle>
            <div className='doctor'>
                <img src='/images/home/home-method-doctor.png' alt='telemedicine' title='telemedicine' />
            </div>
            <div className='clinic'>
                <img src='/images/home/home-method-clinic.png' alt='telepharmacy rating/feedback' title='telepharmacy rating/feedback' />
            </div>
        </MethodTopRightStyle>
    );
}
function MethodBottom() {
    const [method_service, setMethod_service] = useState('step1');
    const onChangeStep = (step: string) => {
        setMethod_service(step);
    };
    const imgService = (img: string, step: string, alt: string) => {
        return <img src={img} alt={alt} title={alt} className={method_service === step ? 'd-block' : 'd-none'} />;
    };

    return (
        <MethodBottomStyle>
            <div className='-img'>
                {imgService('/images/home/home-talk-to-pharmacist.png', 'step1', 'คุยกับเภสัชกร')}
                {imgService('/images/home/home-pharmacist-service.png', 'step2', 'เภสัชกรจ่ายยาตามอาการ')}
                {imgService('/images/home/home-delivery.png', 'step3', 'ส่งยาตรงถึงหน้าบ้านคุณ')}
            </div>
            <MethodBottomStep method_service={method_service} onChangeStep={onChangeStep} />
        </MethodBottomStyle>
    );
}
function MethodBottomStep({ method_service, onChangeStep }) {
    const { t } = useTranslation();
    const topBox = (step: string, text1: string, text2: string, text3: string) => {
        return (
            <div className={`-box ${method_service === step ? '_active' : 'hidden'}`} onClick={() => onChangeStep(step)}>
                <div className='-title'>
                    <div className='-circle'>{text1}</div>
                    <strong>{text2}</strong>
                </div>
                <p>{text3}</p>
            </div>
        );
    };
    return (
        <MethodBottomStepStyle>
            <div className='-title' data-test-id='title'>
                {t('home:3_simple_steps')}{' '}
                <IconConfigWithTheme pageName='home_method' iconName='steps_to_start' className='text-orange-2' />
            </div>
            <p className='text-black-transparent-90 _web' style={{ marginBottom: '0rem' }}>
                {t('home:our_pharmacists')}
            </p>
            <p className='text-black-transparent-90 _web'>{t('home:get_serviced_immediately')}</p>

            <div className='-list'>
                {topBox('step1', '1', t('home:1_video_consultation'), t('home:start_your_consultation'))}
                {topBox(
                    'step2',
                    '2',
                    t('home:2_pharmacist_will_dispense_medication_according'),
                    t('home:if_your_conditions_require_medication')
                )}
                {topBox('step3', '3', t('home:3_get_your_medication_delivered_straight'), t('home:confirm_your_medication'))}
            </div>
        </MethodBottomStepStyle>
    );
}

export default HomeStepsReceiveService;
