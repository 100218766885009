import React from 'react';
import NavLogo from './nav-logo';
import NavLanguage from './nav-language';
import NavLogin from './nav-login';
import NavProfile from './nav-profile';
import useAuth from 'hooks/useAuth';
import NavMenuBar from './nav-menu-bar';
import NavFavourite from './nav-favourite';
import { NavbarWrapper } from './Navbar.styled';
import useThemeWithPartner from 'hooks/useThemeWithPartner';

function Navbar({ hasInProgress = false, mobileLanguage = false }) {
    const { auth } = useAuth();
    const theme = useThemeWithPartner();
    return (
        <NavbarWrapper className='navbar' {...{ $hasInProgress: hasInProgress }}>
            <NavLogo />
            <NavMenuBar role={auth.role} />
            <NavLanguage mobileLanguage={mobileLanguage} />
            <NavLogin />
            <NavProfile />
            {theme.name !== 'mtl' && !hasInProgress && <NavFavourite />}
        </NavbarWrapper>
    );
}

export default Navbar;
