import React from 'react';
import { SERVICE_CONFIG } from 'constants/service-config';

/**
 *
 * @returns {{
 * readyConfig: boolean,
 * name: 'telepharmacy'|'telepharmacyLTC'|'telemed'|'telemedConsult'|'telemedFulfillment'|'fulfillment'|'physiotherapy'|'physiotherapyConsult'|'physiotherapyFulfillment'|'nutritionist'|'nutritionistConsult'|'nutritionistFulfillment'|'tlm'|'tlmConsult'|'tlmFulfillment'|'mth',
 * communicationType: 'VIDEO_CALL'|'TELE_MED'|'GATE_WAY',
 * defaultVoucherCode: String,
 * defaultPharmcareProgram: '02'|'04'|'06'|'08',
 * serviceOrigin: 'telepharmacy'|'telemed'|'fulfillment'|'physiotherapy'|'nutritionist'|'tlm'|'mth'|'tlm-ncd',
 * servicePath: '/telepharmacy'|'/telemed'|'/telemed/consult'|'/telemed/fulfillment'|'/fulfillment'|'/pt'|'/pt/consult'|'/pt/fulfillment'|'/nt'|'/nt/consult'|'/nt/fulfillment'|'/tlm'|'/tlm/consult'|'/tlm/fulfillment''|'/tlm-ncd'|'/tlm-ncd/consult'|'/tlm-ncd/fulfillment',
 * paymentType: 'MEDICINE'|'SERVICE',
 * redirectPath: '/telepharmacy'|'/telepharmacy-ltc'|'/telemed'|'/fulfillment'|'/pt'|'/nt'|'/tlm'|'/mth'|'/tlm-nct'
 * }}
 */
function useServiceConfig() {
    const [serviceConfig, setServiceConfig] = React.useState({
        readyConfig: false,
        name: '',
        serviceOrigin: '',
        servicePath: '',
        redirectServicePath: '',
        paymentType: '',
        communicationType: '',
        defaultVoucherCode: '',
        defaultPharmcareProgram: '',
    });

    React.useEffect(() => {
        const getServiceConfigWithPathname = () => {
            const pathname = window.location.pathname;
            const isClaimService = Object.values(SERVICE_CONFIG).some((s) => pathname.startsWith(s.servicePath));

            if (!isClaimService) {
                setServiceConfig({
                    readyConfig: true,
                    name: '',
                    serviceOrigin: '',
                    servicePath: '',
                    redirectServicePath: '',
                    paymentType: '',
                    communicationType: '',
                    defaultVoucherCode: '',
                    defaultPharmcareProgram: '',
                });
                return; //end
            }

            const indexOfSlash2 = pathname.indexOf('/', 2);
            const pathnameAfterServicePath = pathname.substring(indexOfSlash2, pathname.length);
            let _servicePath = pathname.substring(0, indexOfSlash2);

            if (['/fulfillment', '/consult'].some((s) => pathnameAfterServicePath.startsWith(s))) {
                const indexOfSlash3 = pathname.indexOf('/', indexOfSlash2 + 1);
                _servicePath = pathname.substring(0, indexOfSlash3);
            }
            const findServiceConfig = Object.values(SERVICE_CONFIG).find((f) => f.servicePath === _servicePath);

            setServiceConfig({
                ...serviceConfig,
                readyConfig: true,
                name: findServiceConfig.name,
                serviceOrigin: findServiceConfig.serviceOrigin,
                servicePath: findServiceConfig.servicePath,
                redirectServicePath: findServiceConfig.redirectServicePath,
                paymentType: findServiceConfig.paymentType,
                communicationType: findServiceConfig.communicationType,
                defaultVoucherCode: findServiceConfig.defaultVoucherCode,
                defaultPharmcareProgram: findServiceConfig.defaultPharmcareProgram,
            });
        };
        getServiceConfigWithPathname();
    }, [window.location.pathname]);

    return serviceConfig;
}

export default useServiceConfig;
