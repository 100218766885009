import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { extendesClassname } from 'helpers';

/**
 * @param {{
 * variant?: 'primary' | 'second' | 'danger' | 'info',
 * loading?: boolean | false,
 * showSpinner?: boolean | true
 * disabled?: boolean | false,
 * className?: string,
 * children?: React.ReactNode
 * } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} props
 */
function Button({ variant, loading, showSpinner, disabled, className, children, ...props }) {
    const r = React.useRef();

    const clearLoadingOverflow = () => {
        const bodyElement = document.body;

        if (bodyElement.contains(r.current)) bodyElement.removeChild(r.current);
    };

    React.useEffect(() => {
        const bodyElement = document.body;
        const loadingElement = document.createElement('div');
        const loadingLineElement = document.createElement('div');

        loadingLineElement.className = 'fetch-loading-line';
        loadingElement.className = 'fetch-loading-overlay';
        loadingElement.style.position = 'fixed';
        loadingElement.style.top = 0;
        loadingElement.style.left = 0;
        loadingElement.style.bottom = 0;
        loadingElement.style.right = 0;
        loadingElement.style.zIndex = 100;
        loadingElement.style.backgroundColor = 'transparent';
        // loadingElement.appendChild(loadingLineElement)

        const loadingOverflow = () => {
            if (loading) {
                // set global element
                r.current = loadingElement;
                bodyElement.appendChild(r.current);
            } else {
                clearLoadingOverflow();
            }
        };

        loadingOverflow();
    }, [loading]);

    React.useEffect(() => {
        window.addEventListener('onchangepage', () => clearLoadingOverflow());

        return () => window.removeEventListener('onchangepage', () => clearLoadingOverflow());
    }, [r.current]);

    return (
        <>
            <Style disabled={loading || disabled} {...props} {...extendesClassname(['theme-btn', Button.variants[variant], className])}>
                <span className={`spinner ${loading && showSpinner ? 'loading' : 'loaded'}`}>{children}</span>
            </Style>
        </>
    );
}

Button.variants = {
    primary: 'theme-btn-primary',
    second: 'theme-btn-second',
    danger: 'theme-btn-danger',
    info: 'theme-btn-info',
};

Button.defaultProps = {
    disabled: false,
    loading: false,
    showSpinner: true,
};

Button.propTypes = {
    variant: PropTypes.oneOf(['primary', 'second', 'danger', 'info']),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    /** A renderable type (number, string, element, array…) */
    children: PropTypes.node,
};

export default Button;

const Style = styled.button`
    label: button;

    .spinner {
        position: relative;
        display: inline-block;

        &.loading::after {
            content: '';
            position: absolute;
            top: calc(50% - 0.5rem);
            left: calc(100% + 6px);
            display: block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            border: 3px solid var(--second-color);
            border-right-color: transparent;
            animation: spinnerAnimetion 0.75s linear infinite;
        }
    }

    & + .fetch-loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 100;
        background-color: transparent;
        .fetch-loading-line {
            position: relative;
            height: 4px;
            background-color: var(--primary-color);
            animation: lineAnimetion 15s linear infinite;
        }
    }
    @keyframes spinnerAnimetion {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes lineAnimetion {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }
`;
