import React from 'react';
import styled from '@emotion/styled';

const Style = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-color: transparent;
    z-index: 10;
`;

function EnabledDeveloperTools() {
    const [count, setCount] = React.useState(0);
    //
    const onEnabled = React.useCallback(() => {
        setCount(count + 1);
        if (count === 10) {
            window.localStorage.setItem('enableDeveloperTools', 'true');
            alert('enable developer tools');
            window.location.reload();
        }
    });
    return <Style onClick={onEnabled} />;
}

export default EnabledDeveloperTools;
