import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoint, extendesClassname } from 'helpers';
import Button from '../button';
import { useTranslation } from 'react-i18next';

const Style = styled.div`
    label: exp-popupjs-container;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background: #00000050;
    z-index: var(--popup-zindex);

    .exp-popupjs {
        &-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #00000050;
        }
        &-content {
            position: relative;
            width: 90%;
            max-width: 370px;
            padding: 30px;
            margin: auto;
            border-radius: 10px;
            z-index: 2;
            text-align: center;
            background-color: #fff;

            ${breakpoint('XS')} {
                width: 93%;
            }
        }

        &-icon {
            max-height: 1.75rem;
            font-size: 1.75rem;
            line-height: 0.75;
            margin: auto;
        }

        &-close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 1.375rem;
            height: 1.375rem;
            font-size: 0;
            border: none;
            padding: 0;
            background-color: transparent;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                top: calc(50% - 2px);
                left: 0;
                display: block;
                width: 100%;
                height: 4px;
                border-radius: 4px;
                background-color: var(--gray-2);
                transform: rotate(45deg);
            }

            &::before {
                content: '';
                position: absolute;
                top: calc(50% - 2px);
                left: 0;
                display: block;
                width: 100%;
                height: 4px;
                border-radius: 4px;
                background-color: var(--gray-2);
                transform: rotate(45deg);
                transform: rotate(-45deg);
            }
        }

        &-title {
            font-size: 1.325rem;
            font-weight: 500;
            text-align: center;

            ${breakpoint('XS')} {
                padding: 0;
            }
        }

        &-footer {
            display: flex;
            z-index: 1;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin: 1.25em auto 0;

            button {
                width: 100%;
                margin-bottom: 0.3125em;
                &.btn-underline {
                    width: fit-content;
                    box-shadow: none;
                    color: var(--gray-2);
                    background-color: transparent;
                    font-family: var(--font-detail2);
                    border-color: transparent;

                    > span,
                    .text-loading {
                        text-decoration: underline;
                    }
                    &:disabled {
                        color: var(--gray-1) !important;
                        background-color: transparent !important;
                        cursor: not-allowed;
                    }
                }
            }

            ${breakpoint('XS')} {
                &.footer-button-revers {
                    flex-wrap: wrap-reverse;
                }
            }
        }

        &-default,
        &-success {
            .exp-popupjs-icon,
            .exp-popupjs-title {
                color: var(--primary-color);
            }
        }
        &-warning,
        &-error {
            .exp-popupjs-icon,
            .exp-popupjs-title {
                color: var(--red-1);
            }
        }
    }
`;

const DEFAULT_ICONS = {
    success: <i className='fas fa-check-circle'></i>,
    warning: <i className='fas fa-exclamation-circle'></i>,
    error: <i className='fas fa-times-circle'></i>,
};

const PopupComponent = React.forwardRef(
    /**
     * @version 1.0.1 create
     *
     * @param {{
     * title: string | Element,
     * message: string | Element,
     * icon: 'success' | 'warning' | 'error' | Element,
     * type: 'default' | 'success' | 'warning' | 'error',
     * children: Node,
     * maxWidth: string,
     * maxHeight: string,
     * showClose: boolean | true,
     * isClickOutSideClose: boolean | true,
     * close: ()=> void,
     * buttons: Array.<{
     * label:string,
     * variant:'primary' | 'second' | 'danger' | 'info',
     * action:({loading: (value:boolean)=>void, close: ()=>void, startAction: ()=>void, endAction: ()=>void})
     * }>
     * }} props
     * @returns
     */
    function WrapperComponent(
        { type, children, icon, title, message, maxWidth, maxHeight, buttons, classNames, showClose, isClickOutSideClose, close, ...props },
        ref
    ) {
        const { t } = useTranslation();
        const [buttonLoadingList, setButtonLoadingList] = React.useState([]);

        const adjustPopupContentMaxWidth = React.useMemo(() => {
            let _containerMaxWidth = {};

            if (maxWidth) _containerMaxWidth = { maxWidth: maxWidth };
            if (maxHeight)
                _containerMaxWidth = {
                    ..._containerMaxWidth,
                    maxHeight: maxHeight,
                    overflowY: 'auto',
                };

            return _containerMaxWidth;
        }, [maxWidth, maxHeight]);

        const setLoading = (value, index) => {
            let loading = buttonLoadingList;
            loading[index] = value;
            setButtonLoadingList([...loading]);
        };

        const closePopup = () => {
            if (buttonLoadingList.length > 0) {
                setButtonLoadingList([
                    ...buttonLoadingList.map(() => {
                        return false;
                    }),
                ]);
            }
            close();
        };

        const handleClickButton = (index, action) => {
            if (action) {
                action({
                    loading: (value) => setLoading(value, index),
                    startAction: () => setLoading(true, index),
                    endAction: () => setLoading(false, index),
                    close: closePopup,
                });
            } else {
                closePopup();
            }
        };

        const popupClassNameType = React.useMemo(() => {
            if (!type) return 'exp-popupjs-default';

            return `exp-popupjs-${type}`;
        }, [type]);

        const getIcon = () => {
            if (icon && typeof icon === 'string') return DEFAULT_ICONS[icon];

            return icon;
        };

        React.useEffect(() => {
            if (buttons.length > 0) {
                buttons.forEach(() => {
                    setButtonLoadingList([...buttonLoadingList, false]);
                });
            }
        }, []);

        return (
            <Style ref={ref} className='exp-popupjs-container' {...props}>
                {isClickOutSideClose && <div className='exp-popupjs-overlay' onClick={closePopup}></div>}
                <div {...extendesClassname(['exp-popupjs-content', popupClassNameType])} style={{ ...adjustPopupContentMaxWidth }}>
                    {showClose && <div className='exp-popupjs-close-icon' onClick={closePopup}></div>}
                    {icon && <div className='exp-popupjs-icon'>{getIcon()}</div>}

                    {title && <div {...extendesClassname(['exp-popupjs-title', classNames.title])}>{title}</div>}
                    <div {...extendesClassname(['exp-popupjs-body', classNames.message])}>
                        {!!message && message}

                        {children}
                    </div>
                    {buttons.length > 0 && (
                        <div {...extendesClassname(['exp-popupjs-footer', classNames.buttons])}>
                            {buttons.map((button, index) => (
                                <Button
                                    key={index}
                                    type='button'
                                    {...button}
                                    variant={button.variant}
                                    loading={buttonLoadingList[index]}
                                    onClick={() => handleClickButton(index, button.action)}
                                    {...extendesClassname([button.className])}
                                >
                                    {t(button.label, button.label)}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
            </Style>
        );
    }
);

PopupComponent.propTypes = {
    type: PropTypes.oneOf(['default', 'success', 'warning', 'error', '']),
    children: PropTypes.node,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf(['success', 'warning', 'error'])]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            variant: PropTypes.oneOf(['primary', 'second', 'danger', 'info']),
            action: PropTypes.func,
        })
    ),
    classNames: PropTypes.shape({ title: PropTypes.string, message: PropTypes.string, buttons: PropTypes.string }),
    showClose: PropTypes.bool,
    isClickOutSideClose: PropTypes.bool,
    close: PropTypes.func,
};
PopupComponent.defaultProps = {
    type: 'default',
    icon: 'default',
    buttons: [],
    classNames: { title: '', message: '', buttons: '' },
    showClose: true,
    isClickOutSideClose: true,
};

export default PopupComponent;
