import styled from '@emotion/styled';
import { breakpoint } from 'helpers';

export const LoginStyle = styled.div`
    label: landing-login;

    position: relative;
    width: 100%;
    padding: 2.778rem 1.625rem 0;
    margin-top: -1.875rem;
    border-top-left-radius: 1.875rem;
    border-top-right-radius: 1.875rem;
    background-color: #ebebeb;

    ${breakpoint('MD')} {
        padding-top: 2rem;
    }

    .landing {
        &-panel {
            display: flex;
            width: 100%;
            max-width: 900px;
            color: #707070;
            margin: auto;
        }

        &-image {
            width: 100%;
            max-width: 300px;
            margin-right: 3.667rem;
        }

        &-content {
            padding-bottom: 2.778rem;

            .title {
                color: #272727;
                font-size: 1.556rem;
                font-weight: 500;
            }

            .login-button {
                margin-top: 1.778rem;

                .image {
                    display: none;
                    padding-left: 20%;
                    padding-right: 20%;
                    margin: auto;
                }

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    max-width: 350px;
                    color: #fff;
                    border-color: #49ba00;
                    background-color: #49ba00;

                    i {
                        font-size: 1.444rem;
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        ${breakpoint('MD')} {
            &-image {
                display: none;
            }

            &-content {
                text-align: center;

                .title {
                    font-size: 1.375rem;
                }

                .detail {
                    width: 85%;
                    max-width: 350px;
                    margin: 1rem auto;
                }

                .login-button {
                    .image {
                        display: block;
                    }

                    button {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            &-row {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
`;

export const ServiceSliderStyle = styled.div`
    label: landing-service-slide-box;

    position: relative;
    width: 100%;
    padding: 3.889rem 1.444rem;
    background-color: #ffffff;

    ${breakpoint('XL')} {
        padding: 2rem 1.444rem;
    }
    .slick-list,
    .slick-slider,
    .slick-track {
        display: flex;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .landing {
        &-panel {
            position: relative;
            width: 100%;
            max-width: 1630px;
            margin: auto;

            .title {
                text-align: center;
                color: #005150 !important;
                font-size: 2.667rem;
                font-weight: 500;
                margin-bottom: 2.813rem;
                ${breakpoint('XL')} {
                    font-size: 1.25rem;
                    padding: 0 0.5rem;
                    margin-bottom: 1rem;
                }
            }
        }
        &-content {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            .service-slide {
                width: 100%;
                .img {
                    height: 100px;
                    ${breakpoint('LG')} {
                        height: 70px;
                        padding: 0 12px;
                    }
                }
            }
        }
    }
`;

export const ServiceQeustionStyle = styled.div`
    label: landing-service-qna;

    position: relative;
    width: 100%;
    padding: 10.333rem 1.444rem;
    background-color: #f5f5f5;

    .title {
        color: #005150;
        font-size: 2.667rem;
        font-weight: 500;
    }
    ${breakpoint('XL')} {
        padding: 3.125rem 1.25rem;

        .title {
            font-size: 1.25rem;
            margin-bottom: 2.813rem;
            padding: 0 0.5rem;
        }
    }

    .landing {
        &-panel {
            position: relative;
            width: 100%;
            max-width: 1630px;
            margin: auto;
        }

        &-content {
            display: flex;
        }

        &-image {
            width: 100%;
            max-width: 296px;
            margin: 3.333rem 3.333rem auto 3.333rem;

            img {
                object-position: top;
            }
        }

        ${breakpoint('XL')} {
            &-content {
                flex-wrap: wrap;
            }

            &-image {
                display: none;
            }
        }
    }

    .question-n-answer {
        width: 100%;

        .list {
            margin-bottom: 1.334rem;
            padding-bottom: 1.334rem;
            border-bottom: 1px solid #ebebeb;

            &:last-of-type {
                border-bottom: none;
            }

            .question {
                display: flex;
                justify-content: space-between;
                color: #005150;
                font-size: 1.556rem;
                font-weight: 500;
                cursor: pointer;

                i {
                    margin-left: 1rem;
                }

                h3 {
                    color: inherit;
                    font-size: inherit;
                }
            }
            .answer {
                color: #a0a0a0;
                padding-left: 1.5rem;
                font-family: var(--font-detail2);
            }

            ${breakpoint('XL')} {
                .question {
                    font-size: 1rem;
                    padding: 0 0.5rem;
                }
            }
        }
    }
`;

export const ServicePharmacyNearbyStyle = styled.div`
    label: landing-service-pharmacy-nearby;

    position: relative;
    background-color: #f9f9fa;
    z-index: 2;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 4.444rem;
        background-color: #edf7f8;
        z-index: -1;
    }

    .landing {
        &-panel {
            display: flex;
            width: 100%;
            max-width: 1630px;
            margin: auto;
            padding-top: 4.556rem;
            padding-right: 4.556rem;
            border-bottom-left-radius: 2.222rem;
            border-bottom-right-radius: 2.222rem;
            background-color: #f9f9fa;
            z-index: 2;
        }
        &-image {
            width: 100%;
            max-width: 800px;
            margin-right: -250px;
            z-index: 1;
        }

        ${breakpoint('XL')} {
            &-panel {
                border-bottom-left-radius: 1.25rem;
                border-bottom-right-radius: 1.25rem;
                padding: 0;
            }

            &-image {
                display: none;
            }
        }
    }

    .pharmacy {
        &-nearby {
            position: relative;
            width: 100%;
            margin-bottom: 7.389rem;
            padding: 4.556rem 4.556rem 2rem;
            border-radius: 1.111rem;
            box-shadow: 0 0 10px #77777720;
            background-color: #fff;
        }

        &-panel {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            text-align: center;

            .title {
                color: #000;
                font-weight: 500;
                font-size: 2rem;
                margin-bottom: 4.444rem;
            }
        }
        &-list {
            display: flex;
            justify-content: space-around;
            margin: auto;

            .list {
                display: inline-block;
                & + .list {
                    margin-left: 3.889rem;
                }
            }

            .circle {
                width: 128px;
                height: 128px;
                border-radius: 100%;
                overflow: hidden;
                margin: auto;
                border: 2px solid #00acaa;
            }

            .name {
                color: #272727;
                font-weight: 500;
                font-size: 1.22rem;
                margin-top: 0.5rem;
            }

            .radius {
                color: #00acaa;
                font-weight: 500;
            }
        }
        &-footer {
            margin-top: 2.444rem;

            button {
                width: 100%;
                max-width: 350px;
                padding-top: 0.778rem;
                padding-bottom: 0.778rem;
            }
        }

        ${breakpoint('XL')} {
            &-nearby {
                box-shadow: none;
                margin-bottom: 0;
                padding: 1.5rem 1.5rem 3.75rem;
                background-color: #f9f9fa;
            }
            &-list {
                width: 100%;
            }
        }

        ${breakpoint('MD')} {
            &-panel .title {
                font-size: 1.25rem;
                margin-bottom: 1.875rem;
            }
            &-list {
                .list + .list {
                    margin-left: 1.25rem;
                }
                .circle {
                    width: 65px;
                    height: 65px;
                }

                .name,
                .raduis {
                    font-size: 0.875rem;
                }
            }

            &-footer button {
                max-width: 260px;
            }
        }
    }
`;

export const ServiceRelateStyle = styled.div`
    label: landing-service-about;

    position: relative;
    width: 100%;
    padding: 3.889rem 1.444rem;
    background-color: #ecf4f5;

    ${breakpoint('LG')} {
        padding: 2rem 1.444rem 3.875rem;
    }

    .landing {
        &-panel {
            position: relative;
            width: 100%;
            max-width: 1630px;
            margin: auto;

            .title {
                color: #005150;
                font-weight: 500;
                font-size: 2.667rem;

                ${breakpoint('LG')} {
                    font-size: 1.25rem;
                }
            }
        }
        &-content {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            .service-slide {
                width: calc(100% - 180px - 3.889rem);

                .slide {
                    &-mobile {
                        display: none;
                    }
                }
            }

            .all-service {
                color: #25b8b6;
                font-size: 1.334rem;
                font-weight: 500;
                margin-left: 3.889rem;
                cursor: pointer;

                i {
                    margin-left: 0.5rem;
                }
            }

            ${breakpoint('LG')} {
                .service-slide {
                    width: 100%;

                    .slide {
                        &-desktop {
                            display: none;
                        }
                        &-mobile {
                            display: block;
                        }
                    }
                }
                .all-service {
                    display: none;
                }
            }
        }
    }
`;

export const ServiceRatingStyle = styled.div`
    label: landing-service-rating;

    position: relative;
    padding: 8.889rem 0;
    background-color: #edf7f8;

    ${breakpoint('MD')} {
        padding: 2.75rem 1.625rem;
    }

    .landing-header {
        display: flex;
        /* flex-direction: row; */
        justify-content: center;

        .header {
            .title {
                color: #005150;
                font-size: 2rem;
                font-weight: 500;
                text-align: center;
            }

            .star {
                width: fit-content;
                padding: 1rem;
                margin: 2rem auto;
                border-radius: 3rem;
                background-color: #fff;

                i {
                    color: #fdb359;
                    font-size: 1.556rem;

                    & + i {
                        margin-left: 1rem;
                    }
                }
            }
        }

        .score {
            position: relative;
            display: none;
            height: fit-content;
            text-align: center;
            border: 2px solid #00acaa;
            border-radius: 10px;
            padding: 0.5rem 1rem;
            margin-bottom: auto;

            i {
                color: #fdb359;
                vertical-align: middle;
            }

            span {
                font-weight: 500;
                font-size: 1.5rem;
                vertical-align: middle;
                margin-left: 0.275rem;
            }

            div + div {
                color: #a0a0a0;
                font-size: 0.75rem;
            }
        }

        ${breakpoint('MD')} {
            justify-content: space-between;

            .header {
                .title {
                    font-size: 1.25rem;
                    text-align: left;
                }
                .star {
                    margin: 0.625rem 0;
                    padding: 0.275rem 0.625rem;

                    i {
                        font-size: 0.875rem;
                    }
                }
            }
            .score {
                display: block;
            }
        }
    }

    .rating {
        max-width: 1600px;
        margin: 4.444rem auto auto;

        .slider {
            div[tabindex] {
                height: 100%;
            }
            .slick-track {
                display: flex;
            }
            .slick-track .slick-slide {
                height: inherit;

                & > div {
                    height: 100%;
                    margin-bottom: 1rem;
                }
            }

            &-goto {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                max-width: 350px;
                margin: 4.444rem auto auto;

                .arrows {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 3.556rem;
                    height: 3.556rem;
                    color: #c1c1c1;
                    font-size: 1.667rem;
                    border-radius: 100%;
                    background-color: #fff;
                    cursor: pointer;

                    &.active {
                        color: #005150;
                    }
                }

                .pagination {
                    color: #005150;
                    font-size: 1.667rem;
                    font-weight: 500;
                }
            }

            ${breakpoint('MD')} {
                .slick-track {
                    display: block;
                }
                .slick-track .slick-slide {
                    height: 100%;
                }

                &-goto {
                    max-width: 250px;
                    margin-top: 1.875rem;

                    .arrow {
                        width: 2.625rem;
                        height: 2.625rem;
                        font-size: 1.25rem;
                    }

                    .pagination {
                        font-size: 1.25rem;
                    }
                }
            }
        }
        &-box {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 95%;
            height: 100%;
            min-height: 250px;
            margin: auto;
            padding: 2rem;
            border-radius: 0.5rem;
            background-color: #fff;
        }
        &-comment {
            width: calc(100% - 40px - 2rem);
            font-size: 1.444rem;
            margin-bottom: 1rem;
        }
        &-score {
            position: absolute;
            top: 2rem;
            right: 2rem;

            i {
                color: #fdb359;
                font-size: 1.111rem;
                vertical-align: middle;
            }
            span {
                color: #707070;
                font-size: 1.556rem;
                vertical-align: middle;
                margin-left: 0.5rem;
            }
        }
        &-footer {
            display: flex;
            justify-content: space-between;
            color: #a0a0a0;
            font-size: 1.334rem;
            margin-top: auto;
        }
        &-name {
            font-family: Sarabun-Regular;
        }

        ${breakpoint('MD')} {
            margin-top: 1.875rem;

            &-box {
                width: 100%;
                min-height: 150px;
                padding: 1rem;
            }
            &-comment {
                font-size: 1rem;
            }
            &-score {
                top: 1rem;
                right: 1rem;

                i {
                    font-size: 0.625rem;
                }
                span {
                    font-size: 0.875rem;
                }
            }
            &-footer {
                font-size: 0.875rem;
            }
        }
    }
`;
