import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { EXPCollapse } from 'components/common/exp-collapse';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { updateUserLanguage } from 'api/api-often';
import { LANGUAGE_LIST } from 'constants/default-config';
import useAuth from 'hooks/useAuth';

const LANGUAGE_ICON = {
    th: '/images/sidebar/th.png',
    en: '/images/sidebar/en.png',
};
const LANGUAGE_ALT = {
    th: 'thai language',
    en: 'english language',
};
const Style = styled.div`
    label: sidebar-language;

    &:nth-of-type(n + 2) {
        border-top: 1px solid var(--white);
    }
    .lang- {
        &header {
            display: flex;
            align-items: center;
            font-weight: 600;
            margin: 1.5rem 0;
        }
        &img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-color: var(--second-color);
            img {
                object-fit: cover;
            }
        }
        &title {
            margin-left: 10px;
        }
        &menu {
            list-style: none;
        }
        &list {
            display: grid;
            grid-template-columns: 40px auto;
            grid-gap: 10px;
            color: var(--white);
            align-items: center;
            padding: 10px 0px;

            .lang-img {
                width: 30px;
                height: 30px;
            }
        }
    }
`;

function Sidebarlanguage() {
    const { t, i18n } = useTranslation();
    const { auth, updateProfile } = useAuth();
    const [showLang, setShowLang] = useState(false);

    const onSwitchLanguage = async (language) => {
        await updateUserLanguage(language, auth.accessToken);

        if (auth.isAuth) await updateProfile(auth.accessToken);
    };

    return (
        <Style>
            <div className='lang-header' onClick={() => setShowLang(!showLang)}>
                <div className='lang-img'>
                    <img src={LANGUAGE_ICON[i18n.language.toLowerCase()]} alt={LANGUAGE_ALT[i18n.language.toLowerCase()]} />
                </div>
                <div className='lang-title'>
                    {t(`common:language.${i18n.language.toLowerCase()}`)} {`(${i18n.language.toUpperCase()})`}
                </div>

                <IconConfigWithTheme
                    pageName='sidebar_language'
                    iconName={`${showLang ? 'show_lang' : 'not_show_lang'}`}
                    className='ml-auto'
                />
            </div>
            <EXPCollapse show={showLang}>
                <div>
                    <ul className='lang-menu'>
                        {LANGUAGE_LIST.map((lang, index) => (
                            <li key={index} className='lang-list' onClick={() => onSwitchLanguage(lang)}>
                                <div className='lang-img'>
                                    <img src={LANGUAGE_ICON[lang]} alt={LANGUAGE_ALT[lang]} />
                                </div>
                                <div>
                                    {t(`common:language.${lang}`)} {`(${lang.toUpperCase()})`}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </EXPCollapse>
        </Style>
    );
}

export default Sidebarlanguage;
