import React, { Fragment, useEffect } from 'react';

/**
 *
 * @param {{
 * condition?: any
 * children: React.ReactNode | Then | ElseIf | Else
 * }} props
 * @returns
 */
function If({ condition = null, children }) {
    useEffect(() => {
        if (
            (!Array.isArray(children) && !(children.type === Else || children.type === Then || child.type === ElseIf)) ||
            !React.Children.toArray(children).every((child) => child.type === Else || child.type === Then || child.type === ElseIf)
        ) {
            console.warn(
                'The <If> component should contain <Then /> <ElseIf /> <Else /> or <Fallback /> components as its children',
                children
            );
        }
    }, []);

    if (!children) return null;

    if (condition === null) return null;

    return <IfAsync condition={condition}>{children}</IfAsync>;
}
/**
 *
 * @param {{
 * log?: string
 * children: React.ReactNode
 * }} props
 * @returns
 */
function Then({ log, children }) {
    useEffect(() => {
        if (log) console.log(log);
    }, []);

    return <Fragment>{children}</Fragment>;
}
/**
 *
 * @param {{
 * log?: string
 * children: React.ReactNode
 * condition?: any
 * }} props
 * @returns
 */
function ElseIf({ log, children }) {
    useEffect(() => {
        if (log) console.log(log);
    }, []);

    return <Fragment>{children}</Fragment>;
}
/**
 *
 * @param {{
 * log?: string
 * children: React.ReactNode
 * }} props
 * @returns
 */
function Else({ log, children }) {
    useEffect(() => {
        if (log) console.log(log);
    }, []);

    return <Fragment>{children}</Fragment>;
}
/**
 *
 * @param {{
 * condition?: any
 * children: React.ReactNode
 * }} props
 * @returns
 */
function IfAsync({ condition, children }) {
    const hasThen = React.Children.toArray(children).find((c) => c.type === Then);
    const hasElse = React.Children.toArray(children).find((c) => c.type === Else);
    const hasElseIf = React.Children.toArray(children).filter((c) => c.type === ElseIf);

    if (condition) {
        if (!hasThen) return <Fragment>{null}</Fragment>;

        return <Fragment>{hasThen}</Fragment>;
    }

    if (hasElseIf && hasElseIf.some((s) => s.props.condition !== null && s.props.condition)) {
        return <Fragment>{hasElseIf.find((s) => s.props.condition !== null && s.props.condition)}</Fragment>;
    }

    if (!hasElse) return <Fragment>{null}</Fragment>;

    return <Fragment>{hasElse}</Fragment>;
}

export { If, ElseIf, Then, Else };
