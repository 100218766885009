import styled from '@emotion/styled';
import usePageContext from 'hooks/usePageContext';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { numberjs } from 'helpers';

export default function CoverageCard({ companyCode, data, onSelectCoverage = () => {} }) {
    const { t } = useTranslation();
    const { props } = usePageContext();
    const theme = useThemeWithPartner();

    const getCoverageAmountt = () => {
        if (
            (theme.name === 'default_oceanopd' || companyCode === '19') &&
            data.amount <= 500 &&
            props.serviceConfig.servicePath === '/telepharmacy'
        ) {
            return (
                <>
                    <div className='text-black-transparent-90'>{t('claim:select_coverage.consultations_starting_at')}</div>
                    <div>200.00 {t('common:thb')}</div>
                </>
            );
        }
        if (
            (theme.name === 'default_oceanopd' || companyCode === '19') &&
            data.amount > 500 &&
            props.serviceConfig.servicePath === '/telepharmacy'
        ) {
            return (
                <>
                    <div className='text-black-transparent-90'>{t('claim:select_coverage.consultations_starting_at')}</div>
                    <div>300.00 {t('common:thb')}</div>
                </>
            );
        }
        if ((['default_oceanopd'].some((f) => f === theme.name) || companyCode === '19') && props.serviceConfig.servicePath === '/tlm') {
            return (
                <>
                    <div className='text-black-transparent-90'>{t('claim:select_coverage.consultations_starting_at')}</div>
                    <div>500.00 {t('common:thb')}</div>
                </>
            );
        }
        if (companyCode === '05' && data.policyNumber !== null && data.amount >= 9999) {
            return (
                <>
                    {t('claim:select_coverage.free_Doctor_fee')} {data.visited} / {data.totalVisit}{' '}
                    {t('claim:select_coverage.consultations')}
                </>
            );
        }
        if (props.serviceConfig.servicePath === '/tlm' && ['mtl'].some((f) => f === theme.name)) {
            return (
                <>
                    <div className='text-black-transparent-90'>{t('claim:select_coverage.consultations_starting_at')}</div>
                    <div>300.00 {t('common:thb')}</div>
                </>
            );
        }
        if (props.serviceConfig.servicePath === '/tlm') {
            return (
                <>
                    <div className='text-black-transparent-90'>{t('claim:select_coverage.consultations_starting_at')}</div>
                    <div>250.00 {t('common:thb')}</div>
                </>
            );
        }
        if (props.serviceConfig.servicePath !== '/telemed') {
            return (
                <>
                    <div className='text-black-transparent-90'>{t('claim:select_coverage.consultations_starting_at')}</div>
                    <div>
                        {numberjs(data.maxPharmacistFee).format('double', 2)} {t('common:thb')}
                    </div>
                </>
            );
        }

        return (
            <>
                <div className='text-black-transparent-90'>{t('claim:select_coverage.consultations_starting_at')}</div>
                <div>200.00 {t('common:thb')}</div>
            </>
        );
    };

    return (
        <Style onClick={() => onSelectCoverage(data)}>
            <div className='coverage-logo'>
                <img src={data.imageUrl} alt={data.companyName} />
            </div>
            {(['mtl', 'default_oceanopd'].some((f) => f === theme.name) || companyCode === '19') && (
                <div>
                    <div className='coverage-header break'>
                        {t('claim:select_coverage.policy_number')} {data.policyNo} (
                        {data.certNo !== null ? t('claim:select_coverage.group_policy') : t('claim:select_coverage.individual_policy')})
                    </div>
                    <div className='coverage-header'>
                        {t('claim:select_coverage.policy_start_date')} {moment(data.effectiveDate).format('DD MMM YYYY')}
                    </div>
                </div>
            )}
            {['mtl'].some((f) => f === theme.name) && data.coverageRemark && (
                <div className='coverage-header'>Remark: {data.coverageRemark}</div>
            )}
            <div className='coverage-detail'>
                <div className='col-left'>
                    {companyCode === '05' && data.policyNumber !== null ? (
                        <div>{data.policyNumber}</div>
                    ) : !(['mtl'].some((f) => f === theme.name) && data.amount === 999999) ? (
                        <>
                            <div className='text-black-transparent-90'>{t('claim:select_coverage.coverage_amount')}</div>
                            <div>
                                {numberjs(data.amount).format('double', 2)} {t('common:thb')}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div className='col-right'>{getCoverageAmountt()}</div>
                <div className='angle-icon' />
            </div>
        </Style>
    );
}

const Style = styled.div`
    label: coverage-card;

    position: relative;
    width: 100%;
    padding: 20px;
    margin: 2rem auto;
    border-radius: 14px;
    border: 2px solid var(--text-normal-4);
    cursor: pointer;

    .coverage-logo {
        height: 50px;

        img {
            object-position: left;
        }
    }
    .coverage-header {
        margin-top: 0.5rem;
        color: var(--text-normal-1);
    }
    .break {
        word-break: break-all;
    }
    .coverage-detail {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0.5rem;
        padding-right: 20px;
    }

    .col-left {
        max-width: 180px;
        min-width: 120px;
        color: var(--text-normal-2);
        margin-bottom: 0.5rem;
        padding-right: 25px;
    }

    .col-right {
        min-width: 120px;
        color: var(--text-normal-2);
        margin-bottom: 0.5rem;
        padding-right: 25px;
    }

    .angle-icon {
        position: absolute;
        top: calc(50% - 10px);
        right: 0;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border-top: 4px solid var(--icon-fourth-color);
        border-right: 4px solid var(--icon-fourth-color);
        transform: rotate(45deg);
        cursor: pointer;
    }
`;
