import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { breakpoint } from 'helpers';
import { HomeRegisterWrapper } from './Home.styled';

function HomeRegister() {
    const { t } = useTranslation();

    return (
        <HomeRegisterWrapper>
            <div className='home-register-left'>
                <div className='home-register-left-img'>
                    <img src='/images/home/home-register.png' alt='home-register' title=' ลงทะเบียนเข้าใช้ pharmcare' />
                </div>
            </div>
            <div className='home-register-right'>
                <div className='home-register-title'>{t('home:we_are_ready_to_provide_quality_care')}</div>
                <p>
                    {t('home:we_screen_only_the_highest_quality_pharmacies')} {t('home:to_deliver_accessible_healthcare_at_the')}{' '}
                    {t('home:highest_quality')}
                </p>
                <div className='home-register-link'>
                    <a
                        className='theme-btn theme-btn-info w-100 maw-300 text-decoration-none'
                        href='/user/signup/line'
                        data-test-id='signup'
                    >
                        {t('home:register_now')}
                    </a>
                </div>
            </div>
            <div className='home-register-bg-circle' />
        </HomeRegisterWrapper>
    );
}
export default HomeRegister;
