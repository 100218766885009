import { css } from '@emotion/react';
import { breakpoint } from 'helpers';

export const button = css`
    button {
        &:focus,
        &:active,
        &:hover {
            outline: none;
            box-shadow: none;
        }
    }
    .custom-popup-btn-nowarp {
        flex-wrap: nowrap !important;
        > button {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
    .theme-btn {
        position: relative;
        display: inline-block;
        height: fit-content;
        font-size: 1rem;
        line-height: 1.5;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 0.475rem 1.475rem;
        border: 1px solid transparent;
        border-radius: var(--button-radius);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

        ${breakpoint('LG')} {
            /* padding: 0.375rem 1.375rem; */
            padding: 0.675rem 1.375rem;
        }

        &-primary {
            color: var(--btn-primary-color);
            background-color: var(--btn-primary-background-color);

            &:hover {
                color: var(--btn-primary-hover-color);
                background-color: var(--btn-primary-hover-background-color);

                ${breakpoint('LG')} {
                    color: var(--btn-primary-color);
                    background-color: var(--btn-primary-background-color);
                }
            }
        }

        &-second {
            color: var(--btn-second-color);
            border: 2px solid var(--btn-second-border-color);
            background-color: var(--btn-second-background-color);

            &:hover {
                color: var(--btn-second-hover-color);
                background-color: var(--btn-second-hover-background-color);

                ${breakpoint('LG')} {
                    color: var(--btn-second-color);
                    background-color: var(--btn-second-background-color);
                }
            }

            &:disabled {
                border: 2px solid var(--text-normal-7);
            }
        }

        &-danger {
            color: var(--btn-danger-color);
            background-color: var(--btn-danger-background-color);

            &:hover {
                color: var(--btn-danger-hover-color);
                background-color: var(--btn-danger-hover-background-color);

                ${breakpoint('LG')} {
                    color: var(--btn-danger-color);
                    background-color: var(--btn-danger-background-color);
                }
            }
        }
        &-info {
            color: var(--btn-info-color);
            background-color: var(--btn-info-background-color);

            &:hover {
                color: var(--btn-info-hover-color);
                background-color: var(--btn-info-hover-background-color);

                ${breakpoint('LG')} {
                    color: var(--btn-info-color);
                    background-color: var(--btn-info-background-color);
                }
            }
        }

        &:disabled,
        &:disabled:hover {
            color: var(--text-normal-2);
            background-color: var(--text-normal-7);
            cursor: not-allowed;
        }
    }

    .btn-blue {
        color: var(--btn-blue-color);
        background-color: var(--btn-blue-background-color);
        &:hover {
            color: var(--btn-blue-hover-color);
            background-color: var(--btn-blue-hover-background-color);

            ${breakpoint('XL')} {
                background-color: var(--btn-blue-background-color);
            }
        }
    }
    .btn-green-home-service {
        color: #fff;
        background-color: #44acaa;
        &:hover {
            color: #fff;
            background-color: #44acaa;

            ${breakpoint('XL')} {
                background-color: #44acaa;
            }
        }
    }
    .btn-orange {
        color: var(--btn-orange-color);
        background-color: var(--btn-orange-background-color);
        &:hover {
            color: var(--btn-orange-hover-color);
            background-color: var(--btn-orange-hover-background-color);

            ${breakpoint('XL')} {
                background-color: var(--btn-orange-background-color);
            }
        }
    }
    .btn-green-pastel {
        color: #fff;
        background-color: #3ba274;
        &:hover {
            color: #fff;
            background-color: #3ba274;

            ${breakpoint('XL')} {
                background-color: #3ba274;
            }
        }
    }
    .btn-blue-pastel {
        color: #fff;
        background-color: #4f9ecb;
        &:hover {
            color: #fff;
            background-color: #4f9ecb;

            ${breakpoint('XL')} {
                background-color: #4f9ecb;
            }
        }
    }
    .btn-light-green {
        color: var(--white);
        border-radius: 10px;
        padding: 0.375rem 1.475rem;
        background-color: var(--green-1);
    }
`;
