import { POST } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { CarouselItem } from 'components/common/Carousel';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { HomeSlideWrapper } from './Home.styled';

function HomeSlide() {
    const theme = useThemeWithPartner();
    const [slideList, setSlideList] = useState<any[]>([]);

    const getSlider = useCallback(async () => {
        try {
            const res = await POST('/cms/hero');
            setSlideList(res.data);
        } catch (error) {
            console.error('cms/hero ~ error', error);
        }
    }, []);
    useEffect(() => {
        getSlider();
    }, []);
    return (
        <HomeSlideWrapper controls={false}>
            {slideList.map((e, i) => (
                <CarouselItem key={i}>
                    <div className='carousel-element'>
                        <div className='carousel-content'>
                            <strong>{e.heroSubject}</strong>
                            <br />
                            <div className='d-flex flex-wrap justify-content-center w-100'>
                                <a
                                    className={`theme-btn theme-btn-info maw-200 m-2  text-decoration-none w-100`}
                                    href={e.redirectUrl01 + window.location.search}
                                >
                                    {e.buttonLabel01}
                                </a>
                                {theme.name !== 'rabbit' && theme.name !== 'humatrix' && e.redirectUrl02 && (
                                    <a
                                        className={`theme-btn btn-blue maw-200 m-2  text-decoration-none w-100`}
                                        href={e.redirectUrl02 + window.location.search}
                                    >
                                        {e.buttonLabel02}
                                    </a>
                                )}
                                {theme.name !== 'rabbit' && theme.name !== 'humatrix' && e.redirectUrl03 && (
                                    <a
                                        className='theme-btn btn-green maw-250 m-2 text-decoration-none w-100'
                                        href={e.redirectUrl03 + window.location.search}
                                    >
                                        {e.buttonLabel03}
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className='carousel-img'>
                            {e.heroImgUrl && (
                                <img loading='lazy' src={e.heroImgUrl} alt='pharmcare hero image' title='pharmcare hero image' />
                            )}
                        </div>
                    </div>
                    <div className='carousel-background'>
                        {e.backgroundImgUrl && (
                            <img
                                loading='lazy'
                                src={e.backgroundImgUrl}
                                alt='pharmcare hero background'
                                title='pharmcare hero background'
                            />
                        )}
                    </div>
                </CarouselItem>
            ))}
        </HomeSlideWrapper>
    );
}

export default HomeSlide;
