import { css } from '@emotion/react';

const TH_CHARACTER = ['ก', 'ข', 'ค', 'ง', 'จ', 'ฉ', 'ช', 'ซ', 'ฌ', 'ญ', 'ฎ', 'ฏ', 'ฐ', 'ฑ'];
const TH_25_CHARACTER = ['ก', 'ข', 'จ', 'ฉ', 'ช', 'ซ', 'ฌ', 'ญ', 'ฎ', 'ฏ', 'ฐ', 'ฑ'];

const runThaiCharacter = ({ prefix = '', suffix = '', options = '' }) => {
    const result = TH_CHARACTER.map((c, index) => {
        return `
        &:nth-of-type(${index + 1})::before {
            content: '${prefix}${c}${suffix}';
            ${options}
        }
        `;
    });

    return result.join('\n');
};
const runThai25Character = ({ prefix = '', suffix = '', options = '' }) => {
    const result = TH_25_CHARACTER.map((c, index) => {
        return `
        &:nth-of-type(${index + 1})::before {
            content: '${prefix}${c}${suffix}';
            ${options}
        }
        `;
    });

    return result.join('\n');
};

export const listStyle = () => css`
    /* level 1 */
    .list-style-character {
        &-bracket,
        &-right-bracket,
        &-right-dot,
        &-th25-right-dot {
            list-style-type: none;
            padding-inline-start: 60px;
            counter-reset: run-number;

            > li {
                position: relative;
                counter-increment: run-number;

                &::before {
                    position: absolute;
                    right: calc(100% + 2px);
                    margin-right: 6px;
                }
            }
            &[lang='th'] > li {
                ${runThaiCharacter({ suffix: ')' })}
            }

            &[lang='en'] > li::before {
                content: counter(run-number, lower-alpha) ')';
            }

            &.__no-padding-inline-start {
                padding-inline-start: 0;

                > li::before {
                    position: relative;
                    right: 0;
                }
            }
        }

        &-bracket {
            &[lang='th'] > li {
                ${runThaiCharacter({
                    prefix: '(',
                    suffix: ')',
                })}
            }

            &[lang='en'] > li::before {
                content: '(' counter(run-number, lower-alpha) ')';
            }
        }
        &-right-bracket {
            &[lang='th'] > li {
                ${runThaiCharacter({ suffix: ')' })}
            }

            &[lang='en'] > li::before {
                content: counter(run-number, lower-alpha) ')';
            }
        }
        &-right-dot {
            &[lang='th'] > li {
                ${runThaiCharacter({
                    suffix: '.',
                })}
            }

            &[lang='en'] > li::before {
                content: counter(run-number, lower-alpha) '.';
            }
        }
        &-th25-right-dot {
            &[lang='th'] > li {
                ${runThai25Character({
                    suffix: '.',
                })}
            }
            &[lang='en'] > li {
                ${runThai25Character({
                    suffix: '.',
                })}
            }
        }
    }
    /* level 1 */
    .list-style-number {
        &-bracket,
        &-right-dot,
        &-right-bracket {
            list-style-type: none;
            counter-reset: run-number;
            padding-inline-start: 60px;

            & > li {
                position: relative;
                counter-increment: run-number;
                &::before {
                    position: absolute;
                    right: calc(100% + 2px);
                    margin-right: 6px;
                    width: max-content;
                }
            }

            &.__bold > li::before {
                font-weight: 600;
            }

            &.__no-padding-inline-start {
                padding-inline-start: 0;

                > li::before {
                    position: relative;
                    right: 0;
                }
            }
        }

        &-bracket > li::before {
            content: '(' counter(run-number, decimal) ')';
        }
        &-right-dot > li::before {
            content: counter(run-number, decimal) '.';
        }
        &-right-bracket > li::before {
            content: counter(run-number, decimal) ')';
        }
    }
    /* level 1 */
    .list-style-nested-number {
        counter-reset: run-number1;

        .list-style-nested-number-reset {
            counter-reset: run-number1;
        }
        .list-style-nested-number-increment {
            counter-increment: run-number1;
        }
        /* level 2 */
        .list-style-nested-number {
            &-nomal,
            &-bracket,
            &-right-dot,
            &-right-bracket {
                counter-reset: run-number2;
                list-style-type: none;
                padding-inline-start: 60px;

                .list-style-nested-number-reset {
                    counter-reset: run-number2;
                }
                .list-style-nested-number-increment {
                    counter-increment: run-number2;
                }

                & > li {
                    position: relative;
                    counter-increment: run-number2;

                    &::before {
                        position: absolute;
                        right: calc(100% + 2px);
                        margin-right: 6px;
                        width: max-content;
                    }
                }

                &.__bold > li::before {
                    font-weight: 600;
                }

                &.__no-padding-inline-start {
                    padding-inline-start: 0;

                    > li::before {
                        position: relative;
                        right: 0;
                    }
                }

                /* level 3 */
                .list-style-nested-number {
                    &-nomal,
                    &-bracket,
                    &-right-dot,
                    &-right-bracket {
                        counter-reset: run-number3;

                        .list-style-nested-number-reset {
                            counter-reset: run-number3;
                        }
                        .list-style-nested-number-increment {
                            counter-increment: run-number3;
                        }

                        & > li {
                            counter-increment: run-number3;
                        }
                    }
                    &-nomal > li::before {
                        content: counter(run-number1, decimal) '.' counter(run-number2, decimal) '.' counter(run-number3, decimal);
                    }
                    &-bracket > li::before {
                        content: '(' counter(run-number1, decimal) '.' counter(run-number2, decimal) '.' counter(run-number3, decimal) ')';
                    }
                    &-right-dot > li::before {
                        content: counter(run-number1, decimal) '.' counter(run-number2, decimal) '.' counter(run-number3, decimal) '.';
                    }
                    &-right-bracket > li::before {
                        content: counter(run-number1, decimal) '.' counter(run-number2, decimal) '.' counter(run-number3, decimal) ')';
                    }
                }
            }
            &-nomal > li::before {
                content: counter(run-number1, decimal) '.' counter(run-number2, decimal);
            }
            &-bracket > li::before {
                content: '(' counter(run-number1, decimal) '.' counter(run-number2, decimal) ')';
            }
            &-right-dot > li::before {
                content: counter(run-number1, decimal) '.' counter(run-number2, decimal) '.';
            }
            &-right-bracket > li::before {
                content: counter(run-number1, decimal) '.' counter(run-number2, decimal) ')';
            }
        }
    }
    /* level 1 */
    .list-style-roman-bracket {
        list-style-type: none;
        counter-reset: run-number;
        padding-inline-start: 60px;
        > li {
            position: relative;
            counter-increment: run-number;
            &::before {
                position: absolute;
                right: calc(100% + 2px);
                content: '(' counter(run-number, lower-roman) ')';
                margin-right: 6px;
                width: max-content;
            }
        }
    }
`;
