import styled from '@emotion/styled';
import { EXPCollapseComplete } from 'components/common/exp-collapse';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Style = styled.div`
    label: privacy-policy-with-theme;

    width: 94%;
    max-width: 900px;
    padding-bottom: 40px;
    margin: 0 auto;

    .custom-collapse-title {
        border-radius: 10px;
        padding: 10px;
        margin-top: 10px;
        font-size: 1.2rem;
        background-color: var(--lightseagreen-2);
    }
`;

export default function PrivacyPolicyCentral20() {
    const { t, i18n } = useTranslation();
    console.log('%c>> i18n:', 'background: #00f; color: #fff', i18n.language);

    return (
        <Style className='custom-panel'>
            <div className='list-style-nested-number'>
                <h4 className='text-primary'>{t('privacy-policy-central20:title')}</h4>
                <p dangerouslySetInnerHTML={{ __html: t('privacy-policy-central20:detail1') }} />
                {t('privacy-policy-central20:detail2')}

                {/* <Collaspe1 /> */}
                <EXPCollapseComplete
                    defaultShow={true}
                    classNameTitle='custom-collapse-title'
                    className='list-style-nested-number-increment'
                    title={<>1. {t('privacy-policy-central20:collapse1.title')}</>}
                >
                    <div className='detail'>
                        {t('privacy-policy-central20:collapse1.detail1')}
                        <ul className='list-style-nested-number-nomal __bold'>
                            <li>
                                <strong>{t('privacy-policy-central20:collapse1.detail1_1.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse1.detail1_1.detail1')}</div>
                                <ul className='list-style-nested-number-nomal mt-1'>
                                    <li>
                                        <u
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-central20:collapse1.detail1_1_1.title') }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-central20:collapse1.detail1_1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <u
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-central20:collapse1.detail1_1_2.title') }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-central20:collapse1.detail1_1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <u
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-central20:collapse1.detail1_1_3.title') }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-central20:collapse1.detail1_1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <u
                                            dangerouslySetInnerHTML={{ __html: t('privacy-policy-central20:collapse1.detail1_1_4.title') }}
                                        />
                                        <div
                                            className='d-inline'
                                            dangerouslySetInnerHTML={{
                                                __html: t('privacy-policy-central20:collapse1.detail1_1_4.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('privacy-policy-central20:collapse1.detail1_2.title')}</strong>
                                <div dangerouslySetInnerHTML={{ __html: t('privacy-policy-central20:collapse1.detail1_2.detail1') }} />
                                <ul className='list-style-nested-number-nomal'>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_1.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_2.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_3.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_4.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_5.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_6.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_7.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_8.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_9.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_10.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_11.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_12.detail1')}</div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_13.detail1')}</div>
                                        <div style={{ marginLeft: '-60px' }}>
                                            {t('privacy-policy-central20:collapse1.detail1_2_13.detail2')}
                                        </div>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_14.detail1')}</div>
                                    </li>
                                    <li>
                                        {t('privacy-policy-central20:collapse1.detail1_2_15.detail1')}

                                        <ul
                                            className={`${
                                                i18n.language === 'en' ? 'list-style-roman-bracket' : 'list-style-number-bracket'
                                            }`}
                                        >
                                            <li>{t('privacy-policy-central20:collapse1.detail1_2_15_1.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_2_15_2.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_2_15_3.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_2_15_4.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_2_15_5.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_2_15_6.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_2_15_7.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_2_15_8.detail1')}</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div>{t('privacy-policy-central20:collapse1.detail1_2_16.detail1')}</div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('privacy-policy-central20:collapse1.detail1_3.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse1.detail1_3.detail1')}</div>
                                <ul className='list-style-nested-number-nomal'>
                                    <li>
                                        {t('privacy-policy-central20:collapse1.detail1_3_1.detail1')}
                                        <ul
                                            className={`${
                                                i18n.language === 'en' ? 'list-style-roman-bracket' : 'list-style-number-bracket'
                                            }`}
                                        >
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_1_1.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_1_2.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_1_3.detail1')}</li>
                                        </ul>
                                    </li>
                                    <li>
                                        {t('privacy-policy-central20:collapse1.detail1_3_2.detail1')}
                                        <ul
                                            className={`${
                                                i18n.language === 'en' ? 'list-style-roman-bracket' : 'list-style-number-bracket'
                                            }`}
                                        >
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_2_1.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_2_2.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_2_3.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_2_4.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_2_5.detail1')}</li>
                                            <li>{t('privacy-policy-central20:collapse1.detail1_3_2_6.detail1')}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe2 /> */}
                <EXPCollapseComplete
                    defaultShow={true}
                    classNameTitle='custom-collapse-title'
                    className='list-style-nested-number-increment'
                    title={<>2. {t('privacy-policy-central20:collapse2.title')}</>}
                >
                    <div className='detail'>
                        <div>{t('privacy-policy-central20:collapse2.detail1')}</div>
                        <ul>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_1.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_1.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_2.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_2.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_3.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_3.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_4.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_4.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_5.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_5.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_6.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_6.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_7.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_7.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_8.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_8.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_9.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_9.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_10.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_10.detail1')}</div>
                                </div>
                            </li>
                            <li className='mb-3'>
                                <strong>{t('privacy-policy-central20:collapse2.detail1_11.title')}</strong>
                                <div className='d-flex'>
                                    <strong>{t('privacy-policy-central20:example')}&nbsp;:</strong>
                                    <div className='d-inline-block ml-2'>{t('privacy-policy-central20:collapse2.detail1_11.detail1')}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe3 /> */}
                <EXPCollapseComplete
                    defaultShow={true}
                    classNameTitle='custom-collapse-title'
                    className='list-style-nested-number-increment'
                    title={<>3. {t('privacy-policy-central20:collapse3.title')}</>}
                >
                    <div className='detail'>
                        {t('privacy-policy-central20:collapse3.detail1')}
                        <ul className='list-style-nested-number-nomal'>
                            <li className='mb-2'>{t('privacy-policy-central20:collapse3.detail1_1.detail1')}</li>
                            <li className='mb-2'>{t('privacy-policy-central20:collapse3.detail1_2.detail1')}</li>
                            <li className='mb-2'>{t('privacy-policy-central20:collapse3.detail1_3.detail1')}</li>
                        </ul>
                    </div>
                </EXPCollapseComplete>

                {/* <Collaspe4 /> */}
                <EXPCollapseComplete
                    defaultShow={true}
                    classNameTitle='custom-collapse-title'
                    className='list-style-nested-number-increment'
                    title={<>4. {t('privacy-policy-central20:collapse4.title')}</>}
                >
                    <div className='detail'>{t('privacy-policy-central20:collapse4.detail1')}</div>
                </EXPCollapseComplete>

                {/* <Collaspe5 /> */}
                <EXPCollapseComplete
                    defaultShow={true}
                    classNameTitle='custom-collapse-title'
                    className='list-style-nested-number-increment'
                    title={<>5. {t('privacy-policy-central20:collapse5.title')}</>}
                >
                    <div className='detail'>
                        {t('privacy-policy-central20:collapse5.detail1')}
                        <ul className='list-style-nested-number-nomal __bold'>
                            <li className='mb-2'>
                                <strong>{t('privacy-policy-central20:collapse5.detail1_1.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse5.detail1_1.detail1')}</div>
                            </li>
                            <li className='mb-2'>
                                <strong>{t('privacy-policy-central20:collapse5.detail1_2.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse5.detail1_2.detail1')}</div>
                            </li>
                            <li className='mb-2'>
                                <strong>{t('privacy-policy-central20:collapse5.detail1_3.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse5.detail1_3.detail1')}</div>
                            </li>
                            <li className='mb-2'>
                                <strong>{t('privacy-policy-central20:collapse5.detail1_4.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse5.detail1_4.detail1')}</div>
                            </li>
                            <li className='mb-2'>
                                <strong>{t('privacy-policy-central20:collapse5.detail1_5.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse5.detail1_5.detail1')}</div>
                            </li>
                            <li className='mb-2'>
                                <strong>{t('privacy-policy-central20:collapse5.detail1_6.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse5.detail1_6.detail1')}</div>
                            </li>
                            <li className='mb-2'>
                                <strong>{t('privacy-policy-central20:collapse5.detail1_7.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse5.detail1_7.detail1')}</div>
                            </li>
                            <li className='mb-2'>
                                <strong>{t('privacy-policy-central20:collapse5.detail1_8.title')}</strong>
                                <div>{t('privacy-policy-central20:collapse5.detail1_8.detail1')}</div>
                            </li>
                        </ul>
                    </div>
                </EXPCollapseComplete>

                {/* <Bottom /> */}
                <p className='mt-3' dangerouslySetInnerHTML={{ __html: t('privacy-policy-central20:support_contact') }} />
            </div>
        </Style>
    );
}
