import React from 'react';
import styled from '@emotion/styled';
import { breakpoint, cryptojs } from 'helpers';
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

function NavLogin() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth } = useAuth();

    //
    const onLogin = (e) => {
        e.preventDefault();

        if (window.location.origin.includes('fastcare.co')) {
            const to = { path: '/user/plugin', data: { partner: '18', autoLogin: 0 } };
            return navigate('/user/line?to=' + cryptojs.encode(to));
        }

        if (window.location.origin.includes('yaphrom')) {
            const to = { path: '/user/plugin', data: { partner: '15', autoLogin: 0 } };
            return navigate('/user/line?to=' + cryptojs.encode(to));
        }

        return navigate('/user/line');
    };
    const onSignup = (e) => {
        e.preventDefault();

        if (window.location.origin.includes('fastcare.co')) {
            const to = { path: '/user/plugin', data: { partner: '18', autoLogin: 0 } };
            return navigate('/user/signup/line?to=' + cryptojs.encode(to));
        }
        if (window.location.origin.includes('yaphrom')) {
            const to = { path: '/user/plugin', data: { partner: '15', autoLogin: 0 } };
            return navigate('/user/signup/line?to=' + cryptojs.encode(to));
        }

        return navigate('/user/signup/line');
    };

    if (!auth.isAuth) {
        return (
            <Style>
                <a href='/login' onClick={onLogin} className='nav-login' data-test-id='login'>
                    {t('navbar:login')}
                </a>
                <a href='/signin' onClick={onSignup} className='nav-signup' data-test-id='signup'>
                    {t('navbar:signup')}
                </a>
            </Style>
        );
    }

    return null;
}

export default NavLogin;

const Style = styled.div`
    label: nav-login;

    display: flex;
    align-items: center;

    ${breakpoint('LG')} {
        display: none;
    }
    .nav-login {
        color: var(--navbar-login-color);
        margin: 0 15px;
    }
    .nav-signup {
        color: var(--navbar-register-color);
        margin: 0 15px;
    }
`;
