import React from 'react';
import FormGroup from '../form-group';
import moment from 'moment';
import { extendesClassname } from '../utils';
import FormInvalidFeedback from '../form-invalid-feedback';
import FormRecommend from '../form-recommend';
import FormLabel from '../form-label';
import DatePickerCalendar from '../datepicker-calendar';
import ReactMobileDatepicker from 'react-mobile-datepicker';
import { useTranslation } from 'react-i18next';
import EXPLiftUp from 'components/common/exp-lift-up';
import styled from '@emotion/styled';
import { breakpoint } from 'helpers';

const DatePickerSlideup = styled.div`
    label: datepicker-slideup;

    position: relative;
    margin-top: auto;
    height: inherit;
    display: flex;
    .datepicker-background-overlay,
    .datepicker-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .datepicker-body {
        position: relative;
        width: 100%;
        max-width: 750px;

        padding: 1rem 0;
        border-radius: 24px;
        overflow: hidden;
        background-color: #fff;
        transition: all 0.3s ease-in-out;

        ${breakpoint('MD')} {
            margin-top: auto;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .datepicker-accept {
        width: 100%;
        color: var(--primary-color);
        font-size: 1rem;
        text-align: center;
        padding: 0.5rem 1rem 0;
        margin-top: 1rem;

        button {
            width: 80%;
            max-width: 350px;
        }
    }

    .datepicker.ios {
        position: relative;
        font-family: var(--font-body);
        font-size: 1rem;
        background-color: #fff;

        .datepicker-content {
            padding-top: 0;

            .datepicker-wheel {
                color: var(--primary-color);
                border-color: var(--lightseagreen-transparent-15);
                background-color: var(--lightseagreen-transparent-45);
            }

            .datepicker-viewport {
                &::after {
                    background: linear-gradient(var(--white), transparent 20%, transparent 80%, var(--white));
                }
            }
        }
    }

    &[aria-expanded='true'] {
        opacity: 1;

        .datepicker-body {
            bottom: 0;
        }
    }
`;

const ReactMobileDatepickerConfig = {
    date: {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
    month: {
        format: (value) => moment(value).format('MMMM'),
        caption: 'Mon',
        step: 1,
    },
    year: {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
};

/**
 *
 * @default dateType="datepickerslider"
 * @param {{
 * title?: String | Element,
 * label?: String | Element,
 * formatDate?: String,
 * dateType?: 'datepicker'|'datepickerslider',
 * className?: String | Element,
 * classNameLabel?: String,
 * classNameField?: String,
 * errorMsg?: String | Element,
 * recommendMsg?: String | Element,
 * isInvalid?: Boolean,
 * labelTextSmall?: Boolean,
 * titleTextSmall?: Boolean,
 * xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs,
 * } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} peops
 * @returns
 */
function FieldDate({
    title = '',
    label = '',
    value = '',
    locale = 'th',
    dateType = 'datepickerslider',
    formatDate = 'YYYY-MM-DD',
    className = '',
    classNameLabel = '',
    classNameField = '',
    errorMsg = '',
    recommendMsg = '',
    isInvalid = false,
    labelTextSmall = true,
    titleTextSmall = true,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
    children,
    onChange = () => null,
    ...props
}) {
    const { t } = useTranslation();
    //
    const ref = React.useRef();
    const ref2 = React.useRef();
    const inputRef = React.useRef(null);
    //
    const [showCalendar, setShowCalendar] = React.useState(false);
    const [inputDate, setInputDate] = React.useState(value ? new Date(value) : new Date('01/01/1990'));
    //

    const onSelectDate = (date) => {
        onChange({ target: { value: moment(date) || '', name: props.name } });
        setShowCalendar(false);

        ref2.current.focus();
    };

    React.useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            if (dateType === 'datepicker') setShowCalendar(false);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, setShowCalendar]);

    React.useEffect(() => {
        const setInvalid = () => {
            const _isInvalidClassname = inputRef.current?.classList?.contains('is-invalid');

            if (isInvalid) {
                inputRef.current.setCustomValidity('Invalid field.');

                if (!_isInvalidClassname) inputRef.current.classList.add('is-invalid');
            } else if (_isInvalidClassname) {
                inputRef.current.classList.remove('is-invalid');
            }
        };

        setInvalid();

        return () => inputRef.current.setCustomValidity('');
    }, [isInvalid]);

    return (
        <FormGroup xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} xxxl={xxxl} className={classNameField}>
            {(title || label) && (
                <FormLabel textSmall={labelTextSmall && titleTextSmall} className={classNameLabel} required={props.required}>
                    {title || label}
                </FormLabel>
            )}
            {/* validate */}
            <input
                {...props}
                ref={inputRef}
                type='date'
                value={value ? moment(value).format('YYYY-MM-DD') : ''}
                className='exp-form-control exp-validate-date'
                onChange={() => null} // bypass handle
            />
            {/* display */}
            <div {...extendesClassname(['exp-form-date-display', props.className])}>
                <input
                    {...extendesClassname(['exp-form-control', props.className])}
                    ref={ref2}
                    value={value ? moment(value).format(formatDate) : ''}
                    placeholder={props.placeholder || formatDate}
                    readOnly
                    onClick={() => setShowCalendar(true)}
                />
                {dateType === 'datepicker' && (
                    <DatePickerCalendar
                        autoFocus={showCalendar}
                        datePickerRef={ref}
                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 10, display: showCalendar ? 'block' : 'none' }}
                        value={value || ''}
                        placeholder={props.placeholder || formatDate}
                        locale={locale}
                        format={formatDate}
                        onChangeDate={onSelectDate}
                    />
                )}
                {dateType === 'datepickerslider' && (
                    <EXPLiftUp.slideUp
                        show={showCalendar}
                        className='d-flex'
                        backgroundColor='transparent'
                        onClose={() => setShowCalendar(false)}
                        zIndex={1000}
                    >
                        <DatePickerSlideup>
                            <div className='datepicker-overlay' onClick={() => setShowCalendar(false)}></div>
                            <div className='datepicker-body'>
                                <ReactMobileDatepicker
                                    theme='ios'
                                    isOpen={true}
                                    isPopup={false}
                                    showFooter={false}
                                    showHeader={false}
                                    dateConfig={ReactMobileDatepickerConfig}
                                    value={inputDate}
                                    max={props.max ? new Date(props.max) : undefined}
                                    min={props.min ? new Date(props.min) : undefined}
                                    onChange={(date) => setInputDate(date)}
                                />
                                <div className='datepicker-accept'>
                                    <button className='theme-btn theme-btn-primary' onClick={() => onSelectDate(inputDate)}>
                                        {t('common:ok')}
                                    </button>
                                </div>
                            </div>
                        </DatePickerSlideup>
                    </EXPLiftUp.slideUp>
                )}
            </div>

            {children}
            {errorMsg && <FormInvalidFeedback>{errorMsg}</FormInvalidFeedback>}
            {recommendMsg && <FormRecommend>{recommendMsg}</FormRecommend>}
        </FormGroup>
    );
}

export default FieldDate;
