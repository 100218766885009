import { breakpoint } from 'helpers';
import styled from '@emotion/styled';

export const SidebarWrapper = styled.div`
    label: side-bar;

    position: fixed;
    top: 0;
    bottom: 0;
    right: 100%;
    display: none;
    width: 450px;
    height: 100vh;
    color: var(--white);
    z-index: var(--sidebar-zindex);
    background-color: var(--sidebar-background-color);
    transform: translateX(450px);
    transition: transform 0.5s ease-in-out;
    overflow: visible;

    ${breakpoint('LG')} {
        display: block;
    }

    ${breakpoint('XS')} {
        width: 100vw;
        transform: translateX(100vw);
    }

    &.closed {
        position: absolute;
        height: var(--body-height);
        transform: translateX(0);
        transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
        opacity: 1;
    }
`;

export const SideBarContentWrapper = styled.div`
    label: sidebar-content;

    display: flex;
    flex-direction: column;
    height: calc(var(--body-height) - 50px);
    padding: 10px 30px;
    overflow-y: auto;
    background-color: var(--side-bar-background-color);
    z-index: 10;
`;

export const SidebarProfileWrapper = styled.div<{ $hasInProgress?: boolean }>`
    label: sidebar-profile;

    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: ${(props) => (props.$hasInProgress ? 'block' : 'none')};
        background-color: transparent;
    }

    .profile {
        display: flex;
        align-items: center;
        font-weight: 600;
        margin: 1.5rem 0;

        .photo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-color: var(--second-color);

            img {
                object-fit: cover;
            }
        }

        .name {
            margin-left: 10px;
        }
    }
    .-menu {
        list-style: none;
        .-list {
            display: grid;
            grid-template-columns: 40px auto;
            grid-gap: 10px;
            color: var(--white);
            align-items: center;
            padding: 10px 0px;
            .-img {
                width: 30px;
                height: 30px;
            }
        }
    }
`;
