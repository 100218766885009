import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { breakpoint } from 'helpers';

const Style = styled.div`
    label: terms-conditions-with-theme;

    .terms-and-conditions-title {
        color: var(--text-title-color);
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .terms-and-conditions-subtitle {
        color: var(--text-subtitle-color);
        font-weight: 600;
    }
    .terms-and-conditions-remark {
        color: var(--red-1);
    }

    .terms-and-conditions-panel {
        position: relative;
        display: block;
        width: 94%;
        max-width: 900px;
        padding-bottom: 40px;
        margin: 0 auto;

        ${breakpoint('XS')} {
            padding-bottom: 24px;
        }
    }
`;

function TermsAndConditionsRabbit(props) {
    const { t } = useTranslation();
    return (
        <>
            <Style className='terms-and-conditions' {...props}>
                <div className='terms-and-conditions-panel'>
                    <h4
                        id='terms-conditions-rabbit-title'
                        className='terms-and-conditions-title'
                        dangerouslySetInnerHTML={{
                            __html: t('terms-and-conditions-rabbit:title'),
                        }}
                    />
                    <h5 className='terms-and-conditions-subtitle'>{t('terms-and-conditions-rabbit:subtitle')}</h5>
                    <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-rabbit:detail1') }} />
                    <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-rabbit:detail2') }} />
                    <p
                        className='terms-and-conditions-remark'
                        dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-rabbit:detail3') }}
                    />
                    <div className='terms-and-conditions-section list-style-nested-number'>
                        <ul className='list-style-number-right-dot __bold __no-padding-inline-start'>
                            <li id='terms-conditions-rabbit-section1'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section1.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section1.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section1.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section1.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section1.detail1_4.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section2'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section2.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section2.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section2.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section2.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section3'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section3.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-rabbit:section3.detail1') }} />
                            </li>
                            <li id='terms-conditions-rabbit-section4'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section4.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section4.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section4.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section4.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section4.detail1_4.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section4.detail1_5.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section4.detail1_6.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section5'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section5.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section5.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section5.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section5.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section5.detail1_4.detail1'),
                                            }}
                                        />
                                        <ul lang='th' className='list-style-number-bracket'>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section5.detail1_4_1.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section5.detail1_4_2.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section5.detail1_4_3.detail1'),
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section5.detail1_5.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section6'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section6.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section6.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section6.detail1_2.detail1'),
                                            }}
                                        />

                                        <ul lang='th' className='list-style-number-bracket'>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section6.detail1_2_1.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section6.detail1_2_2.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section6.detail1_2_3.detail1'),
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section6.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section6.detail1_4.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section6.detail1_5.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section7'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section7.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section7.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section7.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section7.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section8'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section8.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section8.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section8.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section8.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section8.detail1_4.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section8.detail1_5.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section8.detail1_6.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section8.detail1_7.detail1'),
                                            }}
                                        />
                                        <ul lang='th' className='list-style-number-bracket'>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section8.detail1_7_1.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section8.detail1_7_2.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section8.detail1_7_3.detail1'),
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section8.detail1_8.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section9'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section9.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section9.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section9.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section10'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section10.title')}</strong>
                                <ul lang='th' className='list-style-character-th25-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section10.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section10.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section10.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section11'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section11.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section11.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section11.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section12'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section12.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-rabbit:section12.detail1') }} />
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section12.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section12.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section12.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section12.detail1_4.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section12.detail1_5.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section13'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section13.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section13.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section13.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section13.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section14'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section14.title')}</strong>
                                <ul lang='th' className='list-style-character-th25-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section14.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section14.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section14.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section15'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section15.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-rabbit:section15.detail1') }} />
                            </li>
                            <li id='terms-conditions-rabbit-section16'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section16.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-rabbit:section16.detail1') }} />
                            </li>
                            <li id='terms-conditions-rabbit-section17'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section17.title')}</strong>
                                <p dangerouslySetInnerHTML={{ __html: t('terms-and-conditions-rabbit:section17.detail1') }} />
                            </li>
                            <li id='terms-conditions-rabbit-section18'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section18.title')}</strong>
                                <ul lang='th' className='list-style-character-th25-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section18.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section18.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section2.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section19'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section19.title')}</strong>
                                <ul lang='th' className='list-style-character-th25-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section19.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section19.detail1_2.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section19.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li id='terms-conditions-rabbit-section20'>
                                <strong className='title'>{t('terms-and-conditions-rabbit:section20.title')}</strong>
                                <ul lang='th' className='list-style-character-right-dot mb-3'>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section20.detail1_1.detail1'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section20.detail1_2.detail1'),
                                            }}
                                        />
                                        <ul lang='th' className='list-style-number-bracket mb-3'>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section20.detail1_2_1.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section20.detail1_2_2.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section20.detail1_2_3.detail1'),
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('terms-and-conditions-rabbit:section20.detail1_2_4.detail1'),
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('terms-and-conditions-rabbit:section20.detail1_3.detail1'),
                                            }}
                                        />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </Style>
        </>
    );
}

export default TermsAndConditionsRabbit;
