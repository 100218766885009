import styled from '@emotion/styled';
import { breakpoint } from 'helpers';

const FormTwinTails = styled.div`
    label: form-twin-tails;

    position: static;
    display: flex;

    .form-twin-tail-left {
        order: 1;
    }

    .form-twin-tail-right {
        order: 3;
    }

    .form-panel {
        order: 2;
    }

    ${breakpoint('XL')} {
        flex-wrap: wrap;

        .form-twin-tail-left {
            order: 2;
        }

        .form-twin-tail-right {
            order: 3;
        }

        .form-panel {
            order: 1;
        }
    }
`;

export default FormTwinTails;
